@import '~styles';

.container {
  padding-top: $spacer-2xl;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-s;

  @include responsiveSmall() {
    flex-wrap: wrap;
  }
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  flex-grow: 1;

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
  }
}

.count {
  padding: $spacer-3xs $spacer-xs;
  margin-left: $spacer-xs;
  border-radius: 15px;
  background-color: $color-primary-light;
  color: $color-dark-purple;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.actions {
  display: flex;
  gap: $spacer-m;

  @include responsiveSmall() {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: $spacer-xs;
  }
}

.actions-right {
  display: flex;
  gap: $spacer-xs;

  @include responsiveSmall() {
    justify-content: flex-start;
  }
}

.switch {
  padding-top: $spacer-3xs;

  p {
    margin-left: -$spacer-xs;
  }
}
