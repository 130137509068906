@import '../Styles/OnboardingSubPage.module.scss';

.container {
  @include styledSubPage();

  .point:nth-child(3) {
    background: $color-primary;
    width: 30px;
  }

  .form {
    margin: auto;

    @include responsiveSmall() {
      margin: 0;
      justify-content: space-between;
    }
  }

  .action {
    width: fit-content;
    margin: 40px auto 0;

    @include responsiveSmall() {
      width: 100%;
      margin: none;
    }
  }
}
