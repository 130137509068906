@import '~styles';

.pin {
  position: absolute;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  touch-action: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border: $border-light;
  font-weight: $font-weight-bolder;
  color: white;
}

.delete {
  position: absolute;
  top: -$spacer-2xs;
  right: -$spacer-2xs;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-light;
  color: color(red-400);
  border: $border-light;
  border-radius: 50%;
  font-size: $font-size-3xs;

  &:hover,
  &:focus {
    outline: none;
  }
}
