@import '~styles';

.container {
  align-items: center;
  border-radius: 80px;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  height: 40px;
  min-width: 40px;
  justify-content: center;
  padding: 0 $spacer-2xl;
  position: relative;
  vertical-align: middle;
  background: $color-light-black;
  box-shadow: none;
  border: none;
  color: $color-text;
  flex-shrink: 0;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: $decoration-none;
    background-color: $color-light-gray;
    color: $color-text;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
  }
}

.icon {
  margin-right: $spacer-s;
}

.size-small {
  font-size: $font-size-2xs;
  height: 30px;
  min-width: 30px;
}

.size-large {
  font-size: $font-size-s;
  height: 50px;
  min-width: 50px;
}

.intent-primary {
  background: $background-gradient-primary-vertical;
  box-shadow: $shadow-purple;
  border: none;
  color: $color-light;

  &:hover,
  &:focus {
    background: $background-gradient-primary-hover;
    color: $color-light;
  }
}

.intent-ia {
  background: $background-gradient-ia;
  border: none;
  color: $color-light;

  &:hover,
  &:focus {
    background: $background-gradient-ia;
    color: $color-light;
  }
}

.ia-button-container {
  background-color: $color-light;
  border-radius: 20px;
  width: fit-content;
}

.intent-ia-light {
  @include textGradient($color-ia-pink, $color-ia-blue);

  background-image: linear-gradient(180deg, $color-ia-pink, $color-ia-blue);
  box-shadow: none;
  border: $border-transparent;

  &:hover,
  &:focus {
    background-color: $color-light-black;
  }
}

.intent-dark {
  background: $color-text;
  box-shadow: $shadow-purple;
  border: none;
  color: $color-light;

  &:hover,
  &:focus {
    background: $color-text;
    color: $color-light;
  }
}

.intent-white {
  background: $color-light;
  border: $border-transparent;
  color: $color-text;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: $color-light-black;
    border: $border-transparent;
    color: $color-text;
  }
}

.intent-outline {
  background: $color-light;
  box-shadow: none;
  border: $border-gray-light;

  &:hover,
  &:focus {
    background: $color-light-gray;
  }
}

.intent-danger {
  background: color(red-400);
  color: color(light);
  border: $border-transparent;
  transition: opacity 250ms ease;

  &:hover,
  &:focus {
    background: $color-light;
    border-color: color(red-400);
    color: color(red-400);
  }
}

.intent-correct {
  background: $color-pastel-green;
  color: $color-text;
  border: none;
  transition: background 250ms ease;

  &:hover,
  &:focus {
    background: $color-pastel-green;
    color: $color-text;
  }
}

.intent-wrong {
  background: $color-dark-red-2;
  color: $color-text;
  border: none;
  transition: background 250ms ease;

  &:hover,
  &:focus {
    background: $color-dark-red-2;
    color: $color-text;
  }
}

.intent-gray {
  background-color: $color-light-black;
  box-shadow: none;
  border: none;

  &:hover,
  &:focus {
    background-color: $color-light-gray;
    border: none;
  }
}

.hasIcon .content {
  text-align: left;
}

.isEmpty {
  padding: 0 !important;

  .icon {
    margin-right: 0 !important;
  }
}

.isDisabled,
.isLoading {
  pointer-events: none;
}

.isDisabled {
  opacity: 0.5;
}

.isLoading {
  position: relative;

  .icon,
  .content {
    visibility: hidden;
  }

  .spinner {
    font-size: $font-size-s;
    position: absolute;
  }
}
