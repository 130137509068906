@import '~styles';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: $spacer-m;
  overflow: hidden;
}

.wrapper {
  outline: none;
  border: none;
  border-radius: 20px;

  &:hover,
  &:focus {
    outline: none;
  }
}

.image {
  width: 100%;
  height: auto;
  user-select: none;
  border-radius: 20px;
}
