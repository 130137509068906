@import '~styles';

.container {
  align-items: center;
  display: flex;
  margin: auto;
  width: 550px;
  max-width: 100%;
}

.icon {
  height: 64px;
  width: 64px;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-m;
  margin-right: $spacer-s;
  background: color(purple-50);
  color: color(blue-300);
  flex: 0 0 auto;
}

.grow {
  flex: 1 1 auto;
  min-width: 0;
}

.title {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  margin-bottom: $spacer-2xs;
  text-align: left;
}

.content {
  display: flex;
  align-items: center;
}

.link {
  @include textOverflow();
  @include textBoldDark();

  line-height: 2.5rem;
  text-align: left;
  padding: 0 $spacer-m;
  border: $border-blue-light;
  border-radius: 22.5px;
  background-color: $color-light;
  margin-right: $spacer-3xs;
  flex: 1 1 auto;
  min-width: 0;
}

.hidden {
  @include hide();
}
