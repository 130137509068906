@import '~styles';

.link-input-container {
  display: flex;
  align-items: center;
  margin: $spacer-xs 0 $spacer-m 0;
}

.link {
  ::placeholder {
    font-weight: $font-weight-normal;
    color: $color-text-light;
  }
}

.link-reset-button {
  margin-left: $spacer-xs;
}

.video-preview {
  display: flex;
  justify-content: center;
}

.iframe {
  border-radius: 15px;
}

.timer {
  margin: $spacer-2xl 0;
  display: flex;
  align-items: center;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.input-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto $spacer-xs auto;
}
