@import '~styles';

.container {
  width: 300px;
  height: fit-content;

  @include responsiveMedium() {
    width: 100%;
  }

  &.column {
    @include responsiveLarge() {
      width: 100%;
    }
  }
}

.title {
  @include textBolderDark();

  text-align: left;
  margin-bottom: $spacer-m;
}

.action {
  width: 100%;
  border-radius: 10px;
  margin-bottom: $spacer-xs;
}
