@import '~styles';

.checkboxes {
  position: relative;
  max-height: 400px;
  overflow-y: scroll;
}

.checkbox {
  margin-bottom: $spacer-s;
}
