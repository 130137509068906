@import '~styles';

.course {
  background: $color-light-purple-2;
  color: $color-text-light;
  font-weight: $font-weight-bold;
  font-size: $font-size-3xs;
  margin-bottom: $spacer-3xs;
  padding: $spacer-2xs $spacer-xs;
  border-radius: 5px;
  width: 100%;
  transition: width 0.3s ease, margin-left 0.3s ease, box-shadow 0.3s ease;
  border: none;

  &:hover,
  &:focus {
    width: 110%;
    margin-left: -$spacer-2xs;
    box-shadow: $shadow-purple;
    font-weight: $font-weight-bolder;
    outline: none;
  }

  &.to-study {
    background: $background-gradient-primary;
    color: $color-text;
  }

  &.disabled {
    cursor: default;
  }

  @include responsiveSmall() {
    &:hover,
    &:focus {
      width: 102%;
      margin-left: -$spacer-3xs;
    }
  }
}

.course-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
}

.course-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-3xs;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background: gradient-light('purple');
  color: $color-light;
}

.course-text {
  @include textOverflow();

  text-align: left;
}

.icon-check {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-3xs;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $color-secondary;
  color: $color-light;
  margin-left: $spacer-xs;
}

.stat {
  font-weight: $font-weight-medium;
  color: $color-text;
  margin-top: $spacer-xs;
  text-align: left;
  display: none;

  span {
    font-weight: $font-weight-bolder;
    font-size: $font-size-m;
    margin: 0 $spacer-3xs;
    line-height: 0.75rem;
  }

  @include responsiveSmall() {
    display: block;
  }
}

.content {
  @include responsiveSmall() {
    display: none;
  }
}

@each $color in $palette-theme{
  .theme-#{$color} {
    background: gradient-light($color);

    .course-icon {
      background: color-dark($color);
    }

    &.to-study {
      background: gradient-dark($color);

      .course-icon {
        background: color-light($color);
        color: color-dark($color);
      }
    }
  }
}
