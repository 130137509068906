@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.aria-label {
  @include ariaLabel();
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;

  &:focus-within {
    border-color: $color-text;
  }
}

.icon {
  margin-left: $spacer-xs;
}

.input {
  @include textBoldDark();

  display: block;
  border-radius: 22.5px;
  border: none;
  width: 100%;
  padding: $spacer-xs $spacer-l;
  height: 44px;
  min-width: 1rem;

  &::placeholder {
    color: color(gray-700);
  }
}

.small-padding {
  padding: $spacer-xs $spacer-l $spacer-xs $spacer-xs;
}

.textarea-container {
  display: block;
  width: 100%;
  padding: $spacer-xs $spacer-l;
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;

  &:focus-within,
  &:focus-visible {
    border-color: $color-text;
  }
}

.textarea {
  @include textBoldDark();

  width: 100%;
  background: none;
  border: none;
  resize: none;
  display: block;
  width: 100%;

  &::placeholder {
    color: color(gray-700);
  }
}

.input:invalid > .input-container,
.textarea:invalid {
  border: none;
  box-shadow: none;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled .input-container,
.isDisabled .textarea-container {
  background: color(gray-200);
  cursor: not-allowed;
  color: color(gray-700);
}

.hasError .input-container,
.hasError .textarea-container {
  border-color: $color-wrong;
}

.large-number {
  border-radius: 10px;
  padding: $spacer-3xs 0 $spacer-3xs $spacer-s;

  .input {
    text-align: center;
    font-size: $font-size-2xl;
    font-weight: $font-weight-bolder;
  }
}

.intent-gray {
  .input-container,
  .textarea-container,
  .input {
    background-color: $color-light-black;
    border-color: $color-light-black;
  }
}
