@import '~styles';

.container {
  padding: 37px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: color-light('gray');
  box-shadow: $shadow-light;
  width: 100%;
  margin-left: 0;
  margin-bottom: $spacer-xs;

  @include responsiveSmall() {
    padding: $spacer-xs $spacer-s;
  }
}

.icon {
  flex: 0 0 auto;
  margin-right: $spacer-m;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 5px;
  background: color-dark('gray');
  color: color-light('gray');
  font-size: $font-size-l;

  @include responsiveSmall() {
    display: none;
  }
}

.text {
  flex-grow: 1;
}

.title {
  @include textBoldDark();

  margin-bottom: $spacer-xs;
}

.subtitle {
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-medium;
}

.action {
  margin-left: $spacer-xs;
}
