@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.toolbar {
  margin: $spacer-xs 0;
  display: flex;
  flex-wrap: wrap;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.action {
  margin: 0 $spacer-3xs $spacer-3xs 0;

  @include responsiveMedium() {
    margin: 0 0 $spacer-3xs 0;
  }
}

.image-input {
  width: 100%;
  height: 200px;
}

.image-legends-container {
  display: flex;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.image {
  flex: 0 0 50%;
  margin-right: $spacer-m;

  @include responsiveMedium() {
    width: 98%;
    margin: auto;
    margin-bottom: $spacer-xs;
  }
}

.legends {
  flex-grow: 1;
}

.legend {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacer-xs;
}

.legend-index {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: $background-gradient-primary;
  border: $border-light;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bolder;
  color: white;
  flex: 0 0 auto;
  margin-top: $spacer-3xs;
}

.legend-input {
  flex-grow: 1;
  margin: 0 $spacer-xs;
}
