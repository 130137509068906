@import '~styles';

.legend-pin {
  position: absolute;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  touch-action: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: $color-light-red-2;
  border: $border-red-dark;
  color: $color-text;
  font-weight: $font-weight-bolder;
}

.isCorrect {
  background: $color-light-green-2;
  border: $border-green-pastel;
}

.isReport {
  background: $background-gradient-primary;
  border: $border-light;
  color: $color-light;
}
