@import '~components/Shared/Styles/Auth.module.scss';

.container {
  @include styledAuth();
}

.section {
  @include responsiveSmall() {
    @include styledSection();
  }
}
