@import '~styles';

.zone {
  position: absolute;
  touch-action: none;
  user-select: none;
  outline: none;
  border: none;
  border: $border-light-transparent;
  background: color(gray-400);
  border-radius: 10px;
}
