@import '~components/Shared/Styles/StyledModal.module.scss';

.modal {
  @include styledModal();

  max-width: 590px;
  background-image: url('./images/join.svg');
  background-repeat: no-repeat;
  background-size: cover;

  @include responsiveSmall() {
    max-width: 90%;
  }
}

.close {
  margin-bottom: 0 !important;
}

.title {
  margin-bottom: $spacer-m !important;
}

.subtitle {
  max-width: 300px;
  margin: 0 auto $spacer-m auto !important;
}

.input-container {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
  padding: $spacer-s $spacer-m;
  background-color: $color-pastel-purple;
  border: $border-light;
  border-radius: 40px;
  box-shadow: $shadow-dark-blue;
  margin: 0 auto;

  @include responsiveSmall() {
    flex-direction: column;
    max-width: 100%;
  }
}
