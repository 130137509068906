@import '~styles';

.warning-container {
  margin: $spacer-m 0;
  background-color: $color-light-red-2;
  border-radius: 15px;
  padding: $spacer-xs $spacer-2xl;
  text-align: left;
}

.warning-title-container {
  display: flex;
  gap: $spacer-xs;
  align-items: center;
}

.warning-title,
.warning-element {
  color: $color-wrong;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.warning-helper {
  color: $color-wrong;
}
