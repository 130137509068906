@import '~styles';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  padding: $spacer-s $spacer-l;
  color: $color-text-light;
  text-align: left;
  border-radius: 10px;
  transition: color ease-in-out 250ms;

  &.isSmall {
    padding: $spacer-s;
    justify-content: center;
  }

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
    text-decoration: $decoration-none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }
}

.icon {
  margin-right: $spacer-m;
  min-width: 25px;
  text-align: center;
  font-size: $font-size-m;
}

.isActive {
  color: $color-text;
  background-color: $color-light-black;

  .icon {
    @include textGradient($color-dark-purple, #c0a8ff);
  }
}

.isSmall {
  .icon {
    margin-right: 0;
  }

  .text {
    display: none;
  }
}

.collapsed {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;

  i {
    margin: 0;
  }

  span {
    display: none;
  }

  @include responsiveSmall() {
    width: 100%;
    height: auto;
    padding: $spacer-s $spacer-l;
    justify-content: flex-start;
    gap: $spacer-s;

    span {
      display: flex;
    }
  }
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-beta {
  background: $color-secondary;
  font-size: $font-size-xs;
  border-radius: 5px;
  font-weight: $font-weight-bolder;
  padding: $spacer-3xs $spacer-3xs;
  color: $color-light;
  margin-left: 50px;

  @include responsiveSmall() {
    margin-left: $spacer-xs;
  }
}
