@import '~components/Shared/Styles/StyledSubPage.module.scss';

.subpage {
  @include styledSubPage();
}

iframe {
  width: 100%;
  height: calc(100% - 65px);
}
