@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.subtitles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacer-xs;

  @include responsiveMedium() {
    display: block;
  }
}

.subtitle {
  margin-top: $spacer-2xl;
  margin-bottom: $spacer-s;
  padding: $spacer-2xl $spacer-l $spacer-m $spacer-l;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  color: $color-text;
  position: relative;
}

.subtitle-header {
  border-radius: 15px;
  background-color: $color-light;
  box-shadow: $shadow-purple;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  padding: $spacer-3xs $spacer-s;
  position: absolute;
  top: -$spacer-s;
  left: $spacer-2xl;
}

.chart {
  padding: $spacer-2xl $spacer-l $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  height: 300px;
}

.chart-axis {
  fill: $color-text;
  font-weight: $font-weight-bolder;
}

.chart-label {
  font-weight: $font-weight-bolder;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin: $spacer-m 0;
  flex-wrap: wrap;
}

.stat-container {
  background-color: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  padding: $spacer-xs;
  text-align: center;
  flex-grow: 1;
  margin: 0 $spacer-xs $spacer-xs $spacer-xs;
  max-width: 250px;
}

.stat-value {
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.stat-title {
  @include textBoldDark();
}

.good-answer-container {
  background-color: $color-success;
  border-radius: 15px;
  text-align: center;
  padding: $spacer-xs;
}

.good-answer-title {
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-light;
  margin-bottom: $spacer-xs;
}

.good-answer {
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-text-light;
  width: 100%;
  background-color: $color-light;
  border-radius: 10px;
  padding: $spacer-xs;
}
