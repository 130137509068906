@import '~styles';

.container {
  width: 90%;
  max-width: 450px;
  position: fixed;
  bottom: $spacer-m;
  right: $spacer-m;
  padding: $spacer-s $spacer-xl;
  z-index: $z-layer-mid-level;
  background: $color-light;
  border-radius: 18px;
  box-shadow: $shadow-blue;
}

.container-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 250ms ease-out, transform 250ms ease-out;
}

.container-exit-active {
  opacity: 0;
  transform: translateY(100%);
}

.icon-container {
  background: $background-gradient-primary;
  display: block;
  min-width: 50px;
  width: 50px;
  height: 50px;
  line-height: 3.125rem;
  border-radius: 50%;
  font-size: $font-size-m;
  color: white;
  text-align: center;
  margin: $spacer-s $spacer-xs 0 0;
}

.title {
  padding: $spacer-xs 0 $spacer-s;

  a {
    display: inline;
    color: $color-primary;
    margin: 0 $spacer-3xs;

    &:hover {
      text-decoration: $decoration-underline;
    }
  }
}

.main-actions {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-action {
  margin-top: $spacer-xs;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: $spacer-xs;

  @include responsiveSmall() {
    flex-direction: column;
    margin-bottom: $spacer-xs;
  }
}

.action {
  @include responsiveSmall() {
    margin-top: $spacer-xs;
  }
}

.switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  margin: $spacer-xs 0 $spacer-xs $spacer-3xs;
}

.tooltip {
  margin-left: $spacer-3xs;
}

/* dimensions for 'iPhone6 Plus' and lower */
@media screen and (max-width: 414px) and (orientation: portrait),
  screen and (max-width: 736px) and (orientation: landscape) {
  .container {
    max-height: 90vh;
  }
}

@media print {
  .container {
    display: none;
  }
}
