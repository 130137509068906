@import '~components/Shared/Styles/StyledModal.module.scss';

.modal {
  @include styledModal();
}

.play-button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 80px;
  padding: 0 $spacer-s 0 $spacer-2xl;
  border: none;
  border-radius: 50px;
  background-color: $color-light;
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  box-shadow: $shadow-dark;
  transition: box-shadow 100ms ease-in-out;

  &:hover,
  &:focus {
    box-shadow: $shadow-dark;
    outline: none;
  }

  @include responsiveSmall() {
    width: 100%;
  }
}

.chevron {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  background-color: $color-text;
  color: $color-light;
  text-align: center;
  margin-left: 40px;

  @include responsiveMedium() {
    margin-left: $spacer-xs;
  }
}
