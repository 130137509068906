@import '~styles';

.preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacer-2xl;
}

.preview {
  border-radius: 15px;
  background: $color-light;
  box-shadow: $shadow-dark-blue;
  padding: $spacer-s;
  width: 440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio {
  max-width: 100%;
  min-width: 50%;
  min-height: 300px;
  max-height: 600px;
  border-radius: 15px;
}

.play {
  i {
    font-size: $font-size-l;
    margin: $spacer-3xs 0 0 $spacer-3xs;
  }
}

.duration {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.delete {
  margin-left: $spacer-m;
}

.label {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  color: $color-text;
  margin: $spacer-m 0 $spacer-3xs 0;
}

.or {
  height: 2px;
  margin: $spacer-2xl 0 40px 0;
  background: $color-light-gray;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
  text-align: center;
}

.or span {
  background: $color-background;
  padding: 0 50px;
  position: relative;
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  top: -$spacer-xs;
}

.small {
  width: 40%;
  margin: $spacer-2xl auto;

  span {
    padding: 0 $spacer-2xl;
  }
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-xs;
  margin-bottom: $spacer-xs;
}
