@import '~components/Shared/Styles/Auth.module.scss';

.container {
  @include styledAuth();

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacer-2xl $spacer-2xl;
  background-color: $color-light;
  height: 100vh;

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-s;
}

.main {
  width: 600px;
  max-width: 100%;
  margin: -70px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title {
  margin-top: $spacer-m;
}

.subtitle {
  color: $color-text-light;
  font-weight: $font-weight-normal;
  margin-bottom: $spacer-m;
}

.recover-button {
  @include responsiveSmall() {
    width: 100%;
  }
}
