@import '~styles';

.explanation {
  font-weight: $font-weight-medium;
  text-align: center;
  color: $color-text-light;
  margin-bottom: $spacer-l;
}

.information-container {
  display: flex;
  align-items: center;
  gap: $spacer-s;
  background-color: $color-zircon;
  padding: $spacer-s;
  border-radius: 8px;
  color: $color-text-light;

  strong {
    color: $color-text;
  }
}

.information-icon {
  font-size: $font-size-xl;
}

.cancel-button {
  background-color: transparent;
  color: $color-text-light;
}

.actions-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: $spacer-xs;

  @include responsiveSmall() {
    flex-direction: column;
  }
}
