@import '~styles';

.container {
  display: flex;
  justify-content: space-between;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: $spacer-xs;
  border-radius: 5px;
  background-color: $color-light-black;
  background-size: cover;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bolder;
}

.information {
  flex-grow: 1;
}

.main-information {
  display: flex;
}

.name {
  color: $color-text;
  font-size: $font-size-xs;
  line-height: 1rem;
  font-weight: $font-weight-bold;
}

.date {
  color: $color-text-light;
  font-size: $font-size-3xs;
  font-weight: $font-weight-bold;
}

.action {
  margin-left: $spacer-2xs;
}

.comment-notification {
  background-color: $color-secondary;
  border-radius: 50px;
  height: 16px;
  width: 16px;
  margin-left: $spacer-xs;
  border: $border-thick-light;
}
