@import '~styles';

.field {
  margin-bottom: $spacer-2xs;
  border-radius: 5px;
  border: $border-thin-gray-light;
  width: 100%;
  resize: none;
  font-size: $font-size-xs;
  padding: $spacer-2xs $spacer-xs;
  color: $color-text;
  font-weight: $font-weight-normal;

  &:focus {
    border: $border-thin-dark;
  }

  &::placeholder {
    color: $color-text-light;
    font-weight: $font-weight-normal;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
}

.reply {
  justify-content: flex-end;
}

.modification {
  margin-top: -$spacer-2xs;
  padding-bottom: $spacer-2xs;
  margin-bottom: $spacer-2xs;
  border-bottom: 1px solid $color-blue-light;
}

.content {
  font-size: $font-size-xs;
  color: $color-text;
  font-weight: $font-weight-normal;
}

.ai-button {
  float: right;
  margin: -$spacer-2xl 0 $spacer-3xs 0;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}
