@import '~components/Shared/Styles/StyledSubPage.module.scss';

.subpage {
  position: relative;
  height: 100vh;
  overflow: hidden;

  @include responsiveMedium() {
    height: auto;
    overflow: auto;
  }
}

.main {
  display: flex;
  gap: $spacer-m;
  height: calc(100% - 70px);
  padding: $spacer-l $spacer-3xl 0 $spacer-m;
  overflow-y: auto;

  @include responsiveMedium() {
    padding: $spacer-l $spacer-2xl 0 $spacer-m;
  }

  @include responsiveLargeSidePanel() {
    flex-direction: column;
  }

  &.menu-expanded {
    @include responsiveSmallSidePanel() {
      flex-direction: column;
    }
  }
}

.preview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.question {
  padding: $spacer-3xl $spacer-2xl $spacer-2xl $spacer-2xl;
  margin: auto;
  width: 100%;
  background-color: $color-light-black;
  border-radius: 15px 15px 0 0;
  flex-grow: 1;

  @include responsiveMedium() {
    padding: $spacer-3xl $spacer-m $spacer-m $spacer-m;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid $color-light-gray;
  background-color: $color-light;
  padding: 0 $spacer-xl 0 $spacer-m;

  @include responsiveMedium() {
    position: relative;
  }
}

.action {
  @include responsiveSmall() {
    width: 100%;
  }
}
