@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.subtitle {
  margin-top: $spacer-2xl;
  margin-bottom: $spacer-s;
  padding: $spacer-2xl $spacer-l $spacer-m $spacer-l;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  color: $color-text;
  position: relative;
}

.header {
  border-radius: 15px;
  background-color: $color-light;
  box-shadow: $shadow-purple;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  padding: $spacer-3xs $spacer-s;
  position: absolute;
  top: -$spacer-s;
  left: $spacer-2xl;
}

.user-answer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: 0 $spacer-2xl $spacer-3xs $spacer-m;
}

.user-answer {
  padding: $spacer-2xs $spacer-2xs;
  border: $border-dark;
  border-radius: 30px;
  visibility: hidden;
  margin: auto;
  font-size: $font-size-3xs;
}

.visible {
  visibility: visible;
}

.chart {
  padding: $spacer-s $spacer-l 50px $spacer-l;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  height: 300px;
}

.biggerChart {
  padding: $spacer-s $spacer-l 100px $spacer-l;
  height: 350px;
}

.chart-axis {
  fill: $color-text;
  font-weight: $font-weight-bolder;
}

.chart-label {
  font-weight: $font-weight-bolder;
}

.expert-feedbacks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: $spacer-xs 55px 0 40px;
}

.aria-label {
  @include ariaLabel();
}
