@import '~styles';

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-l;
}

.title-question-bank {
  font-size: $font-size-l;
}

.chapter {
  margin-bottom: $spacer-xs;
}
