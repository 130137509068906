@import '~styles';

.container {
  max-width: 100%;
}

.select-type {
  border-radius: 15px;
  background-color: $color-light-black;
  padding: $spacer-l $spacer-m;
  position: relative;
  width: 350px;
  max-width: 100%;
  display: flex;
  align-items: center;
  height: 47px;

  @include responsiveMedium() {
    width: 250px;
  }

  &:focus-within {
    border-color: $color-text;
  }

  select {
    color: $color-text;
    background: none;
    max-width: 90%;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    padding-right: 50px;
    border: none;

    @include responsiveMedium() {
      max-width: 85%;
    }

    @include responsiveSmall() {
      padding-right: $spacer-m;
    }
  }
}

.icon-container {
  width: 35px;
  min-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: $color-light;
  border-radius: 50px;
}

.icon {
  width: 10px;
}

.select-type-text {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  padding-left: $spacer-3xs;
}

.arrow {
  pointer-events: none;
  position: absolute;
  top: $spacer-3xs;
  bottom: $spacer-3xs;
  right: $spacer-3xs;
  padding: $spacer-s $spacer-l;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-light-black;
  border-radius: 0 15px 15px 0;
  color: $color-text;
  font-size: $font-size-2xs;

  @include responsiveSmall() {
    padding: $spacer-s $spacer-s;
  }
}
