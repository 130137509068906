@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled .field {
  background: color(gray-200);
  cursor: not-allowed;
  color: color(gray-700);
}

.hasError .field {
  border-color: $color-wrong;
}
