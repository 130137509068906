@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.category-element {
  border-radius: 22px;
  background: $color-light;
  border: $border-gray-light;
  margin-bottom: $spacer-xs;
  padding: $spacer-xs;
  position: relative;
}

.line {
  margin: $spacer-xs -$spacer-xs;
  border-bottom: 2px solid $color-light-gray;

}

.destination-add {
  flex-shrink: 0;
  margin: 0 $spacer-xs;
  width: 30px;
}

.arrow {
  flex-shrink: 0;
  margin: 0 $spacer-xs 0 $spacer-m;
  width: $spacer-m;
}

.destination-complete {
  flex-grow: 1;
  margin: 0 48px 0 0 !important;
}
