@import '~styles';

.error {
  @include textError();

  margin-bottom: $spacer-l;
}

.field {
  margin-bottom: $spacer-s;
}

.action {
  @include responsiveSmall() {
    width: 100%;
  }
}
