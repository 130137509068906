@import '~styles';

.container {
  display: flex;
  gap: $spacer-xs;
  padding: $spacer-xl;
  border-radius: 10px;
  background: $color-light;

  @include responsiveSmall() {
    margin-left: 20px;
  }

  img {
    width: 30px;
  }

  p {
    @include textNormalDark();
  }
}

:global(.react-joyride__overlay) {
  z-index: $z-layer-mid-level !important;
}

:global(.__floater__open) {
  z-index: $z-layer-top-level !important;
}
