@import '~styles';

.container {
  border-bottom: 2px solid #e7ebf0;
  padding-bottom: $spacer-m;
  margin-bottom: $spacer-m;
}

.card {
  border: $border-light;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin-left 200ms ease-in-out;
  width: 100%;
  margin-left: 0;

  &:hover,
  &:focus {
    width: 101%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }
}

.chapter {
  align-items: center;
  display: flex;
  padding: 0 $spacer-l;
}

.icon {
  @include textGradient(#8258f3, #9f7cff);

  align-items: center;
  border: none;
  display: flex;
  flex: 0 0 auto;
  font-size: $font-size-xs;
  height: 30px;
  justify-content: center;
  transition: color 250ms ease-in-out;
  width: 30px;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 50%;
  }
}

.title {
  @include textOverflow();
  @include textBolderDark();

  border: none;
  background: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: $spacer-s $spacer-xs $spacer-s $spacer-l;
  text-align: left;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }
}

.count {
  background-color: $color-secondary;
  font-size: $font-size-2xs;
  border-radius: 50px;
  padding: $spacer-3xs $spacer-2xs;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  color: #fff;
  font-weight: $font-weight-bolder;
  margin-left: $spacer-s;
}

.questions {
  border-radius: 10px;
  min-height: 3rem;
  transition: background 250ms ease-in-out;
  margin: $spacer-3xs $spacer-xs;
}

.question {
  margin-bottom: $spacer-xs;
}
