@import '~components/Shared/Styles/NewStyledModal.module.scss';

.instructions-icon {
  margin-left: $spacer-3xs;
  height: 17px;
  width: 17px;
  min-width: 17px;
  background-color: transparent;
  border: none;
  color: $color-dark-purple;

  &:hover,
  &:focus {
    color: $color-dark-purple;
  }
}

.modal {
  @include newStyledModal();

  overflow: hidden;
}

.header {
  display: flex;
}

.information-container {
  background-color: $color-light-purple-3;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-l;
  display: flex;
  align-items: center;
  padding: $spacer-s 120px $spacer-s $spacer-m;
  border-radius: 15px;
}

.information-icon {
  @include textGradient($color-dark-purple, color(blue-300));

  font-size: $font-size-l;
}

.information {
  text-align: left;
  margin-left: $spacer-s;
}

.explanation {
  line-height: 1.5rem;
  text-align: left;
  padding: 0 80px 0 $spacer-xs;
}
