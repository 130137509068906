@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();

  max-width: 800px;
  margin: auto;
}

.front-container {
  width: 100%;
  min-height: 50vh;
  max-height: 60vh;
  display: grid;
  grid-template-rows: auto 1fr;
  background: $color-light;
  border-radius: 25px;
  box-shadow: $shadow-blue;
  margin: 0 auto $spacer-s;
  padding: $spacer-xs;

  @include responsiveSmall() {
    margin-bottom: $spacer-s;
    padding: $spacer-xs;
    max-height: 55vh;
  }
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: $spacer-xs;
  height: fit-content;
  margin-bottom: $spacer-3xs;
}

.un-reveal,
.speech {
  border: none;
  background-color: $color-light-black;
}

.front-content {
  @include scrollbarPrimary();

  margin: 0 $spacer-xs $spacer-xs $spacer-xs;

  &.isBigContent {
    display: flex;
    align-items: center;
  }
}

.front-text {
  @include textNormalDark();

  text-align: left;

  &.isBigText {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    text-align: center;

    strong {
      font-weight: $font-weight-bolder;
    }
  }
}
