@import '~components/Shared/Styles/StyledSubPage.module.scss';

.subpage {
  @include styledSubPage();
}

.count {
  padding: $spacer-3xs $spacer-xs;
  margin: 0 $spacer-s 0 -45px;
  border-radius: 15px;
  background-color: $color-primary-light;
  color: $color-dark-purple;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;

  @include responsiveSmall() {
    margin: 0 $spacer-s 0 0;
  }
}

.sub-title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-s;
}

.margin {
  margin-top: $spacer-2xl;
}

.separators {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include responsiveMedium() {
    flex-direction: column;
    width: 100%;
    margin-left: $spacer-s;
  }
}

.separator {
  flex: 0 0 25%;
  margin-right: $spacer-2xl;

  @include responsiveMedium() {
    width: 100%;
  }
}

.switch-button {
  display: flex;
  align-content: center;
  margin-top: $spacer-m;
}

.multi {
  display: flex;
  align-content: center;
}

.extra {
  margin-left: $spacer-3xs;
  flex: 0 0 50%;
}

.field-error {
  text-align: left;
}
