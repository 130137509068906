@import '~styles';

.container {
  cursor: move;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin-left 200ms ease-in-out;
  padding: 0 $spacer-l;
  min-height: 58px;
  border: $border-transparent;
  width: 100%;
  margin-left: 0;
  margin-bottom: $spacer-xs;

  &:hover,
  &:focus {
    width: 101%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }

  &.isSelected {
    background: color(purple-400);

    .icon,
    .position,
    .title {
      color: color(light);
    }
  }
}

.number {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin-left: -$spacer-2xl;
  font-weight: $font-weight-bolder;
  font-size: $font-size-2xs;
  color: #fff;
  background-color: #32e0c4;
  text-align: center;
  line-height: 1.5rem;
}

.icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: center;
  width: 32px;
  margin-right: $spacer-m;

  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.position {
  font-size: $font-size-xs;
}

.title {
  @include textOverflow();
  @include textNormalDark();

  flex-grow: 1;
  padding: $spacer-s $spacer-xs $spacer-s $spacer-xs;
  text-align: left;
  cursor: pointer;
}

.author {
  display: flex;
  color: $color-text;
  line-height: 0.938rem;
}

.author-icon {
  margin-right: $spacer-xs;
}
