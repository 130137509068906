@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.message {
  background-color: $color-light-red-2;
  padding: $spacer-3xs $spacer-s;
  border-radius: 10px;
  display: flex;
  color: $color-dark-red-2;
  font-weight: $font-weight-bold;
  width: fit-content;
  margin: -$spacer-s auto $spacer-s;
}

.message-icon {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: $gradient-dark-pink-red;
  color: $color-light;
  text-align: center;
  line-height: 1.875rem;
  margin-right: $spacer-s;
}
