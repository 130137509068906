@import '~styles';


.section-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: $spacer-l;


  @include responsiveSmall() {
    flex-wrap: wrap;
  }
}

.actions,
.options {
  display: flex;
  align-items: center;

  @include responsiveSmall() {
    width: 100%;
    flex-wrap: wrap;
  }
}

.action-create,
.action-join {
  border: none;
  border-radius: 10px;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  padding: $spacer-2xs $spacer-l $spacer-2xs $spacer-xs;
  display: flex;
  align-items: center;
  margin-right: $spacer-s;

  @include responsiveSmall() {
    display: none;
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: $shadow-focus;
  }
}

.plus-menu {
  display: none;
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    display: block;
  }
}

.action-create {
  background: $background-gradient-primary;
  color: $color-light;

  &:hover,
  &:focus {
    background: $background-gradient-primary-hover;
  }
}

.action-join {
  background: $color-light;
  color: $color-text;

  &:hover,
  &:focus {
    background: $color-light-black;
    box-shadow: $shadow-focus;
  }
}

.icon-container {
  width: 45px;
  height: 45px;
  margin-right: $spacer-m;
  border-radius: 10px;
  background-color: $color-pastel-purple;
}

.icon {
  color: $color-light;
  line-height: 2.813rem;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.icon-container-join {
  background: $color-light-purple-3;
}

.icon-join {
  @include textGradient(#c0a8ff, $color-dark-purple);
}

.display {
  margin-left: $spacer-xs;
}

.folder-button {
  @include responsiveSmall() {
    display: none;
  }
}
