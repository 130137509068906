@import '~questions/Shared/Styles/Editor.module.scss';
@import '~components/Shared/Styles/StyledModal.module.scss';

.container {
  @include editor();
}

.explanation {
  @include textNormalDark();

  margin-bottom: $spacer-2xl;
}
