@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.dropzone {
  border-radius: 1rem;
  margin-bottom: $spacer-s;
  padding: $spacer-s;
  transition: opacity 0.25s ease-in-out;

  label {
    border: $border-dashed-black-light;
    border-radius: 1rem;
    cursor: pointer;
    display: block;
    padding: $spacer-l;
    text-align: center;
    width: 100%;
  }

  input {
    @include hide();

    &:focus-visible ~ .input-content {
      border: $border-dark;
      border-radius: 10px;
    }
  }

  .input-content {
    border: $border-transparent;
  }

  .icon {
    font-size: $font-size-l;
    margin-bottom: $spacer-xs;
  }

  &.isDroppable {
    opacity: 0.5;
  }
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

/* States */

.isDisabled {
  .container,
  .input,
  .input-content,
  .dropzone,
  .text {
    cursor: not-allowed;
  }

  .text {
    color: color(gray-400);
  }

  .icon {
    color: color(gray-400);
  }
}

.hasError .field {
  border-color: $color-wrong;
}
