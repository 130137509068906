@import '~styles';

.container {
  border-radius: 25px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  padding: 0 $spacer-l;
}

.isSectionOpen {
  padding: 0 $spacer-l $spacer-l $spacer-l;
}

.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: $spacer-l 0;

  &:focus,
  &:hover {
    outline: none;
  }
}

.title-container {
  display: flex;
  align-items: center;
}

.title-icon {
  margin-right: $spacer-xs;
  height: 40px;
  width: 40px;
  line-height: 2.5rem;
  text-align: center;
  font-weight: $font-weight-bolder;
  border-radius: 7px;
  background: $color-pastel-red;
  color: $color-dark-red-3;
  font-size: $font-size-m;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  text-align: left;
}

.header-right {
  min-width: 90px;
  display: flex;
  align-items: center;
}

.download-button {
  margin-right: $spacer-xs;

  @include responsiveMedium() {
    width: 40px;
    padding-right: 0;
    padding-left: 0;
  }

  .download-button-text {
    @include responsiveMedium() {
      display: none;
    }
  }

  .download-button-icon {
    display: none;

    @include responsiveMedium() {
      display: block;
    }
  }
}

.toggle-button {
  width: 40px;
  height: 40px;
  line-height: 2.188rem;
  text-align: center;
  border-radius: 30px;
  background-color: $color-light-black;

  &:hover {
    background-color: $color-light-gray;
  }
}

.chart-container {
  width: 100%;
  border-radius: 25px;
  border: $border-black-light;
  position: relative;
}

.chart {
  @include scrollbarPrimary();

  width: 100%;
  max-width: 100%;
  overflow-y: auto;
}

.tooltip {
  border-radius: 20px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  left: 0;
  padding: $spacer-2xl;
  position: absolute;
  transform: translateX(-50%) translateY(-110%);
  top: 50%;
  width: 270px;
  display: none;

  &.isVisible {
    display: block;
  }
}

.student {
  @include textBolderDark();

  margin-bottom: $spacer-xs;
}

.question {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.stat {
  align-items: center;
  display: flex;
  font-size: $font-size-xs;
  margin-bottom: $spacer-3xs;
  border-radius: 10px;
  padding: $spacer-3xs $spacer-xs;

  span {
    font-weight: $font-weight-bolder;
    margin-right: $spacer-3xs;
  }
}

.total {
  background-color: $color-light-black;
  margin-top: $spacer-xs;
}

.correct {
  background-color: $color-pastel-green;
}

.wrong {
  background-color: $color-pastel-pink;
}

.unknown {
  background-color: $color-pastel-yellow;
}

.history-title {
  font-size: $font-size-xs;
  margin-top: $spacer-xs;
}

.history {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;

  span {
    margin-right: $spacer-2xs;
  }
}

.icon {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  line-height: 1.563rem;
  text-align: center;
}

.icon.correct {
  color: $color-pastel-green;
  background-color: $color-light-green;
}

.icon.wrong {
  color: $color-wrong;
  background-color: $color-light-red-2;
}

.icon.unknown {
  color: $color-orange;
  background-color: $color-light-orange;
}

.icon {
  font-size: $font-size-2xs;
  margin-right: $spacer-3xs;
}

.label {
  fill: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.chapter {
  font-weight: $font-weight-bolder;
}

.cell:hover {
  stroke: $color-secondary;
  stroke-width: 2px;
}

.hidden {
  visibility: hidden;
}
