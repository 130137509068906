@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.right {
  color: $color-success;
}

.right-occurrence {
  color: $color-success;
  font-weight: $font-weight-bold;
}

.wrong-occurrence {
  color: $color-wrong;
  margin-left: $spacer-2xs;
}

.answers-title {
  font-weight: $font-weight-bold;
  font-size: $font-size-m;
  margin: $spacer-m 0;
  text-align: center;
}

.errors {
  background-color: $color-light;
  font-weight: $font-weight-medium;
  padding: $spacer-xs;
  border-radius: 10px;
  box-shadow: $shadow-light;
  display: flex;
  align-items: center;
  margin-bottom: $spacer-xs;
}

.arrow {
  margin: 0 $spacer-xs;
  color: $color-text-light;
}

.error {
  @include textError();

  display: flex;
  align-items: center;
  margin: 0 $spacer-xs 0 0;
  word-break: keep-all;
  white-space: nowrap;
}

.error-title {
  margin-right: $spacer-3xs;
}

.error-occurrence {
  background-color: $color-light-gray;
  border-radius: 20px;
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  padding: 0 $spacer-xs;
  margin-right: $spacer-xs;
}
