@import '~styles';

:global([data-tippy-root]) {
  max-width: calc(100vw - 10px);
}

.container {
  &:global(.tippy-box[data-animation='fade'][data-state='hidden']) {
    opacity: 0;
  }

  &:global(.tippy-box) {
    background: color(dark);
    border-radius: 4px;
    color: color(light);
    font-size: $font-size-xs;
    line-height: 1.4;
    outline: 0;
    position: relative;
    transition-property: transform, visibility, opacity;
  }

  &:global(.tippy-box[data-placement^='top'] > .tippy-arrow) {
    bottom: 0;
  }

  &:global(.tippy-box[data-placement^='top'] > .tippy-arrow::before) {
    bottom: -$spacer-2xs;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top;
  }

  &:global(.tippy-box[data-placement^='bottom'] > .tippy-arrow) {
    top: 0;
  }

  &:global(.tippy-box[data-placement^='bottom'] > .tippy-arrow::before) {
    top: -$spacer-2xs;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom;
  }

  &:global(.tippy-box[data-placement^='left'] > .tippy-arrow) {
    right: 0;
  }

  &:global(.tippy-box[data-placement^='left'] > .tippy-arrow::before) {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -$spacer-2xs;
    transform-origin: center left;
  }

  &:global(.tippy-box[data-placement^='right'] > .tippy-arrow) {
    left: 0;
  }

  &:global(.tippy-box[data-placement^='right'] > .tippy-arrow::before) {
    left: -$spacer-2xs;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
  }

  &:global(.tippy-box[data-inertia][data-state='visible']) {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }

  :global(.tippy-arrow) {
    width: 16px;
    height: 16px;
    color: color(dark);
  }

  :global(.tippy-arrow::before) {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }

  :global(.tippy-content) {
    position: relative;
    padding: $spacer-3xs $spacer-2xs;
  }
}

.menu-tooltip {
  &:global(.tippy-box) {
    background: $color-text;
    border-radius: 20px;
    box-shadow: $shadow-dark;
    font-size: $font-size-s;

    @include responsiveSmall() {
      display: none;
    }
  }

  :global(.tippy-arrow) {
    color: transparent;
  }

  :global(.tippy-content) {
    padding: $spacer-2xs $spacer-s;
  }
}
