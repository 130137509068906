@import '~styles';

.duration {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.delete {
  margin-left: $spacer-m;
}

.label {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  color: $color-text;
  margin: $spacer-m 0 $spacer-3xs 0;
}

.description {
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
  color: $color-text-light;
  margin: 0 0 $spacer-s 0;
}

.or {
  height: 2px;
  margin: $spacer-2xl 0 40px 0;
  background: $color-light-gray;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
  text-align: center;
}

.or span {
  background: $color-background;
  padding: 0 50px;
  position: relative;
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  top: -$spacer-xs;
}

.small {
  width: 40%;
  margin: $spacer-2xl auto;

  span {
    padding: 0 $spacer-2xl;
  }
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-xs;
  margin-bottom: $spacer-xs;
}
