@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.zone {
  background: color(gray-50);
  border-radius: 1rem;

  &.isDraggingOver {
    background: color(gray-200);
  }
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-2xs;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  color: $color-text;
  cursor: pointer;
  transition: background ease-in-out 250ms;
  border: $border-transparent;

  &:focus-visible {
    border: $border-dark;
  }
}

.aria-label {
  @include ariaLabel();
}

.drag {
  color: color(gray-600);
  flex: 0 0 auto;
  font-size: $font-size-m;
  margin-right: $spacer-s;
  padding: $spacer-3xs $spacer-2xs;
}

.content {
  flex-grow: 1;
}

.action {
  flex: 0 0 auto;
  margin-left: $spacer-3xs;
}

.isDisabled {
  background: color(gray-50);
  cursor: not-allowed;

  &:hover,
  &:focus {
    background: color(gray-50);
  }
}

.isDragging {
  background: color(purple-50);
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .isDragging {
      background: color-light($color);
    }
  }
}
