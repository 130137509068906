@import '~components/Shared/Styles/StyledModal.module.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.open-modal-button {
  margin: auto;
}

.open-modal-button i {
  @include textGradient($color-dark-purple, color(blue-300));

  font-size: $font-size-m;
}

.hidden-overlay {
  background-color: transparent;
}

.countdown {
  font-size: 8.75rem;
  font-weight: $font-weight-bolder;
  color: $color-light;
  width: 245px;
  height: 245px;
  line-height: 245px;
  text-align: center;
  background: $background-gradient-primary;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-preview {
  position: fixed;
  bottom: 90px;
  left: $spacer-2xl;
  width: 213px;
  height: 160px;
}

.stream {
  width: 213px;
  height: 160px;
  border-radius: 15px;
  border: $border-thick-light;
  background-color: $color-light-gray;
}

.loading-message {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: $spacer-xs;
  border-radius: 5px;
  background-color: $color-light;
}

.controller {
  display: flex;
  justify-content: space-between;
  margin-top: $spacer-xs;
  align-items: center;
  border: $border-thick-light;
  border-radius: 30px;
  background-color: $color-pastel-purple;
  padding: $spacer-3xs $spacer-s;
  width: 100%;
}

.delete-button,
.restart-button,
.stop-button {
  border: none;
  width: 20px;
  min-width: 0;
  height: 20px;
  font-size: $font-size-3xs;
  margin-left: $spacer-xs;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.stop-button {
  background-color: $color-dark-red-2;
  color: $color-light;
  margin-left: 0;

  &:hover,
  &:focus {
    background-color: $color-dark-red;
    color: $color-light;
  }
}

.duration {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  margin-left: $spacer-xs;
  padding-right: $spacer-xs;
  border-right: 1px solid $color-light;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -$spacer-m 0 $spacer-m 0;
}

.title {
  margin-bottom: 0 !important;
  text-align: left;
}

.recorded-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacer-m;
}

.footer {
  display: flex;
}

.retake-button {
  margin-right: $spacer-xs;
}

.modal {
  @include styledModal();

  @include responsiveSmall() {
    padding: 40px 40px 50px 40px;
  }
}

@each $color in $palette-theme{
  .theme-#{$color} {
    .open-modal-button i {
      @include textGradient(color-dark($color), color-dark($color));
    }

    .countdown {
      @include buttonTheme($color);
    }

    .controller {
      background-color: color-light($color);
    }

    .submit-button {
      @include buttonTheme($color);

      &:focus,
      &:hover {
        background: color-pastel($color);
      }
    }
  }
}
