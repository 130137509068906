@import '~styles';

.container {
  position: fixed;
  bottom: $spacer-l;
  right: $spacer-l;
  background-color: $color-pastel-blue;
  background-image: url('./images/wave.svg');
  background-repeat: none;
  background-size: cover;
  padding: $spacer-s $spacer-s $spacer-m $spacer-xl;
  border-radius: 25px;
  width: 290px;
  z-index: $z-layer-low-level;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: $spacer-xs;
}

.icon-container {
  height: 60px;
  width: 60px;
  background-color: $color-text;
  padding: $spacer-xs;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  height: 35px;
  width: 35px;
  margin: auto;
}

.close {
  border: $border-light-transparent;
  background-color: transparent;
  color: $color-light;
}

.title {
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-xs;
}

.subtitle {
  margin-bottom: $spacer-xs;
}

.input {
  margin-bottom: $spacer-xs;
  border: none;
}

.submit {
  width: 100%;
}
