@import '~styles';

.container {
  display: flex;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.preview {
  flex-grow: 1;
  padding: $spacer-s $spacer-3xl;

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}

.explanation-preview {
  color: $color-dark-orange;
  font-size: $font-size-xs;
  font-weight: bold;
  border-radius: 10px;
  background-color: $color-light-orange;
  margin-bottom: $spacer-2xl;
  padding: $spacer-xs $spacer-2xl;

  @include responsiveSmall() {
    margin-bottom: $spacer-s;
  }
}

.question {
  max-width: 960px;
  margin: auto;
}

.comments {
  padding: $spacer-s;
  border-left: 1px solid $color-blue-light;
  width: 340px;
  min-height: calc(100vh - 75px);

  @include responsiveSmall() {
    padding: $spacer-xs;
    border-left: none;
    border-top: 1px solid $color-blue-light;
    width: 100%;
  }
}

.comment {
  margin-bottom: $spacer-s;
}

.thread {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-m;
}

.thread-title {
  @include textBolderDark();

  margin-left: $spacer-xs;
}
