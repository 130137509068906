@import '~styles';

.legend-pin {
  position: absolute;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  touch-action: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: $background-gradient-primary;
  border: $border-light;
  font-weight: $font-weight-bolder;
  color: white;
  cursor: grab;
  opacity: 0.85;

  &:focus,
  &:hover {
    outline: none;
  }
}

.isActive {
  opacity: 1;
}
