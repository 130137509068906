@import '~styles';

.container {
  width: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: color(gray-200);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: $border-transparent;
  border-radius: 1rem;
  color: color(gray-700);
  transition: background-color 250ms ease-in-out;

  &:hover,
  &:focus {
    background-color: color(gray-300);
    outline: none;
    border: $border-dark;
  }
}

.isDisabled {
  cursor: not-allowed;
  border: $border-transparent !important;
  background-color: color(gray-200) !important;
}

.hasError {
  color: $color-wrong;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
  text-align: left;
}
