@import '~styles';

@mixin innerStyledSubPage() {
  .title {
    margin-bottom: $spacer-2xl;
    color: $color-text;
    font-size: $font-size-m;
    font-weight: $font-weight-bolder;
  }

  .subtitle {
    @include textNormalDark();

    margin-bottom: $spacer-2xl;
  }

  .error {
    @include textError();

    margin-bottom: $spacer-l;
  }

  .field {
    margin-bottom: $spacer-s;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacer-xl;

    @include responsiveSmall() {
      flex-direction: column;
    }
  }

  .action {
    @include responsiveSmall() {
      & + .action {
        margin-top: $spacer-xs;
      }
    }
  }
}

@mixin styledSubPage() {
  @include innerStyledSubPage();

  padding: $spacer-s $spacer-3xl;

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}
