@import '~styles';

.container {
  padding: 80px;
  text-align: center;

  @include responsiveSmall() {
    padding: $spacer-s;
  }
}

.image {
  margin-bottom: $spacer-2xl;
  max-width: 100%;
  width: 160px;
}
