@import '~styles';

.container {
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
}

.field {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:hover ~ .slider,
  &:focus ~ .slider {
    border: $border-dark;
  }
}

.slider {
  width: 56px;
  height: 30px;
  background: $color-text-light;
  flex: 0 0 auto;
  margin-right: $spacer-s;
  position: relative;
  transition: background 250ms ease-in-out;
  border: $border-transparent;
  border-radius: 15px;

  &::after {
    content: '';
    height: 20px;
    width: 20px;
    background: $color-light;
    border-radius: 50%;
    position: absolute;
    left: $spacer-3xs;
    top: $spacer-3xs;
    transition: left 250ms ease-in-out, transform 250ms ease-in-out;
  }

  &.isChecked {
    background: $background-gradient-primary;
    border: $border-primary;

    &::after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }
}

.content {
  flex-grow: 1;
  font-size: $font-size-xs;
  text-align: left;
}

.title {
  display: flex;
  justify-content: flex-start;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  margin-right: $spacer-xs ;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.children {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled {
  cursor: not-allowed;

  .slider,
  &:hover .slider,
  .slider.isChecked {
    background: rgba(206, 217, 224, 0.5) !important;
    border-color: transparent;
    color: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
  }

  .content,
  .error-message {
    opacity: 0.5;
  }
}

.hasError .slider {
  background: $color-wrong !important;
}

.intent-ia {
  .slider {
    height: 14px;
    width: 36px;
    background-color: $color-pastel-purple;

    &::after {
      background: $background-gradient-ia;
      transform: rotateZ(180deg);
      position: absolute;
      left: -$spacer-3xs;
      top: -$spacer-3xs;
    }

    &.isChecked {
      border: none;

      &::after {
        left: calc(100% - 20px);
        top: -$spacer-3xs;
      }
    }
  }

  .title {
    margin-bottom: 0;
  }

  .label {
    font-weight: $font-weight-medium;
  }
}
