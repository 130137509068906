@import '~styles';

.avatar {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background-color: $color-light-purple-2;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.initial {
  @include textGradient($color-dark-purple, rgba(192, 168, 255, 1));

  font-size: $font-size-s;
  font-weight: $font-weight-bolder;
}

.icon {
  @include textGradient($color-dark-purple, rgba(192, 168, 255, 1));

  background-color: $color-light-purple-2;
}

.teacher-icon-container {
  background-color: $color-light-purple-2;
  position: absolute;
  top: -$spacer-3xs;
  right: -$spacer-3xs;
  width: 20px;
  height: 20px;
  border-radius: 17px;
  border: $border-light;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teacher {
  font-size: $font-size-3xs;
}
