@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.answer-type-container {
  display: flex;
  margin-bottom: $spacer-2xl;
  align-items: center;
}

.answer-type-button {
  position: relative;
  width: 250px;
  height: 160px;
  background-color: $color-light;
  border-radius: 15px;
  padding: $spacer-2xl $spacer-xs $spacer-l $spacer-xs;
  border: $border-gray-light;

  &:hover,
  &:focus {
    border-color: $color-text;
  }

  @include responsiveSmall() {
    height: fit-content;
  }

  .checkbox {
    position: absolute;
    top: $spacer-xs;
    right: $spacer-xs;
    height: 25px;
    width: 25px;
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $color-light;
    border: $border-gray-light;
    border-radius: 5px;
    color: $color-light;
    cursor: pointer;
    font-size: $font-size-3xs;
  }
  &.isChecked {
    border-color: $color-primary;

    .checkbox {
      background: $background-gradient-primary;
      color: $color-light;
      border-color: $color-light;
    }
  }
}

.answer-type-icon-container {
  padding: $spacer-2xl;
  background: $color-light-purple-3;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto $spacer-s auto;
}

.answer-type-icon {
  @include textGradient($color-dark-purple, color(blue-300));

  font-size: $font-size-l;
}

.answer-type-text {
  font-weight: $font-weight-bolder;
  font-size: $font-size-2xs;
}

.or {
  font-size: $font-size-m;
  margin: 0 $spacer-m;

  @include responsiveSmall() {
    font-size: $font-size-2xs;
    margin: 0 $spacer-2xs;
  }
}
