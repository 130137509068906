@import '~styles';

@mixin aggregate() {
  .background {
    border-radius: 10px;
    background-color: $color-light;
    padding: $spacer-2xl;
    box-shadow: $shadow-light;
    margin-bottom: $spacer-m;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .title-container {
    display: flex;
    max-width: 75%;
  }

  .icon {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    height: 32px;
    justify-content: center;
    width: 32px;
    margin-right: $spacer-m;
    border-radius: 5px;

    img {
      max-height: 20px;
      max-width: 20px;
    }
  }

  .title {
    color: $color-text;
    font-size: $font-size-m;
    font-weight: $font-weight-bolder;
    margin-bottom: $spacer-s;

    @include responsiveSmall() {
      margin-bottom: $spacer-s;
    }
  }

  .answers-numbers {
    text-align: right;
    background-color: $color-light-green-2;
    padding: $spacer-2xs;
    border-radius: 5px;
    max-height: 40px;
  }

  .answers-numbers-wrong {
    background-color: $color-light-red-2;
  }

  .correct-answers {
    font-weight: $font-weight-bold;
    color: $color-dark-green-2;
  }

  .isEmpty {
    color: $color-dark-red-2;
  }

  .total-answers {
    color: $color-dark-green-2;
  }

  .total-answers-wrong {
    color: $color-dark-red-2;
  }

  @each $color in $palette-theme {
    .background-#{$color} {
      background: color-light($color);
    }
  }
}
