@import '~styles';

.container {
  border-radius: 25px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  padding: 0 $spacer-l;
}

.isSectionOpen {
  padding: 0 $spacer-l $spacer-l $spacer-l;
}

.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: $spacer-l 0;

  &:focus,
  &:hover {
    outline: none;
  }
}

.title-container {
  display: flex;
  align-items: center;
}

.title-icon {
  margin-right: $spacer-xs;
  height: 40px;
  width: 40px;
  line-height: 2.5rem;
  text-align: center;
  font-weight: $font-weight-bolder;
  border-radius: 7px;
  background: $color-pastel-pink;
  color: $color-text;
  font-size: $font-size-m;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.count {
  padding: $spacer-3xs $spacer-xs;
  margin-left: $spacer-xs;
  border-radius: 15px;
  background-color: $color-light-pink;
  color: $color-dark-pink;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.toggle-button {
  width: 40px;
  height: 40px;
  line-height: 2.188rem;
  text-align: center;
  border-radius: 30px;
  background-color: $color-light-black;

  &:hover {
    background-color: $color-light-gray;
  }
}

.tags-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tags {
  display: grid;
  grid-gap: $spacer-2xl;
  grid-template-columns: 1fr 1fr 1fr;

  @include responsiveMedium() {
    grid-gap: $spacer-m;
    grid-template-columns: 1fr 1fr;
  }

  @include responsiveSmall() {
    grid-gap: $spacer-xs;
    grid-template-columns: 1fr;
  }
}

.tags-toggle {
  margin: $spacer-2xl auto 0 auto;
}

.tags-toggle-icon {
  margin-left: $spacer-xs;
}
