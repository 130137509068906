@import '~styles';

.container {
  z-index: $z-layer-tooltip;
}

.large {
  width: 100%;
}

.toggle-default {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  padding: $spacer-s $spacer-l;
  color: $color-text-light;
  text-align: left;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  transition: color ease-in-out 250ms;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
    text-decoration: $decoration-none;
  }

  &:focus-visible,
  &:focus-within {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }

  &.small {
    padding: $spacer-s $spacer-2xs;

    @include responsiveSmall() {
      padding: $spacer-s $spacer-l;
    }
  }

  @include responsiveSmall() {
    width: 50%;
  }
}

.toggle-default-icon {
  margin-right: $spacer-m;
  min-width: 25px;
  text-align: center;
  font-size: $font-size-m;
}

.toggle-default-text {
  &.hidden {
    display: none;

    @include responsiveSmall() {
      display: block;
    }
  }
}

.toggle-square {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 60px;
  background: $color-light;
  border: $border-light;
  border-radius: 10px;
  box-shadow: $shadow-purple;
  color: $color-text;
  font-weight: $font-weight-bolder;
  font-size: $font-size-2xs;

  &:hover,
  &:focus {
    outline: none;
    border-color: $color-text;
  }

  .toggle-text {
    margin-right: $spacer-3xs;
  }

  .toggle-icon {
    font-size: $font-size-3xs;
  }
}
