@import '~styles';

.large-screen {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: $spacer-xs;

  @include responsiveMedium() {
    display: none;
  }
}

.secondary-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: $spacer-3xs;
  padding-right: $spacer-xs;
  border-right: 2px solid $color-light-gray;
}

.main-actions {
  display: flex;
  gap: $spacer-xs;
  flex-wrap: nowrap;
}

.medium-screen {
  display: none;

  @include responsiveMedium() {
    display: block;
  }

  @include responsiveSmall() {
    display: none;
  }
}
