@import '~styles';

.droppable {
  background: transparent;
  border: $border-transparent;
  border-radius: 10px;
  min-height: 60px;
  transition: background 250ms ease-in-out;
}

.droppable.isDraggingOver {
  background: color(gray-200);
  border-color: color(purple-400);
}
