@import '~components/Shared/Styles/Auth.module.scss';

.container {
  @include styledAuth();
}

.tippy-content {
  background-color: red !important;
  width: 400px !important;
}

.or {
  margin: $spacer-m 0 $spacer-l 0 !important;
}

.or span {
  background: $color-background !important;

  @include responsiveSmall() {
    background-color: transparent !important;
  }
}

.section {
  @include responsiveSmall() {
    @include styledSection();

    margin-bottom: $spacer-m;
  }
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;
  padding-right: $spacer-m;
  margin-bottom: $spacer-m;

  &:focus-within {
    border-color: $color-text;
  }
}

.input {
  @include textBoldDark();

  display: block;
  border-radius: 22.5px;
  border: none;
  width: 100%;
  padding: $spacer-xs $spacer-l;
  height: 44px;
  min-width: 1rem;

  &::placeholder {
    color: color(gray-700);
  }
}

.menu-intern {
  max-height: 300px;
  overflow-y: scroll;
}

.button {
  width: 100%;
}
