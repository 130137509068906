@import '~components/Shared/Styles/StyledModal.module.scss';
@import '~styles';

.modal {
  @include styledModal();

  width: 690px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grade-label {
  @include textMediumDark();

  text-align: left;
  margin-top: $spacer-2xl;
}

.stars {
  padding: $spacer-2xl;
  border: $border-gray-light;
  border-radius: 15px;
  margin: $spacer-xs 0 $spacer-2xl 0;
}

.star {
  background-color: transparent;
  border: none;
  font-size: $font-size-l;
  margin-right: $spacer-l;

  &:focus,
  &:hover {
    outline: none;
  }
}

.icon {
  @include textGradient(#8258f3, #c0a8ff);
}

.feedback {
  margin-bottom: $spacer-2xl;

  div {
    @include textMediumDark();
  }
}

.checkbox {
  p {
    @include textMediumDark();
  }
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacer-2xl;
}

.action {
  margin: 0 0 0 $spacer-xs !important;
}
