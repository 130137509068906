@import '~styles';

.container {
  width: 45px;
  gap: $spacer-xs;
  padding: $spacer-xs 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;

  @include responsiveLarge() {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: $spacer-xs;
  }
}

.action {
  margin-bottom: $spacer-xs;
  max-width: 40px;

  @include responsiveMedium() {
    margin-bottom: 0;
    margin-right: $spacer-xs;
  }
}

.background-button {
  i {
    font-size: $font-size-l;
    border-radius: 30px;
  }
}

.color-title {
  text-align: center;
  font-weight: $font-weight-bolder;
  padding: $spacer-s 0 0 0;
}

.colors {
  display: grid;
  grid-template-columns: repeat(4, 45px);
  gap: $spacer-xs;
  margin: $spacer-m;
}

.color {
  height: 45px;
  border: none;

  &.image-picker {
    border: $border-thick-gray-light;
  }
}

.delete-button {
  width: 210px;
  margin: -$spacer-xs 0 $spacer-m $spacer-m;
}

.font-family,
.font-size,
.font-weight,
.font-style,
.text-align {
  padding: $spacer-xs;
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacer-xs;
}

.font-family {
  width: 180px;
  max-height: 480px;
}

.font-family-button {
  width: fit-content;
  margin: auto;
}

.font-size-action {
  font-weight: $font-weight-normal;
  font-size: $font-size-xs;
  width: 90px;
  height: 40px;
  background-color: $color-light;
  border: none;
  border-radius: 30px;

  &:hover,
  &:focus {
    outline: none;
    background-color: $color-light-black;
  }
}

.font-weight-button,
.font-style-button,
.text-align-button {
  padding: $spacer-xs;
}

@each $color in $palette-background {
  .current-background-#{$color} {
    i {
      color: color-dark($color);
    }
  }

  .background-#{$color} {
    background: gradient-dark($color) !important;
  }
}

.current-background-white {
  i {
    color: $color-light;
    border: $border-gray-light;
  }
}

.current-background-transparent {
  i {
    color: $color-background;
    border: $border-gray-light;
  }
}

.current-background-image-picker {
  i {
    color: $color-text;
  }
}

.background-white {
  background-color: $color-light;
  border: $border-thick-gray-light;
}

.background-transparent {
  background-color: $color-background;
  border: $border-thick-gray-light;
}
