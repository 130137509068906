@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.subtitle {
  margin-bottom: $spacer-s;
  padding: $spacer-m $spacer-l;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  color: $color-text;
}

.experts {
  display: flex;
  justify-content: space-around;
  gap: $spacer-3xs;
  flex-wrap: wrap;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.expert {
  flex: 0 0 19%;
  padding: $spacer-m $spacer-l;
  text-align: center;
  border-radius: 10px;
  background-color: $color-light;
  border: $border-transparent;
  box-shadow: $shadow-blue;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;

  @include responsiveMedium() {
    padding: $spacer-m;
  }

  @include responsiveSmall() {
    margin-bottom: $spacer-3xs;
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: $color-text;
  }

  &.isActive {
    border: $border-primary;
    color: $color-primary;
  }
}

@each $color in $palette-theme{
  .theme-#{$color} {
    .expert {
      &.isActive {
        border: 2px solid color-dark($color);
        color: color-dark($color);
      }
    }
  }
}
