@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.answer {
  font-size: 110%;
}

.duration {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.audio-preview {
  margin: auto;
}
