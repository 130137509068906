@import '~styles';

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-layer-mid-level;
}

:global(.ReactModal__Overlay) {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
}

:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
}

.modal {
  background: none;
  margin: $spacer-2xl auto;
  z-index: $z-layer-modal;
  max-width: 98%;
  width: min-content;
  width: 100%;

  @include responsiveSmall() {
    margin: $spacer-xs auto;
  }
}
