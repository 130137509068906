@import '~styles';

.container {
  padding: 5rem;
  text-align: center;

  @include responsiveSmall() {
    padding: 1rem;
  }
}

.image {
  margin-bottom: $spacer-2xl;
  max-width: 100%;
  width: 10rem;
}
