@import '~styles';

.container {
  text-align: left;
}

.buttons {
  display: flex;
  align-items: center;
  gap: $spacer-2xs;
}

.button {
  height: 60px;
  width: 60px;
  background: $color-light;
  border: $border-transparent;
  color: $color-text;
  font-size: $font-size-m;
  border-radius: 15px;

  &:hover,
  &:focus {
    border-color: $color-text;
    outline: none;
  }

  &.isChecked {
    background: $background-gradient-primary;
    border-width: 0;
    color: $color-light;

    &:hover,
    &:focus {
      background: $background-gradient-primary;
      border-color: $color-text;
      border-width: 2px;
    }
  }
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.children {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled {
  pointer-events: none;

  .label {
    color: color(gray-700);
  }

  .helper {
    color: color(gray-300);
  }
}

.hasError .checkbox {
  border-color: $color-wrong;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .button {
      &.isChecked {
        @include buttonTheme($color);

        &:hover,
        &:focus {
          @include buttonTheme($color);
        }
      }
    }
  }
}
