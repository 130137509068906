@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.picker :global(.react-datetime-picker__wrapper) {
  background: transparent;
  border: none;
  height: 2.75rem;
  font-size: $font-size-s;
  min-width: 1rem;
  margin: 0 $spacer-s;
}

.field {
  width: 100%;
}

.field :global(.react-datetime-picker__wrapper) {
  @include textBoldDark();

  padding: $spacer-xs $spacer-l;
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;

  &:focus-within {
    border-color: $color-text;
  }

  &::placeholder {
    color: color(gray-700);
  }
}

.hasError :global(.react-datetime-picker__wrapper) {
  border-color: $color-wrong;
}

.field :global(.react-datetime-picker__inputGroup) input {
  @include textBoldDark();
}

.field .date:invalid {
  border: none;
  box-shadow: none;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled .field {
  background: color(gray-200);
  cursor: not-allowed;
  color: color(gray-700);
}

:global(.rdtPicker) {
  border: $border-black-light;
}

:global(.react-datetime-picker__calendar--open) {
  z-index: $z-layer-low-level;
}
