@import './palette.scss';

// Font Sizes:
$font-size-3xs: 0.625rem;
$font-size-2xs: 0.75rem;
$font-size-xs: 0.875rem;
$font-size-s: 1rem;
$font-size-m: 1.313rem;
$font-size-l: 1.75rem;
$font-size-xl: 2.25rem;
$font-size-2xl: 2.625rem;
$font-size-3xl: 3.75rem;

// Font Family
$font-family-nunito: 'Nunito', 'Arial', sans-serif;
$font-family-nunito-wysiwyg: 'Nunito', '-apple-system', 'BlinkMacSystemFont',
  'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Font Weight
$font-weight-normal: 300;
$font-weight-medium: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// Case Styles
$case-style-none: none;
$case-style-uppercase: uppercase;
$case-style-capitalize: capitalize;
$case-style-lowercase: lowercase;

// Decoration Styles
$decoration-none: none;
$decoration-underline: underline;
$decoration-underline-dotted: underline dotted;
$decoration-line-through: line-through;

@mixin titleH1Big() {
  font-size: $font-size-xl;
  font-weight: $font-weight-bolder;
  color: $color-text;
}

@mixin titleH1Normal() {
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  color: $color-text;
}

@mixin textNormalDark {
  font-size: $font-size-s;
  font-weight: $font-weight-normal;
  color: $color-text;
}

@mixin textNormalLight {
  font-size: $font-size-s;
  font-weight: $font-weight-normal;
  color: $color-text-light;
}

@mixin textMediumDark {
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $color-text;
}

@mixin textMediumLight {
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $color-text-light;
}

@mixin textBoldDark {
  font-size: $font-size-s;
  font-weight: $font-weight-bold;
  color: $color-text;
}

@mixin textBoldLight {
  font-size: $font-size-s;
  font-weight: $font-weight-bold;
  color: $color-text-light;
}

@mixin textBolderDark {
  font-size: $font-size-s;
  font-weight: $font-weight-bolder;
  color: $color-text;
}

@mixin textBolderLight {
  font-size: $font-size-s;
  font-weight: $font-weight-bolder;
  color: $color-text-light;
}

@mixin textError {
  font-size: $font-size-s;
  font-weight: $font-weight-bold;
  color: $color-wrong;
}
