@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.choice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-xs;
  box-shadow: $shadow-light;
  border-radius: 5px;
  padding: $spacer-xs;

}

.check-icon {
  color: $color-light;
  background-color: $color-success;
  border-radius: 50px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 1.875rem;
  text-align: center;
  margin-right: $spacer-xs;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.choice-title {
  max-width: 25%;
  margin-right: $spacer-xs;

  img {
    max-width: 100% !important;
  }
}

.gauge {
  position: relative;
  margin-right: $spacer-s;
  height: 20px;
  width: 100%;
  background-color: $color-blue-light;
  border-radius: 20px;
  background: $color-light-gray;

  & > .progression {
    height: 20px;
    border-radius: 20px;
    background: $background-gradient-primary;
  }

  &.success > .progression {
    background: $background-gradient-green;
  }

  & > .animation {
    animation-name: percentage;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0, 1.1, 0.55, 1.01);
    height: 20px;
    border-radius: 20px;
    background: $color-light-gray;
    position: absolute;
    right: 0;
    top: 0;
  }

  @keyframes percentage {
    0% {
      width: 100%;
    }
    10% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }

  .percentage {
    position: absolute;
    top: 1px;
    left: calc(50% - 25px);
    background: $color-light;
    font-size: $font-size-2xs;
    border-radius: 10px;
    width: 50px;
    text-align: center;
    color: $color-primary;
    font-weight: $font-weight-bold;
  }

  &.success > .percentage {
    color: $color-success;
  }
}

.votes {
  min-width: 80px;
}
