@import '~styles';

.container {
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $color-pastel-purple;
  box-shadow: $shadow-blue;
  padding: 50px 0;

  @include responsiveSmall() {
    padding: 40px $spacer-m $spacer-m $spacer-m;
  }
}

.title {
  color: $color-light;
  text-align: center;
  font-size: $font-size-xl;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-2xl;
}

.title-with-introduction {
  margin-bottom: $spacer-xs;
}

.introduction {
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $color-light;
  margin: 0 0 $spacer-2xl 0;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: center;

  @include responsiveSmall() {
    flex-direction: column;
    width: 50%;
    margin-bottom: 40px;
  }
}

.moodle-button {
  height: 80px;
  font-size: $font-size-m;
  box-shadow: $shadow-dark;
  transition: box-shadow 100ms ease-in-out;

  &:hover,
  &:focus {
    background-color: $color-light;
    box-shadow: $shadow-dark;
  }
}

.moodle-image {
  width: 1.5rem;
}

.action {
  margin: 0 $spacer-2xs $spacer-xs $spacer-2xs;

  @include responsiveSmall() {
    margin: 0 0 $spacer-xs 0;
  }
}

.menu {
  position: absolute;
  right: $spacer-xs;
  top: $spacer-xs;
}

.propose-question {
  position: absolute;
  right: $spacer-xs;
  bottom: $spacer-xs;
}

.review {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 $spacer-s 0 $spacer-2xl;
  border-radius: 50px;
  background-color: $color-text;
  box-shadow: $shadow-dark;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  text-align: left;
  color: $color-light;
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;

  &:hover,
  &:focus {
    box-shadow: $shadow-dark;
    transform: translateY(-5px);
    text-decoration: $decoration-none;
    color: $color-light;
  }

  @include responsiveSmall() {
    width: 100%;

    &:hover,
    &:focus {
      transform: scale(1.01);
    }
  }
}

.chevron {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  background-color: $color-light;
  color: $color-text;
  margin-left: 40px;
  text-align: center;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    background-color: color-pastel($color);
  }
}
