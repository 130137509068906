@import '~questions/Shared/Styles/Face.module.scss';

.aria-label {
  @include ariaLabel();
}

.blank {
  font-size: $font-size-s;
  line-height: 1rem;
  display: inline-block;
  vertical-align: middle;

  input {
    border: $border-gray-light;
    border-radius: 4rem;
    min-width: 3rem;
    height: 35px;
    line-height: 35px;
    font-weight: $font-weight-bold;
    padding: 0 $spacer-l;

    &:focus {
      border-color: $color-text;
    }
  }
}
