@import './palette.scss';
@import './typography.scss';

.fr-view {
  font-family: $font-family-nunito-wysiwyg;
  font-size: $font-size-s;
  font-weight: $font-weight-normal;
  line-height: 1.5;
  text-align: left;
  word-break: break-word;

  pre {
    background: color(gray-100);
    padding: $spacer-2xs;
  }
}
