@import '~styles';

.answer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.625rem;
  padding: 10px 15px;
  border-radius: 15px;

  &.selected {
    background-color: $color-light-black;
  }
}

.answer-index {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: $color-light-red-2;
  border: 2px solid $color-dark-red-2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  color: $color-text;
  flex: 0 0 auto;

  &.isCorrect {
    background: $color-light-green-2;
    border: 2px solid $color-pastel-green;
  }
}

.corrections {
  flex-grow: 1;
  margin-left: 20px;
}

.correction-wrong-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.correction {
  background: $color-light-red-2;
  border: 2px solid $color-dark-red-2;
  border-radius: 40px;
  padding: 6px 20px;
  width: 100%;
  color: $color-text;
  font-weight: 400;
}

.correct {
  background: $color-light-green-2;
  border: 2px solid $color-pastel-green;
}

.icon-small {
  display: none;

  @include responsiveSmall {
    display: block;
  }
}

.icon-large {
  @include responsiveSmall {
    display: none;
  }
}

.aligned {
  align-items: center;
  margin-bottom: 0;
  width: 100%;

  .corrections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 15px;
  }

  .correction-wrong-container {
    flex-direction: row;
    gap: 15px;
  }

  @include responsiveSmall {
    align-items: flex-start;
    margin-bottom: 0.625rem;

    .corrections {
      grid-template-columns: 1fr;
      gap: 5px;
    }

    .correction-wrong-container {
      flex-direction: column;
      gap: 5px;
    }
  }
}
