// stylelint-disable at-rule-no-unknown, at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix, no-descending-specificity
@import './borders';
@import '~rfs/scss';
@import './palette.scss';
@import './typography.scss';
@import './spacers.scss';

//
// styles targeting irrelevant browsers while applying new styles.
//

//
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus,
*:hover,
*:active {
  outline: none;
}

html {
  font-size: 100%;
}

//
body {
  @include font-size(1rem);

  font-family: $font-family-nunito;
  font-weight: $font-weight-normal;
  line-height: 1.5;
  color: $color-text;
  text-align: left;
  background-color: $color-background; // 2
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba(#000, 0); // 4
}

// interaction that led to the element receiving programmatic focus was a keyboard interaction,
//
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

//
hr {
  color: inherit; // 1
  background-color: currentColor;
  border: none;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px; // 2
}

//

%heading {
  font-weight: $font-weight-bold;
  line-height: 1.2;
}

h1 {
  @extend %heading;

  @include font-size(2.5rem);
}

h2 {
  @extend %heading;

  @include font-size(2rem);
}

h3 {
  @extend %heading;

  @include font-size($font-size-l);
}

h4 {
  @extend %heading;

  @include font-size(1.5rem);
}

h5 {
  @extend %heading;

  @include font-size(1.25rem);
}

h6 {
  @extend %heading;

  @include font-size($font-size-s);
}

//
abbr[title],
abbr[data-original-title] {
  // 1
  text-decoration: $decoration-underline; // 2
  text-decoration: $decoration-underline-dotted; // 2
  cursor: help; // 3
  text-decoration-skip-ink: none; // 4
}

address {
  font-style: normal;
  line-height: inherit;
}

dt {
  font-weight: $font-weight-bold;
}

b,
strong {
  font-weight: $font-weight-bold;
}

small {
  @include font-size(0.875em);
}

sub,
sup {
  @include font-size(0.75em);

  position: relative;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: color(purple-500);
  text-decoration: $decoration-none;

  &:hover,
  &:focus {
    color: color(purple-500);
    text-decoration: $decoration-underline;
  }
}

a:not([href]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: $decoration-none;
  }
}

pre,
code,
kbd,
samp {
  @include font-size(1em); // Correct the odd `em` font sizing in all browsers.

  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
}

pre {
  @include font-size(0.875em);

  display: block;
  overflow: auto; // 3
  color: #333;

  code {
    @include font-size(inherit);

    color: inherit;
    word-break: normal;
  }
}

code {
  @include font-size(0.875em);

  color: #333;
  word-wrap: break-word;

  a > & {
    color: inherit;
  }
}

kbd {
  @include font-size(0.875em);

  border-radius: 0.25rem;
  color: $color-light;
  background-color: #999;

  kbd {
    @include font-size(1em);

    font-weight: $font-weight-bold;
  }
}

img {
  vertical-align: middle;
}

svg {
  overflow: hidden; // 1
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  color: #ccc;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

td {
  border: $border-thin-gray-light;
}

label {
  display: inline-block; // 1
}

//
button {
  // stylelint-disable-next-line property-blacklist
  border-radius: 0;
}

//
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  @include font-size(inherit);

  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: $case-style-none;
}

//
select {
  word-wrap: normal;
}

//
[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2

  &:not(:disabled) {
    cursor: pointer; // 3
  }
}

::-moz-focus-inner {
  border-style: none;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: textfield;
}

textarea {
  overflow: auto; // 1
  resize: vertical; // 2
}

fieldset {
  min-width: 0; // 1
  border: none; // 2
}

legend {
  @include font-size(1.5rem);

  float: left; // 1
  width: 100%;
  line-height: inherit;
  color: inherit; // 2
  white-space: normal; // 3
}

mark {
  background-color: #fcf8e3;
}

progress {
  vertical-align: baseline;
}

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0;
}

[type='search'] {
  outline-offset: -2px; // 1
  -webkit-appearance: textfield; // 2
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit; // 1
  -webkit-appearance: button; // 2
}

output {
  display: inline-block;
}

summary {
  display: list-item; // 1
  cursor: pointer;
}

template {
  display: none;
}

main {
  display: block;
}

[hidden] {
  display: none !important;
}

ul,
ol {
  list-style-position: inside;
}

ul ul,
ol ul,
ol ol,
ul ol {
  margin-left: $spacer-s;
}
