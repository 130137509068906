@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.image {
  width: 98%;
  margin: auto;
}
