@import '~styles';

.container {
  border-radius: 20px;
  background: $color-light-black;
  padding: $spacer-xs;

  &.tooltip {
    transform: translateY(-40%);
    width: 185px;
    padding: $spacer-m;
    background: $color-light;
    box-shadow: $shadow-blue;
  }
}

.header {
  display: flex;
  align-items: center;
}

.course-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-3xs;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background: gradient-light('purple');
  color: $color-light;
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    display: none;
  }
}

.title {
  @include textBolderDark();
}

.duration-container {
  display: flex;
  align-items: center;
  margin: $spacer-xs 0 $spacer-xs 0;

  &.tooltip {
    margin: $spacer-3xs 0 $spacer-xs $spacer-2xl;
  }
}

.duration-icon {
  font-size: $font-size-3xs;
  color: $color-primary;
  margin-right: $spacer-xs;
}

.study-duration {
  font-size: $font-size-2xs;
  color: $color-text-light;

  span {
    font-weight: $font-weight-bolder;
    margin-right: $spacer-3xs;
    color: $color-text;
  }

  &.tooltip {
    font-size: $font-size-3xs;
  }
}

.stat {
  color: $color-text-light;
  font-size: $font-size-2xs;
  margin: 0 0 $spacer-xs $spacer-s;
  position: relative;

  span {
    font-weight: $font-weight-bolder;
    margin-right: $spacer-3xs;
    color: $color-text;
  }

  &::before {
    content: '';
    height: 7px;
    width: 7px;
    border-radius: 10px;
    background-color: $color-success;
    position: absolute;
    left: -$spacer-s;
    top: $spacer-3xs;
  }

  &.seen {
    &::before {
      background-color: $color-dark-gray;
    }
  }

  &.wrong {
    &::before {
      background-color: $color-wrong;
    }
  }

  &.unknown {
    &::before {
      background-color: $color-dark-yellow;
    }
  }
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .course-icon {
      background: color-dark($color);
    }
  }
}
