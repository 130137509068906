@import '~styles';

.container {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
  border-radius: 10px;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin-left 200ms ease-in-out, padding 200ms ease-in-out;
  padding: 0 $spacer-l 0 0;
  min-height: 58px;
  border: $border-transparent;
  width: 100%;
  margin-left: 0;
  background-color: $color-light;

  &:hover,
  &:focus {
    width: 101%;
    padding: 0 calc($spacer-l + 0.5%) 0 0.5%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;

    .sort {
      visibility: visible;
    }
  }

  &:focus-within {
    border: $border-dark;
  }

  @include responsiveSmall() {
    padding: 0 $spacer-xs 0 0;

    &:hover,
    &:focus {
      padding: 0 calc($spacer-xs + 0.5%) 0 0.5%;
    }

    .sort {
      visibility: visible;
    }
  }
}

.aria-drag-description {
  @include ariaLabel();
}

.sort {
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  color: $color-text-light;
  background: none;
  border-radius: 50px;
  border: $border-transparent;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
  }

  &:focus-visible {
    border-color: $color-text;
  }

  @include responsiveSmall() {
    padding: 0 $spacer-xs;
  }
}

.checkbox-container {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  margin-right: $spacer-xs;

  &:focus,
  &:hover {
    outline: none;
  }

  &:focus-visible .checkbox {
    border: $border-dark;
  }
}

.field {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:hover ~ .checkbox,
  &:focus ~ .checkbox {
    border-color: $color-dark-purple;
  }
}

.checkbox {
  height: 25px;
  width: 25px;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-light;
  border: $border-blue-light;
  border-radius: 5px;
  color: $color-light;
  cursor: pointer;
  font-size: $font-size-3xs;

  &.isChecked {
    background: $background-gradient-primary;
    color: $color-light;

    &:hover,
    &:focus {
      background: $background-gradient-primary;
      border-color: $color-text;
    }
  }
}

.clickable-area {
  @include textOverflow();

  cursor: pointer;
  display: flex;
  align-items: center;
  gap: $spacer-xs;
  flex-grow: 1;

  &:hover,
  &:focus {
    outline: none;
  }
}

.number {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  font-weight: $font-weight-bolder;
  font-size: $font-size-2xs;
  margin: 0 (-$spacer-xs) 0 (-$spacer-m);
  color: $color-light;
  background-color: $color-secondary;
  text-align: center;
  line-height: 1.5rem;
}

.icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: center;
  width: 32px;
  margin-right: $spacer-s;
  border-radius: 5px;

  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.position {
  font-size: $font-size-xs;
}

.aria-edit-button {
  position: absolute;

  @include ariaLabel();
}

.edit-button {
  border: none;
  background-color: transparent;
  flex-grow: 1;
  border: $border-transparent;

  &:focus-visible {
    border: $border-dark;
  }
}

.title {
  @include textOverflow();
  @include textNormalDark();

  flex-grow: 1;
  padding: $spacer-s $spacer-xs $spacer-s $spacer-xs;
  text-align: left;
}

.author {
  display: flex;
  align-items: center;
  color: #1d254f;
  line-height: 0.938rem;
  width: 150px;
  flex: 0 0 150px;
}

.author-name {
  @include textOverflow();
}

.disabled {
  color: $color-wrong;
  font-size: $font-size-xs;
  margin-right: $spacer-3xs;
}

.action {
  flex: 0 0 auto;
}

.comment-notification {
  background-color: $color-secondary;
  border-radius: 50px;
  height: 16px;
  width: 16px;
  margin: -$spacer-m 0 0 (-$spacer-m);
  border: $border-thick-light;
  z-index: $z-layer-neutral;
}

.container.isUnavailable {
  background: repeating-linear-gradient(
    45deg,
    color(red-50),
    color(red-50) 10px,
    color(light) 10px,
    color(light) 20px
  );
}

.container.isSelected,
.container.isDragging {
  background: color(purple-400);

  .icon,
  .position,
  .title,
  .sort {
    color: color(light);
  }
}

@each $color in $palette-theme {
  .background-#{$color} {
    background: color-light($color);
  }
}
