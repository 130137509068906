@import '~styles';

.container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  padding: 0 $spacer-l;
  min-height: 58px;
}

.icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: center;
  width: 32px;
  margin-right: $spacer-m;
  border-radius: 5px;

  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.position {
  font-size: $font-size-xs;
}

.comment-notification {
  background-color: $color-secondary;
  border-radius: 50px;
  height: 16px;
  width: 16px;
  margin: -$spacer-m 0 0 (-$spacer-m);
  border: $border-thick-light;
  z-index: $z-layer-neutral;
}

.title {
  @include textOverflow();
  @include textNormalDark();

  flex-grow: 1;
  padding: $spacer-s $spacer-xs;
  text-align: left;
}

.stats {
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.action {
  flex: 0 0 auto;
  margin: 0 $spacer-xs;
}

@each $color in $palette-theme {
  .background-#{$color} {
    background: color-light($color);
  }
}
