@import '~styles';

.enter,
.enter::backdrop {
  opacity: 0;
}

.enter-active,
.enter-active::backdrop {
  opacity: 1;
  transition: opacity 250ms linear;
}

.exit,
.exit::backdrop {
  opacity: 1;
}

.exit-active,
.exit-active::backdrop {
  opacity: 0;
  transition: opacity 250ms linear;
}

.modal {
  @include scrollbarPrimary();
  max-width: 98%;
  max-height: 98%;
  margin: auto;
  padding: $spacer-2xl;
  border-radius: 30px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  text-align: center;
  border: none;
  transition: opacity 250ms linear;

  @include responsiveSmall() {
    padding: $spacer-m;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.75);
  }

  &.size-sm {
    width: 500px;
  }

  &.size-md {
    width: 700px;
  }

  &.size-lg {
    width: 850px;
  }

  &.size-xl {
    width: 960px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  gap: $spacer-2xs;
  align-items: center;
  margin-bottom: $spacer-l;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;

  &.centered {
    flex-grow: 1;
    margin-left: 44px;
  }
}

.image {
  display: block;
  width: 175px;
  margin: 0 auto 38px;

  &.small {
    width: 90px;
  }
}

.subtitle {
  @include textNormalDark();

  margin-bottom: $spacer-2xl;
}

.error {
  @include textError();

  margin-bottom: $spacer-l;
}

.action {
  display: flex;
  justify-content: center;
  gap: $spacer-m;
  margin-top: $spacer-l;

  @include responsiveSmall() {
    flex-direction: column;
    gap: $spacer-xs;
  }
}
