@import '~styles';

.warning-container {
  margin: -$spacer-m 0 $spacer-m 0;
  display: flex;
  flex-direction: column;
  gap: $spacer-xs;
}

.warning {
  padding: $spacer-xs $spacer-s;
  border-radius: 15px;
  background-color: color(yellow-50);
  color: color(yellow-800);
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.email {
  margin-top: $spacer-l;
}

.qrcode {
  margin: $spacer-m auto;
}


