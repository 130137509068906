@import '~styles';

.container {
  display: flex;
  align-items: center;
}

.form {
  align-items: center;
  display: flex;
  width: 100%;
}

.field {
  margin-right: $spacer-xs;
  width: 20rem;
  max-width: 100%;
}

.title-container {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  text-align: left;
  word-break: break-all;
}

.edit-button {
  border: none;
}
