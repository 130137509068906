@import '~styles';

.container {
  width: 45%;
  margin: auto;

  @include responsiveMedium() {
    width: 80%;
  }

  @include responsiveSmall() {
    width: 95%;
  }
}

.header {
  border-radius: 30px;
  box-shadow: $shadow-purple;
  background: $color-light;
  min-height: 230px;
  width: 100%;
  margin: $spacer-2xl auto 40px auto;
  padding: 45px $spacer-l;
  display: flex;
  align-items: center;
}

.image-container {
  width: 160px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: $color-primary-light;
  margin-right: $spacer-2xl;
  overflow: hidden;
  position: relative;

  @include responsiveSmall() {
    display: none;
  }
}

.image-background {
  width: 160px;
  height: 160px;
  transform: scale(1.35);
  position: absolute;
  background-color: $color-primary;
  opacity: 0.3;
  -webkit-mask: url(./images/study-background.svg) no-repeat center;
  mask: url(./images/study-background.svg) no-repeat center;
}

.image {
  width: 160px;
  height: 160px;
  border-radius: 0;
  position: absolute;
}

.image-line {
  width: 160px;
  height: 160px;
  transform: scale(1.35);
  position: absolute;
  background-color: $color-primary;
  -webkit-mask: url(./images/study-line.svg) no-repeat center;
  mask: url(./images/study-line.svg) no-repeat center;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.welcome-in {
  font-size: $font-size-3xs;
  font-weight: $font-weight-bolder;
  color: $color-primary;
  letter-spacing: 0.063rem;
  margin-bottom: $spacer-xs;
}

.title {
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
  color: $color-text;
  margin-bottom: $spacer-s;
}

.study-by-chapter {
  font-weight: $font-weight-bolder;
  color: $color-text;
  font-size: $font-size-m;
  margin-bottom: $spacer-s;
}

.chapter-card {
  margin-bottom: $spacer-xs;
  padding: $spacer-xs $spacer-2xl;
  background-color: $color-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: $shadow-purple;
  border-radius: 10px;
}

.chapter-title {
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  color: $color-text;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .header {
      background: color-pastel($color);
    }

    .welcome-in {
      color: $color-text;
    }

    .image-container {
      background-color: $color-light;
    }

    .image-background {
      background-color: color-pastel($color);
    }

    .image-line {
      background-color: color-dark($color);
    }

    .action {
      background: $color-light;
      color: $color-text;
    }
  }
}
