@import '~styles';

.container {
  border-radius: 25px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  padding: 0 $spacer-l;
}

.isSectionOpen {
  padding: 0 $spacer-l $spacer-l $spacer-l;
}

.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: $spacer-l 0;

  &:focus,
  &:hover {
    outline: none;
  }
}

.title-container {
  display: flex;
  align-items: center;
}

.title-icon {
  margin-right: $spacer-xs;
  height: 40px;
  width: 40px;
  line-height: 2.5rem;
  text-align: center;
  font-weight: $font-weight-bolder;
  border-radius: 7px;
  background: $color-pastel-yellow;
  color: $color-dark-yellow-2;
  font-size: $font-size-s;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.count {
  padding: $spacer-3xs $spacer-xs;
  margin-left: $spacer-xs;
  border-radius: 15px;
  background-color: $color-light-yellow;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.toggle-button {
  width: 40px;
  height: 40px;
  line-height: 2.188rem;
  text-align: center;
  border-radius: 30px;
  background-color: $color-light-black;

  &:hover {
    background-color: $color-light-gray;
  }
}

.content {
  @include scrollbarPrimary();

  border-radius: 25px;
  background-color: $color-light;
  border: $border-black-light;
  padding: 0 40px 40px 40px;
  overflow: auto;
  max-height: 600px;
}

.table {
  border-bottom: 1px solid $color-blue-light;
  min-width: 100%;
  margin-bottom: $spacer-s;

  th {
    padding: $spacer-3xs $spacer-s $spacer-xs;
    white-space: nowrap;
    vertical-align: top;
    border-bottom: 1px solid $color-blue-light;
  }

  td {
    padding: $spacer-3xs $spacer-s;
  }
}

.header {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  line-height: 1.875rem;
}

.tooltip {
  margin-left: $spacer-xs;
  margin-right: $spacer-3xs;
  color: $color-text-light;
}

.data {
  @include textNormalLight();

  line-height: 1.875rem;
}
