@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.choice {
  display: flex;
  align-items: flex-start;
  gap: $spacer-2xs;
  margin-bottom: $spacer-m;
}

.choice-title {
  flex-grow: 1;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  color: $color-text;
  font-weight: $font-weight-medium;
}

.isCorrect {
  .choice-title {
    background: $color-light-green-2;
    border: $border-green-pastel;
  }
}

.isWrong {
  .choice-title {
    background: $color-light-red-2;
    border: $border-red-dark;
  }
}

.choice-feedback {
  margin-top: -$spacer-s !important;
  margin-bottom: $spacer-l;
}

.checkbox {
  text-align: left;
}

.buttons {
  display: flex;
  align-items: center;
  gap: $spacer-2xs;
}

.button {
  height: 65px;
  width: 65px;
  background: $color-light;
  color: $color-text;
  font-size: $font-size-m;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;

  &.isCorrect {
    border: $border-green-pastel;
    background: $color-light-green-2;
  }

  &.isWrong {
    border: $border-red-dark;
  }

  &.isChecked {
    background: $background-gradient-primary;
    color: $color-light;
    border-width: 3px;
  }
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .button {
      &.isChecked {
        @include buttonTheme($color);

        &:hover,
        &:focus {
          @include buttonTheme($color);
        }
      }
    }
  }
}
