@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
  border: $border-transparent;
  border-radius: 10px;
  z-index: $z-layer-neutral;

  &:focus-visible {
    border: $border-dark;
  }

  &:focus-within {
    z-index: $z-layer-low-level;
  }
}

.title {
  display: flex;
  justify-content: flex-start;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  margin-right: $spacer-xs;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.field {
  background: $color-light;
  border: $border-gray-light;
  border-radius: 22px;

  &:focus-within {
    border-color: $color-text;
    border-radius: 22px;
  }
}

.field :global(.fr-box.fr-basic .fr-wrapper) {
  background: none;
  border: none;
  cursor: text;
}

.field :global(.fr-box.fr-basic .fr-element) {
  padding: $spacer-xs $spacer-s;
  min-height: 0;
  font-weight: $font-weight-medium;
  color: $color-text !important;
  font-family: inherit;
}

.field :global(.fr-toolbar.fr-top),
.field :global(.fr-toolbar.fr-bottom) {
  position: absolute;
  bottom: calc(100% + 10px);
  left: -1px;
  right: -1px;
  top: unset;
  background: $color-light;
  border-radius: 10px;
  border: $border-thin-dark;
  z-index: $z-layer-mid-level;
}

.field :global(.fr-toolbar .fr-newline) {
  background: transparent;
}

.cefield {
  @include textBoldDark();

  display: block;
  width: 100%;
  padding: $spacer-xs $spacer-l;
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;
  cursor: text;

  &:focus,
  &:focus-within {
    border-color: $color-text;
  }

  &::placeholder {
    color: color(gray-700);
  }
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.hasError .field {
  border-color: $color-wrong;
}

:global(.fr-toolbar .fr-command.fr-btn svg path) {
  fill: $color-text;
}

:global(.fr-toolbar .fr-command.fr-btn) {
  color: $color-text;
}

:global(.fr-toolbar .fr-command.fr-btn.fr-dropdown::after) {
  border-top: 4px solid $color-text;
}

:global(.fr-dropdown-menu) {
  bottom: -145px !important;
  transition: none !important;
}

:global(.fr-expanded) {
  border-radius: 20px 20px 0 0 !important;
}

:global(.fr-popup.fr-desktop.fr-active) {
  z-index: $z-layer-tooltip !important;
}
