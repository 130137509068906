@import './palette.scss';

$border-dark: 2px solid $color-text;
$border-dark-light: 2px solid $color-text-light;
$border-light: 2px solid $color-light;
$border-light-transparent: 2px solid rgba(255, 255, 255, 0.5);
$border-primary: 2px solid $color-primary;
$border-blue-light: 2px solid $color-blue-light;
$border-gray-light: 2px solid $color-light-gray;
$border-black-light: 2px solid $color-light-black;
$border-green-pastel: 2px solid $color-pastel-green;
$border-red-dark: 2px solid $color-dark-red-2;
$border-transparent: 2px solid transparent;

$border-thin-gray-light: 1px solid $color-light-gray;
$border-thin-dark: 1px solid $color-text;

$border-thick-light: 3px solid $color-light;
$border-thick-dark: 3px solid $color-text;
$border-thick-green-pastel: 3px solid $color-pastel-green;
$border-thick-red-dark: 3px solid $color-dark-red-2;
$border-thick-gray-light: 3px solid $color-light-gray;
$border-thick-black-light: 3px solid $color-light-black;

$border-thicker-light: 7px solid $color-light;

$border-dashed-dark: 2px dashed $color-text;
$border-dashed-black-light: 2px dashed $color-light-black;
