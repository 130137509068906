@import '../../Styles/OnboardingSubPage.module.scss';

.container {
  height: 100%;
  padding: $spacer-3xl $spacer-m $spacer-m $spacer-m;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include responsiveSmall() {
    padding: $spacer-2xl;
    background-image: none;
  }
}

.step1 {
  background-color: $color-pastel-turquoise;
  background-image: url('./images/bg_step1.svg');

  .point:nth-child(1) {
    background: $color-text;
    width: 30px;
  }
}

.step2 {
  background-color: $color-pastel-pink;
  background-image: url('./images/bg_step2.svg');

  .point:nth-child(2) {
    background: $color-text;
    width: 30px;
  }
}

.step3 {
  background-color: $color-pastel-yellow;
  background-image: url('./images/bg_step3.svg');

  .point:nth-child(3) {
    background: $color-text;
    width: 30px;
  }
}

.points {
  display: flex;
  justify-content: center;
}

.point {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background: $color-light-purple-3;
  margin-left: $spacer-2xs;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;

  @include responsiveSmall() {
    height: 100%;
    display: grid;
    grid-template-rows: 50% 50%;
    justify-content: stretch;
    margin: 0 auto 0 auto;
  }
}

.picture {
  height: 250px;
  width: 100%;

  @include responsiveSmall() {
    height: auto;
    max-height: 250px;
    align-self: end;
    margin: 0 auto;
  }
}

.text {
  margin: 0 auto;
  align-self: start;

  @include responsiveSmall() {
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    width: 100%;
  }
}

.title {
  font-weight: $font-weight-bolder;
  font-size: $font-size-xl;
  margin: $spacer-2xl auto 0 auto;
  width: 340px;

  @include responsiveSmall() {
    width: 320px;
  }
}

.subtitle {
  font-weight: $font-weight-medium;
  margin: $spacer-xs 0 50px 0;
  margin: auto;

  @include responsiveSmall() {
    width: 320px;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: $spacer-xs;
  margin: $spacer-m 0;

  @include responsiveSmall() {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.action {
  width: 100%;
  box-shadow: none;
}
