@import '~styles';

.item-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.link {
  display: flex;
  gap: $spacer-m;
  align-items: center;
  border: $border-light;
  border-radius: 10px;
  flex-grow: 1;
  padding: $spacer-m $spacer-2xl;
  background-color: $color-light;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin 200ms ease-in-out, padding 200ms ease-in-out;
  width: 100%;

  @include responsiveSmall() {
    padding: $spacer-s $spacer-m $spacer-s $spacer-2xl;
  }

  &:hover,
  &:focus {
    width: 101%;
    padding: $spacer-m calc(30px + 0.5%);
    margin: 0 -0.5%;
    box-shadow: $shadow-light-hover;
    text-decoration: $decoration-none;

    @include responsiveSmall() {
      padding: $spacer-s calc(20px + 0.5%) $spacer-s calc(30px + 0.5%);
    }
  }

  &:focus-visible {
    border-color: $color-text;
  }
}

.shadow {
  box-shadow: $shadow-blue;
}

.sort {
  color: $color-text-light;
  border: none;
  background: none;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
  }
}

.pin {
  background: none;
  border: none;
  color: $color-secondary;

  @include responsiveSmall() {
    left: $spacer-m;
    top: $spacer-s;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    color: $color-text;
  }
}

.icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: gradient-light('purple');
  color: color-dark('purple');

  @include responsiveSmall() {
    display: none;
  }
}

.title {
  @include textOverflow();
  @include textNormalDark();

  flex-grow: 1;
  margin-right: $spacer-xs;
}

.owner,
.created-at {
  @include textOverflow();

  flex-shrink: 0;
  color: $color-text-light;
  font-size: $font-size-xs;
  max-width: 150px;
  margin: 0 $spacer-xs;

  @include responsiveMedium() {
    display: none;
  }
}

.created-at {
  width: 120px;
}

.participants {
  flex-shrink: 0;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bolder;
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    display: none;
  }
}

.participants-icon {
  margin-right: $spacer-xs;
  color: $color-dark-purple;
}

.tooltip {
  margin-right: $spacer-m;
  color: $color-text-light;

  @include responsiveMedium() {
    display: none;
  }
}

.menu {
  border: none;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .icon {
      background: gradient-light($color);
      color: color-dark($color);
    }
  }
}
