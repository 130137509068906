@import '~styles';

.cover {
  margin-bottom: $spacer-2xl;

  @include responsiveSmall() {
    margin-bottom: $spacer-m;
  }
}

.course-progress {
  margin-bottom: 46px;

  @include responsiveSmall() {
    margin-bottom: $spacer-m;
  }
}

.chapters {
  margin-bottom: 46px;

  @include responsiveSmall() {
    margin-bottom: $spacer-m;
  }
}
