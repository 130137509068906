@import '~styles';

.tab-list {
  border-bottom: 2px solid $color-light-gray;
  display: flex;
}

.tab-list-item {
  display: inline-block;
  padding: $spacer-m;
  background: none;
  border: none;
  color: $color-text-light;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  border-bottom: 3px solid transparent;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
    text-decoration: $decoration-none;
  }

  &.isActive {
    color: $color-text;
    border-color: color(blue-300);
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }
}
