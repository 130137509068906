@import '~styles';

.preview-container {
  border-radius: 15px;
  background: $color-light;
  box-shadow: $shadow-dark-blue;
  padding: $spacer-s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preview {
  margin: auto;
  width: 100%;
}

.video-container {
  border: none;
  background: transparent;
  position: relative;

  &:hover,
  &:focus {
    outline: none;
  }
}

.iframe {
  border-radius: 15px;
  width: 100vw;
  max-width: 100%;
  height: 300px;
}

.video {
  max-width: 100%;
  min-width: 50%;
  max-height: 600px;
  border-radius: 15px;
  display: inline-block;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  i {
    font-size: $font-size-l;
    margin: $spacer-3xs 0 0 $spacer-3xs;
  }
}

.footer {
  margin-top: $spacer-xs;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@each $color in $palette-theme{
  .theme-#{$color} {
    .play {
      @include buttonTheme($color);
    }
  }
}
