@import '~components/Shared/Styles/Auth.module.scss';

.container {
  @include styledAuth();
}

.section {
  @include responsiveSmall() {
    @include styledSection();
  }
}

.title {
  margin-bottom: $spacer-3xs !important;
}

.forgot-password {
  text-align: right;
  margin: $spacer-3xs 0;
}

.or {
  margin: $spacer-m 0 $spacer-l 0 !important;
}

.or span {
  background: $color-background !important;

  @include responsiveSmall() {
    background-color: transparent !important;
  }
}
