@import '~styles';

.container {
  align-items: center;
  display: flex;
  margin: auto;
  width: 550px;
  max-width: 100%;
}

.icon {
  height: 64px;
  width: 64px;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-l;
  margin-right: $spacer-s;
  background: $color-light-black;
  color: $color-dark-black;
  flex:0 0 auto;
}

.grow {
  flex: 1 1 auto;
  min-width: 0;
}

.title {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  margin-bottom: $spacer-2xs;
  text-align: left;
}

.container {
  display: flex;
  align-items: center;
}

.field {
  flex-grow: 1;
  margin-right: $spacer-2xs;
  min-width: 0;
}
