@import '~styles';

.button {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 $spacer-l;
  background: $color-light;
  border: none;
  border-radius: 10px;
  box-shadow: $shadow-light;
  color: $color-text;

  @include responsiveSmall() {
    display: flex;
    height: 40px;
    padding: 0 $spacer-xs;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus:focus-visible {
    border: $border-thick-dark;
  }
}
