@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.choice-add {
  margin-left: -$spacer-3xs;
}

.choice-complete {
  flex-grow: 1;
  margin: 0 48px 0 $spacer-xs !important;
}

.choice-feedback {
  margin: 0 0 $spacer-m 43px;
  padding: 0 90px 0 0;
  width: 100%;
}

