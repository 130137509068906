@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.image-legends-container {
  display: flex;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.image {
  width: 50%;
  margin-right: $spacer-m;
  border-radius: 20px;

  @include responsiveMedium() {
    width: 98%;
    margin: auto;
    margin-bottom: $spacer-xs;
  }
}

.answers {
  @include scrollbarPrimary();

  background-color: $color-light;
  border-radius: 20px;
  flex: 1 1 auto;
  height: fit-content;
  max-height: 500px;
  padding: $spacer-l;
}

.answer {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacer-xs;
}

.answer-index {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: $color-light-red-2;
  border: $border-red-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bolder;
  color: $color-text;
  flex: 0 0 auto;

  &.isCorrect {
    background: $color-light-green-2;
    border: $border-green-pastel;
  }
}

.corrections {
  flex-grow: 1;
  margin-left: $spacer-m;
}

.correction-wrong-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.correction {
  background: $color-light-red-2;
  border: $border-red-dark;
  border-radius: 40px;
  padding: $spacer-2xs $spacer-m;
  width: 100%;
  color: $color-text;
  font-weight: $font-weight-medium;
}

.correct {
  background: $color-light-green-2;
  border: $border-green-pastel;
}

.icon {
  flex: 0 0 auto;
  font-size: $font-size-3xs;
  width: 1.5rem;
}
