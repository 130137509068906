@import '~styles';

.button {
  @include responsiveSmall() {
    width: 100%;
  }
}

.split-button-container {
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
  }
}

.split-button {
  border-radius: 30px;
  padding-right: $spacer-3xl;

  @include responsiveSmall() {
    margin-bottom: 0 !important;
  }
}

.save-in-chapter-button {
  border-radius: 0 30px 30px 0;
  margin-left: -50px;
  color: $color-light;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    background-color: $color-primary;
    color: $color-light;
  }
}

.save-in-chapter-title {
  font-weight: $font-weight-bolder;
  min-width: 200px;
  font-size: $font-size-xs;
  text-align: center;
  color: $color-text-light;
  padding: $spacer-xs 0 $spacer-xs $spacer-m;
  border-bottom: 2px solid $color-light-gray;
  cursor: default;
}
