@import '~styles';

.container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out, margin-left 200ms ease-in-out;
  padding: $spacer-s $spacer-l;
  border: $border-transparent;
  width: 100%;
  margin-left: 0;

  &:hover,
  &:focus {
    width: 101%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: $spacer-m;
  border-radius: 5px;
  background-color: $color-light-black;
  background-size: cover;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bolder;
}

.title {
  @include textNormalDark();

  flex-grow: 1;
  min-width: 300px;

  @include responsiveMedium() {
    min-width: 0;
  }
}

.action {
  flex: 0 0 auto;
  margin-left: $spacer-xs;
}
