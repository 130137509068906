@import '~styles';

.zone {
  position: absolute;
  touch-action: none;
  user-select: none;
  cursor: grab;
  outline: none;
  border: $border-light;
  background-color: rgba(125, 226, 24, 0.4);

  &.isActive {
    background-color: rgba(161, 236, 85, 0.4);
  }
}

.move-button {
  background: transparent;
  border: none;

  &:hover,
  &:focus {
    outline: none;
  }
}

.resize,
.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: $color-light;
  border: $border-light;
  border-radius: 50%;
  font-size: $font-size-3xs;

  &:hover,
  &:focus {
    outline: none;
  }

  @include responsiveSmall() {
    width: 30px;
    height: 30px;
  }
}

.resize {
  bottom: -$spacer-xs;
  cursor: nwse-resize;

  @include responsiveSmall() {
    bottom: -$spacer-s;
  }
}

.delete {
  top: -$spacer-xs;
  cursor: pointer;
  color: color(red-400);

  @include responsiveSmall() {
    top: -$spacer-s;
  }
}

.shape-rectangle {
  border-radius: 10px;

  .delete,
  .resize {
    right: -$spacer-xs;

    @include responsiveSmall() {
      right: -$spacer-s;
    }
  }
}

.shape-circle {
  border-radius: 50%;

  .delete,
  .resize {
    left: 50%;
    transform: translateX(-10px);

    @include responsiveSmall() {
      transform: translateX(-15px);
    }
  }
}
