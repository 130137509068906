@import '~styles';

.container {
  font-family: inherit;
  width: 100%;

  img {
    cursor: zoom-in !important;
    max-height: auto !important;
    max-width: 100% !important;
    display: block;
    margin: auto;
  }

  video {
    cursor: pointer;
    max-height: auto !important;
    max-width: 100% !important;
    display: block;
    margin: auto;
  }

  audio {
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
    min-height: 300px;
    border: none;
    border-radius: 20px;
  }

  :global(.fr-img-caption),
  :global(.fr-inner) {
    display: block;
    text-align: center;
    width: auto !important;
  }

  pre,
  code {
    text-align: left !important;
    text-wrap: wrap;
  }

  a {
    text-decoration: $decoration-underline;
  }

  :global(.pp-slide) {
    text-align: center;

    img {
      width: 1200px;
      max-width: 100% !important;
    }
  }

  strong {
    font-weight: $font-weight-bolder;
  }

  h1:focus {
    outline: none;
  }
}
