@import '~styles';

.container {
  height: fit-content;
  width: 300px;

  @include responsiveMedium() {
    width: 100%;
  }

  &.column {
    @include responsiveLarge() {
      width: 100%;
    }
  }
}

.title {
  @include textBolderDark();

  text-align: left;
  margin-bottom: $spacer-m;
}

.action {
  width: 100%;
  border-radius: 10px;
  margin-bottom: $spacer-xs;
}

.formula-input-container {
  display: flex;
}

.input {
  padding: $spacer-2xs $spacer-xs;
  border-radius: 10px;
  border: $border-gray-light;
  width: 100%;
  flex-grow: 1;

  &.margin {
    margin-bottom: $spacer-xs;
  }
}

.formula-button {
  width: fit-content;
  padding: $spacer-xs;
  margin-left: $spacer-xs;
  border-radius: 10px;
}

.formula-preview {
  display: none;
}

.visible {
  display: block;
}
