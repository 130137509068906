@import '~styles';

.container {
  padding-top: $spacer-2xl;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-s;

  @include responsiveSmall() {
    flex-wrap: wrap;
    margin-bottom: $spacer-xs;
  }
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.badge {
  padding: $spacer-3xs $spacer-xs;
  margin-left: $spacer-xs;
  border-radius: 15px;
  background-color: color(yellow-50);
  color: color(yellow-800);
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.error {
  margin-bottom: $spacer-s;
}

.choices {
  margin-bottom: $spacer-l;
}

.content {
  background-color: $color-light;
  border-radius: 15px;
  padding: $spacer-l;
  margin-bottom: $spacer-l;
  border-bottom: 1px solid $color-blue-light;
}

.section-title {
  @include textBolderDark();

  margin-bottom: $spacer-xs;
}

.field {
  margin-bottom: $spacer-s;
}

.questions-by-exam {
  display: flex;
  margin-top: $spacer-xs;
  align-items: center;
}

.questions-by-exam-label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  margin-left: $spacer-xs;
  text-align: left;
}
