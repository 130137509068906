@import '~styles';

.dashboard-title {
  font-weight: $font-weight-bolder;
  color: $color-text;
  font-size: $font-size-l;
  padding: $spacer-xs 0 0 $spacer-2xl;

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
    color: $color-light;
    font-size: $font-size-l;
    padding: 0;
  }
}

.search {
  display: flex;
  align-items: center;
  margin: $spacer-s $spacer-xs 0 $spacer-2xl;
  border: $border-blue-light;
  border-radius: 22.5px;
  background: $color-light;
  color: $color-text;
  width: 400px;
  padding: 0 $spacer-xs;

  @include responsiveMedium() {
    margin: $spacer-s $spacer-xs 0 $spacer-s;
  }

  @include responsiveSmall() {
    width: 100%;
    margin: $spacer-s 0 $spacer-xs 0;
  }

  &:focus-within {
    border-color: $color-text;
  }

  .search-input {
    flex-grow: 1;
    padding: $spacer-xs 0 $spacer-xs $spacer-xs;
    color: $color-text;
    height: 40px;
    font-size: $font-size-2xs;
    font-weight: $font-weight-bold;
    border: none;
    background: none;
  }
}

.dashboard-hello {
  @include textOverflow();

  color: $color-light;
  text-align: center;

  span {
    font-weight: $font-weight-bolder;
    margin-left: $spacer-3xs;
  }
}

.title,
.section-title {
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  color: $color-text;
  margin-bottom: $spacer-xs;
}

.section-title-container {
  display: flex;
  justify-content: left;

  @include responsiveSmall() {
    margin-top: $spacer-m;
  }
}

.section-title-container-small {
  @include responsiveSmall() {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.section-title {
  font-weight: $font-weight-bolder;
}

.number {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  font-weight: $font-weight-bolder;
  font-size: $font-size-2xs;
  margin-left: $spacer-xs;
  color: $color-primary;
  background-color: $color-primary-light;
  text-align: center;
  line-height: 1.5rem;
}

.quick {
  margin-bottom: $spacer-2xl;
}

.filter-item {
  display: block;
  width: 100%;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  padding: $spacer-s $spacer-l;
  background: none;
  border: none;
  color: $color-text-light;
  transition: color ease-in-out 250ms;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
    text-decoration: $decoration-none;
  }

  &.isActive {
    color: $color-text;
  }
}

.favorites-placeholder {
  height: 250px;
  width: 100%;
  position: relative;
  margin-bottom: -$spacer-2xl;
}

.favorites-container {
  width: 100%;
  height: 280px;
  position: absolute;
  bottom: $spacer-s;
  overflow: hidden;

  &:hover {
    @include scrollbarPrimary();
    overflow: auto;
  }
}

.favorites {
  position: absolute;
  bottom: $spacer-s;
  grid-gap: $spacer-s;
  display: flex;
}

.courses.style-grid {
  display: flex;
  grid-gap: $spacer-s;
  flex-wrap: wrap;
}
