@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.label {
  font-size: $font-size-2xs;
  font-weight: $font-weight-medium;
  color: $color-text;
  margin: $spacer-m 0 $spacer-xs 0;
}

.background-options {
  display: flex;
  flex-wrap: wrap;
}

.error {
  @include textError();

  margin-bottom: $spacer-xs;
}

.check {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.checkbox {
  height: 45px;
  width: 45px;
  margin-right: $spacer-xs;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-light;
  border: none;
  border-radius: 50px;
  cursor: pointer;

  &.isChecked {
    border: $border-thick-dark;
  }
}

@each $color in $palette-background {
  .background-#{$color} {
    background: gradient-dark($color) !important;
  }
}

.background-white {
  background: $color-light;
  border: $border-thick-gray-light;
}

.background-image-picker {
  border: $border-thick-gray-light;
}
