@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.answer-container {
  font-weight: $font-weight-medium;
}

.answer {
  width: 100%;
  border-radius: 15px;
  background-color: $color-light;
  padding: $spacer-m $spacer-2xl;
  margin-bottom: $spacer-xs;
}

.correct {
  background-color: $color-light-green-2;
  border: $border-thick-green-pastel;
  margin-top: 50px;
}

.wrong {
  background-color: $color-light-red-2;
  border: $border-thick-red-dark;
  margin-bottom: 40px;
}
