@import '~styles';
@import '~components/Shared/Styles/StyledSubPage.module.scss';

.subpage {
  @include styledSubPage();
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-2xl;
}

.preview {
  padding: $spacer-s $spacer-3xl;
  max-width: 960px;
  margin: auto;

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}

.explanation-preview {
  color: $color-dark-orange;
  font-size: $font-size-xs;
  font-weight: bold;
  border-radius: 10px;
  background-color: $color-light-orange;
  margin-bottom: 43px;
  padding: $spacer-xs 37px;

  @include responsiveSmall() {
    margin-bottom: $spacer-s;
  }
}

.no-wrong-answer {
  color: $color-dark-purple;
  font-size: $font-size-xs;
  font-weight: bold;
  border-radius: 10px;
  background-color: $color-light-purple;
  margin-bottom: 43px;
  padding: $spacer-xs 37px;

  @include responsiveSmall() {
    margin-bottom: $spacer-s;
  }
}
