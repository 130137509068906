@import '~styles';

.choices {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.choice {
  position: relative;
  width: 32%;
  padding: 42px $spacer-m;
  border: $border-gray-light;
  background: color(light);
  border-radius: 15px;
  box-shadow: $shadow-light;
  transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out;
  display: flex;
  flex-direction: column;

  &:hover,
  &:focus {
    transform: translate(0, -5px);
    box-shadow: $shadow-dark;
    text-decoration: $decoration-none;
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }

  @include responsiveSmall() {
    width: 100%;
    margin-bottom: $spacer-xs;
    min-height: 150px;
    padding: $spacer-s;
    align-items: center;
    text-align: left;
    flex-direction: row;
  }

  &.isInSettings.isActive {
    border-color: $color-primary;
  }

  &.isInSettings.isInactive {
    opacity: 0.5;
  }
}

.recommended {
  position: absolute;
  top: $spacer-3xs;
  right: $spacer-3xs;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: $spacer-3xs $spacer-2xs;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  border-radius: 5px;
  background: $color-secondary;
}

.picture-container {
  width: 120px;
  height: 80px;
  border-radius: 15px;
  position: relative;
  margin: 0 auto $spacer-s auto;

  @include responsiveSmall() {
    margin: 0 $spacer-m 0 0;
  }
}

.picture {
  background-color: $color-light-purple-2;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  object-fit: contain;

  @include responsiveSmall() {
    width: 120px;
  }
}

.choice-title {
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-m;
  color: $color-text;
  line-height: 1.563rem;
  padding: 0 $spacer-xs;

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
    padding: 0;
  }
}

.choice-subtitle {
  @include textMediumLight();
  @include responsiveSmall() {
    min-height: 0;
  }
}

.isSmall {
  .linear,
  .adaptive {
    width: 49%;
    margin-bottom: $spacer-xs;

    @include responsiveSmall() {
      width: 100%;
    }
  }

  .exam {
    align-self: flex-start;
    width: 100%;
    padding: $spacer-m;

    @include responsiveSmall() {
      min-height: 0;
      padding: $spacer-xs;
    }

    .picture-container {
      display: none;
    }

    .description {
      display: flex;
    }

    .choice-title {
      line-height: 1rem;
      font-size: $font-size-s;
      padding: 0;
      margin: 0 $spacer-xs 0 0;

      @include responsiveSmall() {
        margin: 0 $spacer-xs 0 0;
      }
    }

    .choice-subtitle {
      line-height: 1rem;
      font-size: $font-size-xs;
    }
  }
}
