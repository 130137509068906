@import '~styles';

.header {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
  top: 0;
  padding: $spacer-2xl 0 $spacer-m 0;
  background-color: $color-background;
  z-index: $z-layer-low-level;

  &:global(.sticky-header) {
    position: sticky;
  }
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  flex-grow: 1;
}

.count {
  padding: $spacer-3xs $spacer-xs;
  margin-left: $spacer-xs;
  border-radius: 15px;
  background-color: $color-primary-light;
  color: $color-dark-purple;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.warning {
  display: flex;
  padding: $spacer-xs $spacer-s;
  border-radius: 15px;
  background-color: color(yellow-50);
  color: color(yellow-800);
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-2xl;

  &:hover,
  &:focus {
    background-color: color(yellow-50);
    color: color(yellow-800);
    text-decoration: $decoration-none;
  }
}
