@import '~styles';

.container {
  border: $border-light;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  transition: border-color 250ms ease-in-out;
}

.group {
  align-items: center;
  display: flex;
  padding: 0 $spacer-l;
}

.icon {
  @include textGradient($color-dark-purple, color(blue-300));

  align-items: center;
  border: none;
  display: flex;
  flex: 0 0 auto;
  font-size: $font-size-xs;
  height: 30px;
  justify-content: center;
  transition: color 250ms ease-in-out;
  width: 30px;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 50%;
  }
}

.title {
  @include textOverflow();
  @include textBolderDark();

  flex-grow: 1;
  padding: $spacer-s $spacer-xs $spacer-s $spacer-l;
  text-align: left;

  &:hover,
  &:focus {
    outline: none;
  }
}

.students {
  border-radius: 10px;
  min-height: 3rem;
  transition: background 250ms ease-in-out;
  margin: $spacer-3xs $spacer-xs;
}

.student {
  margin-bottom: $spacer-2xs;
}
