@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.good-answers {
  text-align: center;
  margin-bottom: -$spacer-3xs;
  color: $color-success;
  font-weight: $font-weight-bold;
}

.match {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $spacer-xs;
}

.match-source,
.match-destination {
  color: $color-text-light;
}

.match-source {
  text-align: right;
}

.match-index {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: $border-light;
  background: $background-gradient-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bolder;
  font-size: $font-size-2xs;
  color: $color-light;
  flex: 0 0 auto;
  box-shadow: $shadow-purple;
}

.source-index {
  margin-left: $spacer-m;
}

.destination-index {
  margin-right: $spacer-m;
}

.result {
  width: 100%;
}

.gauge {
  position: relative;
  height: 5px;
  width: 100%;
  background-color: $color-blue-light;
  background: $color-light-gray;

  & > .progression {
    height: 5px;
    background: $color-success;
  }

  &.success > .progression {
    background: $background-gradient-green;
  }

  & > .animation {
    animation-name: percentage;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0, 1.1, 0.55, 1.01);
    height: 5px;
    background: $color-light-gray;
    position: absolute;
    right: 0;
    top: 0;
  }

  @keyframes percentage {
    0% {
      width: 100%;
    }
    10% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }

  .percentage {
    position: absolute;
    top: 1px;
    left: calc(50% - 25px);
    background: $color-light;
    font-size: $font-size-2xs;
    border-radius: 10px;
    width: 50px;
    text-align: center;
    color: $color-primary;
    font-weight: $font-weight-bold;
  }

  &.success > .percentage {
    color: $color-success;
  }
}
