@import '~styles';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: $spacer-m;
  overflow: hidden;
}

.image {
  user-select: none;
  cursor: 'crosshair';
  width: 100%;
  height: auto;
  border-radius: 20px;
}
