@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.answers-title {
  font-weight: $font-weight-bold;
  font-size: $font-size-m;
  margin-bottom: $spacer-xs;
  text-align: center;
}

.user-answers-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-gap: $spacer-xs;
  margin-bottom: $spacer-m;

  @include responsiveMedium() {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }

  @include responsiveSmall() {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }
}

.answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-light-gray;
  border-radius: 10px;
  padding: $spacer-s;
}

.answer-title {
  color: $color-text-light;
}

.occurrence {
  display: flex;
  align-items: center;
  padding: 0 $spacer-xs;
  color: $color-text-light;
  background: $color-light;
  border-radius: 20px;
}

.occurrence-number {
  margin-right: $spacer-3xs;
  color: $color-text-light;
  font-weight: $font-weight-bold;
}

.occurrence-icon {
  color: $color-text-light;
}

.good-answer-container {
  background-color: $color-success;
  border-radius: 15px;
  text-align: center;
  padding: $spacer-xs;
}

.good-answer-title {
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-light;
  margin-bottom: $spacer-xs;
}

.good-answer {
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-text-light;
  width: 100%;
  background-color: $color-light;
  border-radius: 10px;
  padding: $spacer-xs;
}
