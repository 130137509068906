@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.drag {
  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }
}

.item-add {
  margin-left: -$spacer-xs;
}

.item-complete {
  flex-grow: 1;
  margin: 0 48px 0 (-$spacer-2xs) !important;
}
