@import '~components/Shared/Styles/StyledModal.module.scss';

.modal {
  @include styledModal();
}

.isDisabled {
  opacity: 0.5;
}

.podium {
  margin-top: $spacer-2xl;
}
