$colors: (
  'light': #fff,
  'dark': #212529,
  'background': #f8f9fb,
  //
  'gray-50': #fafafa,
  'gray-100': #f5f5f5,
  'gray-200': #eee,
  'gray-300': #e0e0e0,
  'gray-400': #bdbdbd,
  'gray-500': #9e9e9e,
  'gray-600': #757575,
  'gray-700': #616161,
  'gray-800': #424242,
  'gray-900': #212121,
  //
  'orange-50': #fff3e0,
  'orange-100': #ffe0b2,
  'orange-200': #fecc80,
  'orange-300': #feb74d,
  'orange-400': #fea726,
  'orange-500': #fe9800,
  'orange-600': #fa8c00,
  'orange-700': #f47c00,
  'orange-800': #ee6d00,
  'orange-900': #e55200,
  //
  'yellow-50': #fffce8,
  'yellow-100': #fff6c6,
  'yellow-200': #fff1a1,
  'yellow-300': #feeb7e,
  'yellow-400': #fde662,
  'yellow-500': #fee34d,
  'yellow-600': #fad148,
  'yellow-700': #f6ba41,
  'yellow-800': #f2a33a,
  'yellow-900': #ea7d2f,
  //
  'red-50': #ffe9ee,
  'red-100': #ffc7d0,
  'red-200': #f88f95,
  'red-300': #f05f6b,
  'red-400': #fb2b46,
  'red-500': #fe002a,
  'red-600': #ef002a,
  'red-700': #dd0024,
  'red-800': #d0001d,
  'red-900': #c00010,
  //
  'green-50': #d4eee8,
  'green-100': #adf2d7,
  'green-200': #6be9bd,
  'green-300': #00dfa3,
  'green-400': #00d490,
  'green-500': #00ca7d,
  'green-600': #00ba71,
  'green-700': #00a762,
  'green-800': #009555,
  'green-900': #00743d,
  //
  'blue-50': #e8eafb,
  'blue-100': #c4cbf5,
  'blue-200': #9ca9ef,
  'blue-300': #9f7cff,
  'blue-400': #4d6be4,
  'blue-500': #2851d8,
  'blue-600': #2148cd,
  'blue-700': #133ec0,
  'blue-800': #0533b3,
  'blue-900': #001f9d,
  //
  'purple-50': #eee6fd,
  'purple-100': #d1c1fa,
  'purple-200': #b298f8,
  'purple-300': #906ef0,
  'purple-400': #754fe4,
  'purple-500': #5831d9,
  'purple-600': #4b2cd3,
  'purple-700': #3824ca,
  'purple-800': #211fc2,
  'purple-900': #0013b3,
  //
  'level-0': #bdbdbd,
  'level-1': #ed403c,
  'level-2': #f58225,
  'level-3': #f8a51b,
  'level-4': #cbbe00,
  'level-5': #6fbe44,
);

$shadows: (
  'down': 0 1.25rem 2.5rem -0.75rem rgba(181, 189, 196, 0.5),
  'downXL': 0 1.25rem 2.5rem 0.25rem rgba(181, 189, 196, 0.5),
  'up': 0 -1.25rem 2.5rem -0.75rem rgba(181, 189, 196, 0.5),
);

@function color($key) {
  @return map-get($colors, $key);
}

@function shadow($key) {
  @return map-get($shadows, $key);
}

$color-light: #fff;
$color-background: #f5f6fa;
$color-text: #1d254f;
$color-text-light: #656686;
$color-primary: #906ef0;
$color-primary-light: #f5f1ff;
$color-primary-dark: #4a239d;
$color-secondary: #32e0c4;
$color-blue: #146aff;
$color-blue-light: #f5f1ff;
$color-green: #6bdd6a;
$color-orange: #eb9500;
$color-red: #ed343f;
$color-turquoise: #32cdfb;
$color-pink: #f35e9a;
$color-zircon: #f4f8ff;

$color-correct: #6bdd6a;
$color-correct-background: #d3f1d6;
$color-success: #1bc489;
$color-wrong: #d80e1a;
$color-wrong-light: #f4dfe3;
$color-wrong-background: #fff3f3;
$color-unknown-background: #f5f2ed;
$color-na-background: #d8e4fb;
$color-ia-blue: #4428d4;
$color-ia-pink: #ca6584;

$color-pastel-green: #63d5b0;
$color-pastel-blue: #9bbefd;
$color-pastel-pink: #ffa9d0;
$color-pastel-yellow: #ffd77c;
$color-pastel-purple: #b397f5;
$color-pastel-turquoise: #91e3fa;
$color-pastel-red: #ffa9a9;
$color-pastel-orange: #ffc7a9;
$color-pastel-gray: #e2dbd8;
$color-pastel-black: #cfd0d4;

$colors-pastel: (
  'purple': $color-pastel-purple,
  'blue': $color-pastel-blue,
  'turquoise': $color-pastel-turquoise,
  'red': $color-pastel-red,
  'green': $color-pastel-green,
  'orange': $color-pastel-orange,
  'yellow': $color-pastel-yellow,
  'pink': $color-pastel-pink,
  'gray': $color-pastel-gray,
  'black': $color-pastel-black,
);

@function color-pastel($key) {
  @return map-get($colors-pastel, $key);
}

$color-facebook: #1976d2;
$color-twitter: #00acee;
$color-linkedin: #2867b2;
$color-messenger: #006aff;

$background-gradient-primary: linear-gradient(
  49.71deg,
  #8258f3 0%,
  #c0a8ff 100%
);
$background-gradient-primary-vertical: linear-gradient(
  180deg,
  #9f7cff 0%,
  #8258f3 100%
);
$background-gradient-primary-light: linear-gradient(
  180deg,
  rgba(217, 201, 252, 0.15) 0%,
  rgba(177, 152, 248, 0.15) 100%
);
$background-gradient-primary-hover: linear-gradient(
  49.71deg,
  #5b3cad 0%,
  #9f7cff 100%
);
$background-gradient-primary-dark: linear-gradient(
  9deg,
  #8258f3 0%,
  #1d254f 100%
);
$background-gradient-ia: linear-gradient(
  100deg,
  $color-ia-pink 0%,
  $color-ia-blue 100%
);
$background-gradient-blue: linear-gradient(180deg, #2da3ff 0%, #146aff 100%);
$background-gradient-green: linear-gradient(270deg, #3ae4be 0%, #1bc489 100%);
$background-gradient-orange: linear-gradient(270deg, #ffe282 0%, #dea947 100%);
$background-gradient-yellow: linear-gradient(180deg, #ffd392 0%, #ffb522 100%);
$background-gradient-red: linear-gradient(270deg, #f5438c 0%, #f67aac 100%);
$background-gradient-silver: linear-gradient(0deg, #a8aec5 0%, #dfe3e9 100%);
$background-gradient-bronze: linear-gradient(0deg, #65452e 0%, #91684a 99.96%);

$background-gradient-plan-starter: linear-gradient(
  180deg,
  #1d254f10 0%,
  #3e4c8710 100%
);
$background-gradient-logo-plan-starter: linear-gradient(
  180deg,
  #1d254f10 0%,
  #3e4c8710 100%
);
$background-gradient-plan-pro: linear-gradient(
  180deg,
  #d9c9fc30 0%,
  #b198f830 100%
);
$background-gradient-plan-recommended: linear-gradient(
  180deg,
  #fbf2ff 0%,
  #f7eafe 100%
);
$background-gradient-plan-expert: linear-gradient(
  270deg,
  #ffe28230 0%,
  #dea94730 100%
);

$background-gradient-light-primary: linear-gradient(
  180deg,
  #d9c9fc30 0%,
  #b198f830 100%
);
$background-gradient-light-blue: linear-gradient(
  180deg,
  #146aff20 0%,
  #2da3ff20 100%
);
$background-gradient-light-green: linear-gradient(
  180deg,
  #6bdd6a20 0%,
  #a5f1a520 100%
);
$background-gradient-light-orange: linear-gradient(
  270deg,
  #eb950020 0%,
  #f7c70020 100%
);
$background-gradient-light-red: linear-gradient(
  180deg,
  #f5438c20 0%,
  #f67aac20 100%
);

$palette-background: 'black', 'purple', 'blue', 'turquoise', 'green', 'orange',
  'yellow', 'pink', 'red';

$palette-theme: 'purple', 'blue', 'turquoise', 'yellow', 'pink';

$palette-text: 'black', 'white';

$colors-text: (
  'black': $color-text,
  'white': $color-light,
);

@function color-text($key) {
  @return map-get($colors-text, $key);
}

$color-dark-purple: #8258f3;
$color-dark-purple-2: #3d1e80;
$color-dark-blue: #146aff;
$color-dark-blue-2: #1e4382;
$color-dark-blue-3: #233048;
$color-dark-turquoise: #32cdfb;
$color-dark-turquoise-2: #16657b;
$color-dark-red: #d80e1a;
$color-dark-red-2: #e4165c;
$color-dark-red-3: #801e3f;
$color-dark-green: #6bdd6a;
$color-dark-green-2: #14a975;
$color-dark-green-3: #1b5746;
$color-dark-orange: #eb9500;
$color-dark-yellow: #ffb500;
$color-dark-yellow-2: #6c501a;
$color-dark-pink: #f5438c;
$color-dark-black: #1d254f;
$color-dark-gray: #656686;

$color-light-purple: #efeffd;
$color-light-purple-2: #f9f6fe;
$color-light-purple-3: #f4effe;
$color-light-blue: #dfefff;
$color-light-turquoise: #e3f6f9;
$color-light-red: #f0e3ea;
$color-light-red-2: #fff0f5;
$color-light-green: #ecfbec;
$color-light-green-2: #f0fff7;
$color-light-orange: #fef6d9;
$color-light-yellow: #fffaf0;
$color-light-pink: #fae3ee;
$color-light-black: #f6f7fa;
$color-light-gray: #e7ebf0;

$gradient-dark-purple: linear-gradient(0deg, #8258f3 0%, #c0a8ff 100%);
$gradient-dark-purple-2: linear-gradient(180deg, #8258f3 0%, #3d1e80 100%);
$gradient-dark-blue: linear-gradient(0deg, #146aff 0%, #2da3ff 100%);
$gradient-dark-turquoise: linear-gradient(0deg, #32cdfb 0%, #a6f2ff 100%);
$gradient-dark-red: linear-gradient(0deg, #d80e1a 0%, #fb838c 100%);
$gradient-dark-green: linear-gradient(0deg, #6bdd6a 0%, #a5f1a5 100%);
$gradient-dark-orange: linear-gradient(0deg, #eb9500 0%, #f7c700 100%);
$gradient-dark-yellow: linear-gradient(0deg, #ffb500 0%, #ffd392 100%);
$gradient-dark-pink: linear-gradient(0deg, #f5438c 0%, #f67aac 100%);
$gradient-dark-pink-red: linear-gradient(0deg, #e4165c 0%, #f35e9a 100%);
$gradient-dark-black: linear-gradient(0deg, #1d254f 0%, #3e4c87 100%);

$gradient-light-purple: linear-gradient(0deg, #efeffd 0%, #efeffd 100%);
$gradient-light-blue: linear-gradient(0deg, #dfefff 0%, #dfefff 100%);
$gradient-light-turquoise: linear-gradient(0deg, #d1f4ff 0%, #e8fbff 100%);
$gradient-light-red: linear-gradient(0deg, #f0e3ea 0%, #f0e3ea 100%);
$gradient-light-green: linear-gradient(0deg, #ecfbec 0%, #ecfbec 100%);
$gradient-light-orange: linear-gradient(0deg, #fef6d9 0%, #fef6d9 100%);
$gradient-light-yellow: linear-gradient(0deg, #fffaf0 0%, #fffaf0 100%);
$gradient-light-pink: linear-gradient(0deg, #fae3ee 0%, #fae3ee 100%);
$gradient-light-black: linear-gradient(0deg, #f6f7fa 0%, #f6f7fa 100%);

$colors-dark: (
  'purple': $color-dark-purple,
  'blue': $color-dark-blue,
  'turquoise': $color-dark-turquoise,
  'red': $color-dark-red,
  'red-2': $color-dark-red-2,
  'green': $color-dark-green,
  'green-2': $color-dark-green-2,
  'orange': $color-dark-orange,
  'yellow': $color-dark-yellow,
  'yellow-2': $color-dark-yellow-2,
  'pink': $color-dark-pink,
  'black': $color-dark-black,
  'gray': $color-dark-gray,
);

@function color-dark($key) {
  @return map-get($colors-dark, $key);
}

$colors-light: (
  'purple': $color-light-purple,
  'blue': $color-light-blue,
  'turquoise': $color-light-turquoise,
  'red': $color-light-red,
  'red-2': $color-light-red-2,
  'green': $color-light-green,
  'green-2': $color-light-green-2,
  'orange': $color-light-orange,
  'yellow': $color-light-yellow,
  'pink': $color-light-pink,
  'gray': $color-light-gray,
  'black': $color-light-black,
);

@function color-light($key) {
  @return map-get($colors-light, $key);
}

$gradients-dark: (
  'purple': $gradient-dark-purple,
  'blue': $gradient-dark-blue,
  'turquoise': $gradient-dark-turquoise,
  'red': $gradient-dark-red,
  'green': $gradient-dark-green,
  'orange': $gradient-dark-orange,
  'yellow': $gradient-dark-yellow,
  'pink': $gradient-dark-pink,
  'black': $gradient-dark-black,
);

@function gradient-dark($key) {
  @return map-get($gradients-dark, $key);
}

$gradients-light: (
  'purple': $gradient-light-purple,
  'blue': $gradient-light-blue,
  'turquoise': $gradient-light-turquoise,
  'red': $gradient-light-red,
  'green': $gradient-light-green,
  'orange': $gradient-light-orange,
  'yellow': $gradient-light-yellow,
  'pink': $gradient-light-pink,
  'black': $gradient-light-black,
);

@function gradient-light($key) {
  @return map-get($gradients-light, $key);
}
