@import '~styles';

.podium {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

