@import '~styles';

.container {
  border-radius: 15px;
  background: $color-light;
  box-shadow: $shadow-dark-blue;
  padding: $spacer-s;
  width: 440px;
  max-width: 100%;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio {
  max-width: 100%;
  min-width: 50%;
  min-height: 300px;
  max-height: 600px;
  border-radius: 15px;
}

.play {
  i {
    font-size: $font-size-l;
    margin: $spacer-3xs 0 0 $spacer-3xs;
  }
}

.duration {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.footer {
  margin-top: $spacer-xs;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .play {
      @include buttonTheme($color);
    }
  }
}
