@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.combinations-title {
  text-align: center;
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-text;
  margin-bottom: $spacer-xs;
}

.combinations {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-gap: $spacer-xs;
  margin-bottom: $spacer-m;

  @include responsiveMedium() {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }

  @include responsiveSmall() {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }
}

.good-answer {
  color: $color-text-light;
  width: 100%;
  background-color: $color-success;
  border-radius: 20px;
  padding: $spacer-xs;
}

.good-answer-title {
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-light;
  text-align: center;
  margin-bottom: $spacer-xs;
}

.items {
  .item {
    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}

.item {
  background-color: $color-light;
  display: flex;
  padding: $spacer-xs;
  align-items: center;
}

.pair {
  background-color: $color-light-gray;
}

.position {
  color: $color-light;
  font-weight: $font-weight-bold;
  font-size: $font-size-2xs;
  background: $background-gradient-primary;
  border: $border-light;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  line-height: 1.688rem;
  text-align: center;
  margin-right: $spacer-xs;
  box-shadow: $shadow-purple;
}

.item-icon {
  color: $color-success;
  font-size: $font-size-m;
  margin-right: $spacer-2xs;
}

.item-icon-wrong {
  color: $color-wrong;
  margin-right: $spacer-2xs;
}

.occurrence {
  color: $color-success;
  font-weight: $font-weight-bold;
  margin-right: $spacer-3xs;
}

.occurrence-icon {
  color: $color-success;
}
