@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.video-wrapper {
  display: flex;
  justify-content: center;
}

.video {
  max-width: 100%;

  &.youtube {
    width: 100vw;
    height: 56.25vw;
  }
}

.duration {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}
