@import '../Styles/OnboardingSubPage.module.scss';

.container {
  @include styledSubPage();
}

.select-button:nth-child(1) {
  background: $color-light-blue;
}

.select-button:nth-child(2) {
  background: $color-light-yellow;
}

.select-button:nth-child(3) {
  background: $color-light-red-2;
}

.button-illustration {
  @include responsiveMedium() {
    height: 70px;
    width: auto;
  }
}

.point:nth-child(1) {
  background: $color-primary;
  width: 30px;
}
