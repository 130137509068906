@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.category {
  display: flex;
  margin-bottom: $spacer-2xs;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  color: $color-text;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.category-title {
  border-right: 0.0875rem solid color(gray-600);
  flex: 0 0 auto;
  font-weight: $font-weight-bold;
  margin-right: $spacer-s;
  padding-right: $spacer-s;
  width: 45%;

  @include responsiveSmall() {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
    width: 100%;
  }
}

.answers {
  flex-grow: 1;
}

.answer {
  display: flex;
  align-items: center;

  img {
    max-width: 100% !important;
  }
}

.correct {
  color: darken($color-correct, 20) !important;
}

.wrong {
  color: $color-wrong !important;
}

.icon {
  flex: 0 0 auto;
  font-size: $font-size-3xs;
  width: 1.5rem;
}

.text {
  margin-right: $spacer-xs;
  width: fit-content;
}
