@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.numbers {
  display: flex;
  justify-content: space-between;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.correct-range {
  font-size: $font-size-2xs;
  margin-bottom: $spacer-xs;
}
