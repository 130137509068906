@import '~styles';

.link-input-container {
  display: flex;
  align-items: center;
  margin: $spacer-xs 0 $spacer-m 0;
}

.link {
  ::placeholder {
    font-weight: $font-weight-normal;
    color: $color-text-light;
  }
}

.link-reset-button {
  margin-left: $spacer-xs;
}
