@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.answer-complete {
  flex-grow: 1;
  margin: 0 48px 0 0 !important;
}
