@import '~styles';

.share-button {
  padding-left: $spacer-m;
  padding-right: $spacer-m;
  box-shadow: none;
}

.warning-container {
  margin: -$spacer-m 0 $spacer-m 0;
  display: flex;
  flex-direction: column;
  gap: $spacer-xs;
}

.warning {
  padding: $spacer-xs $spacer-s;
  border-radius: 15px;
  background-color: color(yellow-50);
  color: color(yellow-800);
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.code,
.email,
.social {
  margin-top: $spacer-l;
}

.tabs {
  margin-bottom: 45px;
}

.close {
  @include responsiveSmall() {
    top: $spacer-2xl;
    right: $spacer-2xl;
  }
}
