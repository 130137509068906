@import '~styles';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  padding: $spacer-s $spacer-l;
  background: none;
  border: none;
  color: $color-text-light;
  transition: color ease-in-out 250ms;
  text-align: left;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
    text-decoration: $decoration-none;
  }

  &.isActive {
    color: $color-text;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }
}

.icon {
  font-size: $font-size-m;
  margin-right: $spacer-m;
}
