@import '~styles';

.pin {
  position: absolute;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  touch-action: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(226, 24, 24, 0.801);
  border: $border-light;
  font-weight: $font-weight-bolder;
  color: $color-light;
  z-index: $z-layer-low-level;
  animation-name: load;
  animation-duration: 1s;

  &.correct {
    background-color: rgba(125, 226, 24, 0.4);
  }
}

@keyframes load {
  0% {
    width: 0;
    height: 0;
  }
  20% {
    width: 0;
    height: 0;
  }
  40% {
    width: 55px;
    height: 55px;
  }
  100% {
    width: 35px;
    height: 35px;
  }
}
