@import '~components/Shared/Styles/StyledModal.module.scss';

.mobile-screen {
  display: none;

  @include responsiveSmall() {
    display: block;
  }
}

.modal {
  @include styledModal();

  position: fixed;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacer-xs;
  background-color: $color-light;
  border-radius: 20px 20px 0 0;
  padding: 40px $spacer-s $spacer-m $spacer-s;
  transform: translateY(100%);
  transition: transform 0.2s ease-in-out;

  &.isVisible {
    transform: translateY(0);
  }
}

.mobile-action {
  border: $border-gray-light;
  width: 100%;
  text-align: center;
}
