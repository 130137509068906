@import '~styles';

.container {
  border-radius: 25px;
  background-color: $color-light;
  border: $border-black-light;
  padding: 38px $spacer-xl;

  @include responsiveSmall() {
    padding: $spacer-m;
  }
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-l;
}

.line {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-s;

  @include responsiveSmall() {
    margin-bottom:$spacer-xs;
  }
}

.indicator {
  font-size: $font-size-2xs;
  margin-right: $spacer-s;

  &.success {
    color: $color-success;
  }

  &.mastery {
    color: color(orange-300);
  }

  &.progression {
    color: color(blue-300);
  }
}

.name {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: $spacer-s;
}

.tooltip {
  font-size: $font-size-3xs;
  margin-left: $spacer-xs;
}

.gauge {
  margin-right: $spacer-s;
  height: 5px;
  width: 100%;
  max-width: 200px;
  background-color: $color-blue-light;
  border-radius: 5px;

  & > div {
    height: 5px;
    border-radius: 5px;
  }

  &.success > div {
    background: $background-gradient-green;
  }

  &.mastery > div {
    background: $background-gradient-orange;
  }

  &.progression > div {
    background: $background-gradient-primary;
  }
}

.percentage {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  width: 50px;
  text-align: right;
}
