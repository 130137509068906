@import './spacers.scss';

@mixin hide() {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
}

@mixin textGradient($from, $to) {
  background-color: $from;
  background-image: linear-gradient(45deg, $from, $to);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  // Style adapted for Safari: background-clip:text doesn't work on Safari
  // the linear gradient cannot be applied
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      -webkit-text-fill-color: $from;
    }
  }
}

@mixin textOverflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin responsiveLarge() {
  @media only screen and (max-width: 1650px) {
    @content;
  }
}

@mixin responsiveMedium() {
  @media only screen and (max-width: 1312px) {
    @content;
  }
}

@mixin responsiveSmall() {
  @media only screen and (max-width: 800px) {
    @content;
  }
}

@mixin responsiveSmallSidePanel() {
  @media only screen and (max-width: 1130px) {
    @content;
  }
}

@mixin responsiveLargeSidePanel() {
  @media only screen and (max-width: 930px) {
    @content;
  }
}

@mixin themeBackground() {
  background-color: $color-primary;
  transition: background-color 250ms ease-in-out;

  &.theme-gray {
    background-color: color(gray-500);
  }

  &.theme-orange {
    background-color: $color-orange;
  }

  &.theme-yellow {
    background-color: color(yellow-700);
  }

  &.theme-red {
    background-color: $color-red;
  }

  &.theme-green {
    background-color: $color-green;
  }

  &.theme-blue {
    background-color: $color-blue;
  }

  &.theme-purple {
    background-color: $color-primary;
  }
}

@mixin themeColor() {
  color: $color-primary;
  transition: color 250ms ease-in-out;

  &.theme-gray {
    color: color(gray-500);
  }

  &.theme-orange {
    color: $color-orange;
  }

  &.theme-yellow {
    color: color(yellow-700);
  }

  &.theme-red {
    color: $color-red;
  }

  &.theme-green {
    color: $color-green;
  }

  &.theme-blue {
    color: $color-blue;
  }

  &.theme-purple {
    color: color(purple-400);
  }
}

@mixin scrollbarPrimary() {
  scrollbar-color: $color-primary $color-light-black;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 8px;
    height: 8px;
    margin-right: $spacer-xs;
    background-color: $color-light-black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 10px;
  }
}

@mixin buttonTheme($color) {
  background: color-pastel($color);
  color: $color-text;
  box-shadow: none;
}

@mixin ariaLabel() {
  border: none;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}
