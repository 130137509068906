@import '~questions/Shared/Styles/Editor.module.scss';
@import '~components/Shared/Styles/NewStyledModal.module.scss';

.expert-container {
  background-color: $color-light;
  border-radius: 15px;
  padding: $spacer-s;
  max-width: 200px;


  @include responsiveMedium() {
    max-width: 400px;
  }
}

.edit {
  font-size: $font-size-2xs;
  margin-left: $spacer-3xs;
  color: $color-text-light;
}

.label {
  background: none;
  padding-bottom: $spacer-3xs;
  padding-top: $spacer-3xs;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  border: $border-transparent;
  border-radius: 10px;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }
}

.modal {
  @include newStyledModal();
}

.container {
  @include editor();
}

.title {
  padding-right: 140px;
  text-align: left;

  span {
    font-weight: $font-weight-medium !important;
    margin-right: $spacer-xs;
  }
}

.button {
  margin-top: $spacer-xs;
  background-color: $color-light-black;
  border: none;
  width: 100%;
}

.primary {
  background-color: $color-light-purple-3;
  color: $color-dark-purple;

  &:hover,
  &:focus {
    background-color: $color-light-purple-2;
    color: $color-dark-purple;
  }
}

.list-element {
  margin-bottom: $spacer-xs !important;
}

.delete {
  margin-top: $spacer-l;
}

.add {
  width: fit-content;
  margin: auto;
}

.large {
  background: $color-light-black !important;
  width: 100%;
  box-shadow: none;
  color: $color-text;

  &:focus,
  &:hover {
    color: $color-text;
  }
}
