@import '~styles';

.header {
  display: flex;
  align-items: center;
  background: color(light);
  border-radius: 15px;
  padding: $spacer-l;
  margin-bottom: $spacer-xl;
}

.icon {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-m;
  margin-right: $spacer-s;
  background: color(purple-50);
  color: color(blue-300);
  flex-shrink: 0;
}

.title-header {
  display: flex;
  align-items: center;
  margin-right: $spacer-s;
}

.title-view {
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  margin-right: $spacer-xs;
}

.title-edit {
  margin-right: $spacer-xs;
}

.fill {
  flex-grow: 1;
}

.tiles {
  display: flex;
  align-items: flex-start;

  @include responsiveMedium() {
    justify-content: space-between;
  }

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.tile {
  background: $color-light;
  box-shadow: $shadow-purple;
  border-radius: 15px;
  padding: $spacer-l;
  margin-right: $spacer-2xl;
  width: 33%;

  @include responsiveMedium() {
    width: 49%;
    margin-right: 0;
  }

  @include responsiveSmall() {
    width: 100%;
    margin-right: 0;
    margin-bottom: $spacer-xs;
  }
}

.tile-title {
  @include textBolderDark();

  line-height: 1.375rem;
  margin-bottom: $spacer-l;
}

.field {
  margin-bottom: $spacer-s;
}

.error {
  @include textError();

  margin-bottom: $spacer-l;
}
