@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.answer {
  font-size: 110%;
}

.correct {
  margin-bottom: $spacer-m;
}

.isAdded {
  background: $color-dark-green;
}

.isRemoved {
  color: $color-light;
  background: $color-dark-red;
}
