@import '../Styles/OnboardingSubPage.module.scss';

.container {
  @include styledSubPage();

  .point:nth-child(4) {
    background: $color-primary;
    width: 30px;
  }

  .illustration {
    width: 350px;

    @include responsiveSmall() {
      width: 100%;
      padding: $spacer-2xl $spacer-xs 0 $spacer-xs;
    }
  }

  .form {
    margin: auto;

    @include responsiveSmall() {
      margin: 0;
      justify-content: flex-end;
    }
  }

  .action {
    width: fit-content;
    margin: 0 auto;

    @include responsiveSmall() {
      width: 100%;
      margin: none;
      margin-top: 0;
    }
  }
}
