@import '../Styles/OnboardingSubPage.module.scss';

.container {
  @include styledSubPage();

  .point:nth-child(5) {
    background: $color-primary;
    width: 30px;
  }

  .form {
    justify-content: flex-start;
    margin-top: 0;

    @include responsiveSmall() {
      margin: 0;
      justify-content: flex-end;
      padding: $spacer-xs $spacer-m $spacer-2xl $spacer-m;
    }
  }

  .illustration {
    max-width: 300px;

    @include responsiveSmall() {
      max-width: none;
      padding: $spacer-2xl $spacer-xs 0 $spacer-xs;
    }
  }

  .title {
    max-width: 350px;
    margin: $spacer-s auto $spacer-xl auto;

    @include responsiveSmall() {
      max-width: none;
      margin: $spacer-l auto $spacer-xs auto;
    }
  }

  .action {
    width: fit-content;
    margin: 0 auto;

    @include responsiveSmall() {
      width: 100%;
      margin: none;
      margin-top: 0;
    }
  }

  .action-text {
    font-weight: $font-weight-bolder;
    font-size: $font-size-xs;
    text-align: center;
  }

  .reject {
    width: fit-content;
    margin: $spacer-m auto $spacer-l auto;

    @include responsiveSmall() {
      margin: $spacer-2xs auto 0 auto;
    }
  }
}
