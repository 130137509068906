@import '~styles';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include responsiveMedium() {
    flex-direction: column;
  }

  &.column {
    @include responsiveLarge() {
      flex-direction: column;
    }
  }
}

.toolbar {
  min-height: 100%;
}

.toolbar-menu-container {
  border-radius: 20px 20px 0 0;
  background-color: $color-light;
  margin: 0 auto;
  padding-bottom: $spacer-m;
  border-bottom: 1px solid $color-light-gray;
  width: 100%;
  padding: $spacer-l $spacer-m $spacer-m $spacer-m;
}

.toolbar-menu {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  background-color: $color-light-black;
  padding: $spacer-3xs;
}

.icon {
  font-size: $font-size-l;
  color: $color-text-light;
}

.toolbar-menu-button {
  border-radius: 5px;
  border: none;
  width: 95px;
  height: 45px;
  background-color: transparent;
  outline: none;

  .icon {
    @include textGradient(#c0a8ff, $color-dark-purple);
  }

  &:hover,
  &:focus {
    outline: none;
    background: $background-gradient-primary;

    .icon {
      @include textGradient($color-light, $color-light);
    }
  }

  &.isActive {
    background: $background-gradient-primary;

    .icon {
      @include textGradient($color-light, $color-light);
    }
  }
}

.toolbar-content {
  background-color: $color-light;
  border-radius: 0 0 20px 20px;
  padding: $spacer-m;
}

.canvas-container {
  margin: $spacer-l $spacer-2xl;

  @include responsiveMedium() {
    margin: $spacer-l auto 40px auto;
  }

  &.column {
    @include responsiveLarge() {
      margin: $spacer-l auto 40px auto;
    }
  }
}

.canvas {
  border-radius: 30px;
  outline: 22px solid $color-light;
  box-shadow: $shadow-dark-blue;
  max-width: 100%;

  @include responsiveSmall() {
    outline: 10px solid $color-light;
  }
}
