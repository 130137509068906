@import '~components/Shared/Styles/Auth.module.scss';

.container {
  @include styledAuth();
}

.section {
  @include responsiveSmall() {
    @include styledSection();
  }
}

.title {
  margin-bottom: $spacer-3xs !important;
}

.power-subtitle {
  color: $color-text-light;
}

.information {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-m;
  border-radius: 12px;
  background-color: $color-pastel-blue;
  padding: $spacer-s;
  background-image: url(./images/wave.svg);
  background-repeat: none;
  background-size: cover;
}

.information-icon {
  color: $color-text;
  margin-right: $spacer-s;
  margin-right: $spacer-xs;
  background-color: $color-light;
  border-radius: 30px;
}

.information-text {
  text-align: left;
}
