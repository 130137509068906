@import 'styles';

.container {
  padding: $spacer-2xl $spacer-l $spacer-l $spacer-l;
  background-color: $color-light;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacer-l;

  @include responsiveSmall() {
    justify-content: space-between;
    gap: $spacer-2xs;
  }
}

.title {
  @include titleH1Big();

  text-align: center;

  @include responsiveSmall() {
    font-size: $font-size-m;
  }
}

.subtitle {
  @include textNormalDark();

  text-align: center;
}

.picture {
  max-height: 300px;
  padding: $spacer-xs 0;

  @include responsiveSmall() {
    max-height: 170px;
    padding: 0;
  }
}

.access-course {
  @include responsiveSmall() {
    max-height: 400px;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: $spacer-xs;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.actions-title {
  font-size: $font-size-m;
  margin-bottom: $spacer-m;

  @include responsiveSmall() {
    font-size: $font-size-s;
    margin-bottom: $spacer-xs;
  }
}

.ia-border {
  height: fit-content;
  padding: 2px;
  background: $background-gradient-ia;
  border-radius: 18px;
  transition: background 0.2s ease;

  &:hover,
  &:focus {
    background: $color-light-gray;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
  padding: $spacer-2xs $spacer-xs;
  background: $color-light;
  border-radius: 16px;
  text-align: left;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  border: 2px solid $color-light-gray;
  transition: background 0.2s ease;

  &:hover,
  &:focus {
    background: $color-light-gray;
    outline: none;
  }
}

.button-image {
  width: 50px;
  height: 40px;
}

.loading {
  margin: 0 auto;
  font-size: $font-size-xl;
  color: $color-dark-purple;
  width: 40px;
  height: 40px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.button-access-course {
  width: 500px;
  margin: 0 auto;

  @include responsiveSmall() {
    width: 100%;
  }
}

.button-ia {
  border-color: transparent;
  width: 100%;
}
