@import '~styles';

.container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  padding: $spacer-s $spacer-l;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: $spacer-m;
  border-radius: 5px;
  background-color: $color-light-black;
  background-size: cover;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bolder;
}

.title {
  @include textNormalDark();

  flex-grow: 1;
  margin-right: $spacer-m;
}

.ongoing {
  margin: 0 $spacer-xs;
  padding: $spacer-3xs $spacer-xs;
  border-radius: 15px;
  background-color: $color-light-orange;
  color: $color-dark-orange;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.action {
  flex: 0 0 auto;
}
