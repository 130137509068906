@import '~styles';

.container-limits {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  transition: width 0.1s ease-out;
}

.limits-large {
  width: 205px;
}

.container {
  position: absolute;
  height: 100%;
  width: 190px;
  right: -205px;
  padding: 40px $spacer-l;
  background-color: $color-light;
  z-index: $z-layer-low-level;
  box-shadow: $shadow-purple;
  transition: right 0.1s ease-out;
  border-radius: 30px 0 0 30px;
}

.open {
  right: 0;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-l;
}

.button {
  margin-left: -40px;
}

.title {
  font-weight: $font-weight-bolder;
  font-size: $font-size-xs;
  margin-left: $spacer-xs;
}

.templates {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer-xs;
  max-height: 100%;
  overflow-x: scroll;
}

.button-container {
  height: 80px;
  border-radius: 12px;
  background: $color-light;
  border: $border-gray-light;

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    border: $border-dark;
  }
}

.template-illustration {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
