@import '~styles';

.container {
  border-top: 2px solid $color-blue-light;
  padding-top: $spacer-xs;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-xs;

  @include responsiveSmall() {
    flex-wrap: wrap;
  }
}

.explanation {
  color: $color-dark-orange;
  font-size: $font-size-xs;
  font-weight: bold;
  border-radius: 10px;
  background-color: $color-light-orange;
  margin-bottom: $spacer-m;
  padding: $spacer-xs 37px;

  @include responsiveSmall() {
    margin-bottom: $spacer-s;
  }
}
