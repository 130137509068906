@import '~components/Shared/Styles/StyledModal.module.scss';

.modal {
  @include styledModal();

  width: 60vw;

  @include responsiveSmall() {
    width: 80vw;
  }
}

.close {
  @include responsiveSmall() {
    top: $spacer-2xl;
    right: $spacer-2xl;
  }
}
