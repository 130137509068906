@import '~styles';

.chapters {
  background: transparent;
  border-radius: 10px;
}

.chapter {
  margin-bottom: $spacer-xs;
}

.chapters.isDraggingOver {
  background: color(gray-200);
}
