@import '~styles';

.header {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin-bottom: $spacer-s;
  width: 100%;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }
}

.icon {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-3xs;
  margin-right: $spacer-xs;
  background: color(purple-50);
  color: color(blue-300);
}

.title {
  @include textOverflow();
  @include textBolderDark();

  flex-grow: 1;
  margin-right: $spacer-l;
  text-align: left;
}

.toggle {
  color: $color-text;
  margin-right: $spacer-xs;
  width: $spacer-2xs;
}
