@import '~styles';

@mixin editor() {
  .field {
    margin-bottom: $spacer-s;
  }

  .label {
    color: $color-text;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    margin-bottom: $spacer-3xs;
  }

  .subtitle {
    color: $color-text;
    font-size: $font-size-xs;
    font-weight: $font-weight-bolder;
    margin-bottom: $spacer-3xs;
  }

  .zone {
    border-radius: $spacer-2xs;
    margin-bottom: $spacer-3xs;
  }

  .list-element {
    align-items: center;
    border-radius: 0.5rem;
    display: flex;
    margin-bottom: $spacer-3xs;

    & > * {
      margin-right: $spacer-2xs;

      &:last-child {
        margin-right: 0;
      }
    }

    .drag {
      color: color(gray-500);
      cursor: move !important;
      flex: 0 0 auto;
      font-size: $font-size-xs;
    }

    .fill {
      flex-grow: 1;
    }

    .hidden {
      @include hide();
    }

    &.isDragging {
      background: color(blue-50);
      padding: 1rem !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .pair-element {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer-3xs;

    @include responsiveMedium() {
      flex-wrap: wrap;
      margin-bottom: $spacer-2xs;
    }

    .left,
    .right {
      flex: 0 0 auto;
      width: calc(50% - 32px);

      @include responsiveMedium() {
        margin-bottom: $spacer-3xs;
        width: calc(100% - 48px);
      }
    }

    .arrow {
      @include responsiveMedium() {
        display: none !important;
      }
    }
  }

  .actions {
    margin-top: $spacer-3xl;
  }

  .options {
    margin-top: $spacer-2xl;
    border-bottom: 1px solid $color-light-gray;
  }
}
