@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.fitb-text :global(.fr-box.fr-basic .fr-element) {
  min-height: 70px;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.description {
  color: $color-text-light;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}
