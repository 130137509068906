@import '~styles';

.title {
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-text;
  margin-bottom: $spacer-xs;
}

.container {
  border-radius: 15px;
  background-color: $color-light;
  padding: $spacer-2xl $spacer-2xl $spacer-m $spacer-2xl;
  margin-bottom: $spacer-m;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacer-m;

  @include responsiveSmall() {
    display: block;
  }
}

.period {
  font-weight: $font-weight-bolder;

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
  }
}

.actions {
  @include responsiveSmall() {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.action {
  margin-right: $spacer-xs;
}

.before {
  @include responsiveSmall() {
    order: 1;
  }
}

.action-today {
  @include responsiveSmall() {
    order: 2;
  }
}

.next {
  @include responsiveSmall() {
    order: 3;
  }
}

.week-large {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: 100%;
  border-radius: 23px 23px 0 0;
  border-top: 2px solid $color-light-black;

  @include responsiveMedium() {
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 0;
  }

  @include responsiveSmall() {
    display: none;
  }
}

.week-small {
  display: none;
  height: 100%;

  @include responsiveSmall() {
    display: block;
  }
}

.week-small-header {
  display: flex;
  justify-content: space-between;
}

.day {
  height: 100%;
  min-width: 100%;
  border-left: 1px solid $color-light-black;
  border-right: 1px solid $color-light-black;
  border-bottom: 2px solid $color-light-black;

  &.first {
    border-radius: 23px 0 0 0;
    border-left: 2px solid $color-light-black;

    @include responsiveMedium() {
      border-radius: 0;
    }
  }

  &.last {
    border-radius: 0 23px 0 0;
    border-right: 2px solid $color-light-black;

    @include responsiveMedium() {
      border-radius: 0;
    }
  }
}

.day-header {
  text-align: center;
  color: $color-text-light;
  background-color: $color-light;
  font-weight: $font-weight-bold;
  border-bottom: 2px solid $color-light-black;

  &.first {
    border-radius: 23px 0 0 0;
  }

  &.last {
    border-radius: 0 23px 0 0;
  }

  @include responsiveSmall() {
    border: none;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.date-number {
  background-color: $color-light-purple-2;
  height: 45px;
  width: 45px;
  line-height: 2.813rem;
  border-radius: 30px;
  font-weight: $font-weight-bolder;
  color: $color-primary;
  margin: $spacer-m auto 0 auto;

  &.today {
    color: $color-text;
    background: $color-secondary;
  }

  @include responsiveSmall() {
    height: 35px;
    width: 35px;
    line-height: 2.188rem;
    font-size: $font-size-xs;
    margin: 0;
  }
}

.date-day {
  margin-bottom: $spacer-s;

  &.today {
    color: $color-text;
    font-weight: $font-weight-bolder;
  }

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
  }
}

.future-day {
  color: $color-text;
}

.day-courses {
  padding: $spacer-xs $spacer-xs $spacer-3xs $spacer-xs;
  height: calc(100% - 60px);
  min-height: 100px;
  width: 100%;

  @include responsiveSmall() {
    border: $border-black-light;
    border-radius: 10px;
    min-height: 100px;
  }
}
