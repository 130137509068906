@import '~components/Shared/Styles/StyledSubPage.module.scss';

.container {
  @include styledSubPage();
}

.subpage {
  display: grid;
  grid-template-columns: 250px calc(100% - 300px);
  margin-top: $spacer-xl;
  gap: 50px;

  @include responsiveSmall() {
    display: flex;
  }
}

.question-info {
  @include responsiveSmall() {
    display: none;
  }
}

.question-picture-container {
  width: 100%;
  height: 130px;
  padding: $spacer-xs;
  border-radius: 25px;
  background-color: $color-light-black;
  margin-bottom: $spacer-m;
}

.question-picture {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  object-fit: contain;
}

.question-tooltip {
  color: $color-text-light;
}

.types {
  @include scrollbarPrimary();

  width: 100%;
  max-width: 100%;
  height: calc(100vh - 60px);
}

.category-title {
  font-size: $font-size-m;
  line-height: 1.5rem;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-s;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer-xs;

  @include responsiveSmall() {
    display: grid;
    grid-template-columns: repeat(auto-fill, 145px);
    justify-content: center;
    gap: $spacer-2xs;
  }
}

.informative-cards {
  margin-bottom: 50px;
}

.type {
  margin: 0 $spacer-xs $spacer-xs 0;

  @include responsiveSmall() {
    margin: 0 $spacer-3xs $spacer-3xs 0;
  }
}
