@import '~styles';

.container {
  margin-top: $spacer-2xl;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-l;
}

.course-item {
  margin-bottom: $spacer-xs;
}

.empty {
  color: $color-text;
  font-size: $font-size-m;
  padding: $spacer-2xl 0;
  text-align: center;
}
