@import '~components/Shared/Styles/Auth.module.scss';

.container {
  @include styledAuth();
}

.title {
  @include responsiveSmall() {
    color: $color-light !important;
  }
}
