@import '~styles';

.container {
  display: flex;
  align-items: center;
}

.form {
  align-items: center;
  display: flex;
  width: 100%;
}

.field {
  margin-right: $spacer-xs;
  width: 30rem;
  max-width: 100%;
}

.introduction-container {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
}

.introduction {
  color: $color-text-light;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
  text-align: left;
}

.edit-button {
  border: none;
}
