@import '~styles';

.container {
  padding: 40px;
  border-radius: 25px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  text-align: center;
}

.image {
  margin-bottom: $spacer-2xl;
  max-width: 100%;
  width: 15rem;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-top: $spacer-2xl;
}
