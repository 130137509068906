@import './palette.scss';
@import './utilities.scss';

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }
}

.text-gradient-purple {
  @include textGradient(#8258f3, #9f7cff);
}

.text-gradient-red {
  @include textGradient(#d80e1a, #fb838c);
}

.text-gradient-black {
  @include textGradient(#1d254f, #3e4c87);
}

.text-gradient-blue {
  @include textGradient(#146aff, #2da3ff);
}

.text-gradient-green {
  @include textGradient(#6bdd6a, #a5f1a5);
}

.text-gradient-orange {
  @include textGradient(#eb9500, #f7c700);
}

.text-gradient-pink {
  @include textGradient(#f5438c, #f67aac);
}

.ReactModal__Body--open {
  overflow: hidden !important;

  #root * {
    .fa,
    .fas,
    .fab {
      overflow: unset !important;
    }
  }
}
