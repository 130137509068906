@import '~styles';

@mixin innerNewStyledModal() {
  .close {
    position: absolute;
    top: $spacer-2xl;
    right: $spacer-2xl;

    @include responsiveSmall() {
      margin: 0;
    }
  }

  .image {
    display: block;
    width: 175px;
    margin: 0 auto 38px;

    &.small {
      width: 90px;
    }
  }

  .title {
    align-self: flex-start;
    margin-bottom: $spacer-2xl;
    color: $color-text;
    font-size: $font-size-m;
    font-weight: $font-weight-bolder;
  }

  .centered-title {
    align-self: center;
  }

  .subtitle {
    @include textNormalDark();

    margin-bottom: $spacer-2xl;
  }

  .error {
    @include textError();

    margin-bottom: $spacer-l;
  }

  .field {
    margin-bottom: $spacer-s;
  }

  .action {
    display: flex;
    margin: $spacer-xl auto 0;

    & + .action {
      margin-top: $spacer-xs;
    }
  }
}

@mixin newStyledModal() {
  @include innerNewStyledModal();

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  max-width: 98%;
  padding: 37px $spacer-2xl;
  margin: auto;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  text-align: center;

  @include responsiveSmall() {
    padding: $spacer-m;
  }

  &.large {
    width: 700px;
  }

  &.extra-large {
    width: 960px;
  }
}
