@import '~styles';

.container {
  position: fixed;
  bottom: $spacer-l;
  right: $spacer-l;
  background-color: $color-pastel-yellow;
  background-image: url('./images/wave.svg');
  background-repeat: none;
  background-size: cover;
  padding: $spacer-s $spacer-s $spacer-m $spacer-xl;
  border-radius: 25px;
  width: 250px;
  z-index: $z-layer-low-level;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.title {
  font-weight: $font-weight-medium;
  margin-bottom: $spacer-m;
  text-align: center;
}

.illustration {
  width: 230px;
  margin: 0 0 0 -120px;
}

.button {
  width: 100%;
}
