@import '~styles';

.container {
  @include textBolderDark();

  border: $border-transparent;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  background: $color-light;

  &:focus,
  &:hover {
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
    outline: none;
  }
}

@each $color in $palette-theme {
  .background-#{$color} {
    background: color-pastel($color);
  }
}

.isActive {
  border-color: color(dark);
}
