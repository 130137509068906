@import '~styles';

.container {
  cursor: pointer;
  border: $border-light;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin-left 200ms ease-in-out, padding 200ms ease-in-out;
  width: 100%;
  margin-left: 0;
  padding: 0;

  &:hover,
  &:focus {
    width: 101%;
    padding: 0 0.5%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }
}

.aria-drag-description {
  @include ariaLabel();
}

.chapter {
  display: flex;
  align-items: center;
  gap: $spacer-3xs;
  padding: 0 $spacer-l 0 $spacer-xs;

  @include responsiveSmall() {
    padding: 0 $spacer-xs;

  }
}

.sort {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: $color-text-light;
  background: none;
  border-radius: 50px;
  border: $border-transparent;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
  }

  &:focus-visible {
    border-color: $color-text;
  }
}

.icon {
  @include textGradient($color-dark-purple, color(blue-300));

  align-items: center;
  border: none;
  display: flex;
  flex: 0 0 auto;
  font-size: $font-size-xs;
  height: 30px;
  justify-content: center;
  transition: color 250ms ease-in-out;
  width: 30px;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 50%;
  }
}

.chapter-icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: center;
  width: 32px;
  margin-right: $spacer-m;
  border-radius: 5px;
  background: #f6f7fa;
  color: #3e4c87;
  font-size: $font-size-xs;
}

.ia-icon {
  @include textGradient($color-ia-pink, $color-ia-blue);

  font-size: $font-size-m;
  margin-top: $spacer-3xs;
}

.comment-notification {
  background-color: $color-secondary;
  border-radius: 50px;
  height: 12px;
  width: 12px;
  margin-right: $spacer-xs;
}

.title {
  @include textOverflow();
  @include textBolderDark();

  flex-grow: 1;
  padding: $spacer-s $spacer-xs $spacer-s 0;
  text-align: left;

  &:hover,
  &:focus {
    outline: none;
  }
}

.hidden,
.linked {
  color: color(dark);
  font-size: $font-size-3xs;
  margin-right: $spacer-3xs;
}

.action {
  flex: 0 0 auto;
  margin-left: $spacer-xs;
}

.questions {
  border-radius: 10px;
  min-height: 3rem;
  transition: background 250ms ease-in-out;
  margin: $spacer-3xs $spacer-xs;
}

.question {
  margin-bottom: $spacer-xs;
}

.questions.isDraggingOver {
  background: color(gray-200);
}

.container.isDraggingOver {
  background: color(gray-100);
  border-color: color(purple-400);
}

.container.isSelected {
  border-color: color(purple-400);
}

.container.isDragging {
  border-color: color(purple-400);
}
