@import '~styles';

@mixin face() {
  .title {
    color: $color-text;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    margin-bottom: $spacer-s;

    @include responsiveSmall() {
      margin-bottom: $spacer-s;
    }
  }

  .instructions {
    color: $color-dark-purple;
    font-size: $font-size-xs;
    font-weight: bold;
    border-radius: 10px;
    background-color: $color-primary-light;
    margin-bottom: $spacer-s;
    padding: $spacer-xs 37px;

    @include responsiveSmall() {
      margin-bottom: $spacer-s;
    }
  }

  .label {
    color: $color-text;
    font-size: $font-size-2xs;
    font-weight: $font-weight-bold;
    margin-bottom: $spacer-xs;
  }

  .feedback {
    border-radius: 10px;
    background-color: $color-light;
    color: $color-text;
    font-size: $font-size-xs;
    font-weight: bold;
    margin-top: 37px;
    padding: $spacer-xs $spacer-s;
    display: flex;

    @include responsiveSmall() {
      margin-top: $spacer-s;
    }
  }

  .feedback-icon {
    color: $color-primary;
    margin: $spacer-3xs $spacer-2xs 0 0;
  }
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .instructions {
      background: color-light($color) !important;
      color: color-dark($color) !important;
    }

    .feedback-icon {
      color: color-dark($color) !important;
    }
  }
}
