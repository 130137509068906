@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.choice {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacer-2xs;
  width: 100%;
  gap: $spacer-2xs;
}

.choice-title {
  flex-grow: 1;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  color: $color-text;
}
