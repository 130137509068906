@import '~styles';

.header {
  display: flex;
  justify-content: space-between;
  gap: $spacer-xs;
  margin-bottom: $spacer-xs;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacer-xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.ia-button-container {
  width: fit-content;
  height: fit-content;
  padding: 2px;
  background: $background-gradient-ia;
  border-radius: 50px;
}

.ia-button {
  box-shadow: none;
}

.ia-button-text-large {
  @include responsiveSmall() {
    display: none;
  }
}

.ia-button-text-small {
  display: none;

  @include responsiveSmall() {
    display: block;
  }
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.feedback-placeholder {
  position: relative;
  color: $color-text-light;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  border-radius: 22.5px;
  overflow: hidden;
  z-index: $z-layer-neutral;

  &::before {
    content: '';
    position: absolute;
    z-index: $z-layer-negative;
    top: -50%;
    width: 110%;
    aspect-ratio: 1 / 1;
    transform: translateY(-40%);
    background-repeat: no-repeat;
    background-image: $background-gradient-ia;
    animation: rotation 2s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    left: $spacer-3xs;
    top: $spacer-3xs;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 20.5px;
    background: $color-light;
    z-index: $z-layer-negative;
  }
}

@keyframes rotation {
  100% {
    transform: translateY(-40%) rotate(1turn);
  }
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}
