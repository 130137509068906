@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.background {
  background: $color-light;
  border-radius: 2.8rem;
  box-shadow: $shadow-blue;
  padding: $spacer-2xl;
  background-size: cover;
  min-height: 50vh;
  display: flex;
  align-items: center;
  margin-bottom: $spacer-m;
  background-position: center;

  @include responsiveSmall() {
    padding: $spacer-s;
  }
}

@each $color in $palette-background {
  .background-#{$color} {
    background: gradient-dark($color) !important;
  }
}

@each $color in $palette-text {
  .text-color-#{$color} {
    color: color-text($color) !important;
  }
}

