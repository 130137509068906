@import '~styles';

.container {
  background-color: $color-light;
  border-radius: 10px;
}

.chapter {
  padding: 0 $spacer-l;
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;
  background-color: $color-light;
  border-radius: 10px;
}

.icon {
  @include textGradient($color-dark-purple, color(blue-300));

  align-items: center;
  border: none;
  display: flex;
  flex: 0 0 auto;
  font-size: $font-size-xs;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.comment-notification {
  background-color: $color-secondary;
  border-radius: 50px;
  height: 12px;
  width: 12px;
  margin: 0 (-$spacer-s) 0 $spacer-l;
}

.chapter-button {
  border: $border-transparent;
  background-color: transparent;
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
    border-radius: 10px;
  }
}

.title {
  @include textOverflow();
  @include textBolderDark();

  padding: $spacer-s $spacer-xs $spacer-s $spacer-l;
  text-align: left;
}

.linked {
  color: color(dark);
  font-size: $font-size-3xs;
  margin-right: $spacer-3xs;
}

.action {
  flex: 0 0 auto;
  margin-left: $spacer-xs;
}

.questions {
  border-radius: 10px;
  min-height: 3rem;
  transition: background 250ms ease-in-out;
  margin: $spacer-3xs $spacer-xs;
}

.question {
  margin-bottom: $spacer-xs;
}
