@import '~styles';

.container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  padding: 0 $spacer-2xl;
  min-height: 58px;
  margin-bottom: $spacer-xs;

  &.public {
    background-color: transparent;
    padding: 0 0 $spacer-xs 0;
    border-bottom: 2px solid $color-light-gray;

    .icon {
      margin: 0;
    }
  }
}

.icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: center;
  width: 32px;
  margin: 0 $spacer-xs;
  background-color: $color-light-gray;
  border-radius: 5px;

  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.title {
  @include textOverflow();
  @include textNormalDark();

  flex-grow: 1;
  padding: $spacer-s $spacer-xs;
  text-align: left;
}

.action {
  flex: 0 0 auto;
}
