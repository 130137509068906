@import '~questions/Shared/Styles/Face.module.scss';

.select {
  border: $border-gray-light;
  border-radius: 4rem;
  min-width: 6rem;
  height: 38px;
  line-height: 35px;
  padding: 0 $spacer-l;
  background-color: $color-light;
  display: inline-block;
  vertical-align: middle;
  font-weight: $font-weight-bold;

  &:focus,
  &:hover {
    outline: none;
  }

  &:focus {
    border-color: $color-text;
  }
}

.choices {
  display: flex;
  justify-content: center;
  max-width: 700px;
  flex-wrap: wrap;
  gap: $spacer-xs;

  li {
    list-style: none;
  }
}

.choice {
  padding: $spacer-xs $spacer-l;
  text-align: center;
  border-radius: 10px;
  background-color: $color-light;
  border: $border-dark;
  box-shadow: $shadow-blue;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;

  @include responsiveMedium() {
    padding: $spacer-xs $spacer-m;
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: $color-primary;
  }
}

.disabled {
  color: $color-text-light;
  border-color: $color-light-gray;

  &:hover,
  &:focus {
    border-color: $color-light-gray;
  }
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .choice {
      &:hover,
      &:focus {
        border-color: color-dark($color);
      }
    }
  }
}
