@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.input-container {
  display: flex;
  align-items: center;
}

.input {
  @include textBoldDark();

  display: block;
  width: 10rem;
  padding: $spacer-xs $spacer-l;
  border-radius: 22.5px;
  border: $border-gray-light;
  background-color: $color-light;
  height: 44px;
  min-width: 1rem;
  -moz-appearance: textfield;
  text-align: center;

  &:focus {
    border-color: $color-text;
  }

  &::placeholder {
    color: color(gray-700);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.colon {
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin: 0 $spacer-3xs;
  color: $color-text-light;
}

.spin {
  margin-left: $spacer-3xs;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 43px;
}

.button {
  height: 20px;
  width: 20px;
  font-size: $font-size-2xs;
  border: $border-thin-gray-light;
  border-radius: 5px;
  background-color: $color-light;
  color: $color-dark-gray;

  &:focus,
  &:hover {
    outline: none;
  }

  &:hover {
    outline: none;
    background-color: $color-light-black;
    color: $color-text;
  }
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled .input,
.isDisabled .textarea-container {
  background: color(gray-200);
  cursor: not-allowed;
  color: color(gray-700);
}

.hasError .input {
  border-color: $color-wrong;
}
