@import '~styles';

.zone {
  position: absolute;
  touch-action: none;
  user-select: none;
  outline: none;
  border: $border-light;
  background-color: rgba(125, 226, 24, 0.4);

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background-color: rgba(161, 236, 85, 0.4);
  }
}

.shape-rectangle {
  border-radius: 10px;
}

.shape-circle {
  border-radius: 50%;
}
