@import '~styles';

.mascot-feedback {
  display: flex;
  gap: $spacer-xs;
  align-items: flex-start;

  margin-top: 70px;

  @include responsiveSmall() {
    margin-top: $spacer-s;
    flex-direction: column;
  }
}

.mascot-feedback-content {
  border-radius: 30px;
  padding: $spacer-s $spacer-2xl;
  background-color: $color-light;
  color: $color-text;
}

.feedback {
  border-radius: 10px;
  background-color: $color-light;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: bold;
  margin-top: 37px;
  padding: $spacer-xs $spacer-s;
  display: flex;

  @include responsiveSmall() {
    margin-top: $spacer-s;
  }
}

.feedback-icon {
  color: $color-primary;
  margin: $spacer-3xs $spacer-2xs 0 0;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .feedback {
      .feedback-icon {
        color: color-pastel($color);
      }
    }
  }
}
