@import '~styles';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacer-l;
  margin-bottom: $spacer-xs;
}

.information {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
}

.header-title {
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-bottom: 0;
}

.title-large {
  @include responsiveSmall() {
    display: none;
  }
}

.title-small {
  display: none;

  @include responsiveSmall() {
    display: block;
  }
}

.header-right {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
  justify-content: flex-end;
}

.saved-container {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.save-information {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  gap: $spacer-3xs;
  color: $color-correct;
  font-weight: $font-weight-bolder;
  text-align: right;
  transform: translateX(110%);
  transition: all 0.2s ease-in-out;

  &.isVisible {
    transform: translateX(0);
  }
}

.save-text {
  @include responsiveMedium() {
    display: none;
  }
}
