@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.select {
  max-width: 300px;
  margin-bottom: $spacer-xs;
  margin-left: $spacer-m;

  @include responsiveMedium() {
    margin-left: 0;
  }
}

.select-feedback {
  margin-top: $spacer-xs;
}
