@import '~styles';

.container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  padding: $spacer-s $spacer-l;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: $spacer-m;
  border-radius: 5px;
  background-color: $color-light-black;
  background-size: cover;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bolder;
  position: relative;
}

.status {
  position: absolute;
  border-radius: 50px;
  background-color: $color-light-gray;
  height: 14px;
  width: 14px;
  top: -$spacer-2xs;
  right: -$spacer-2xs;
  border: $border-light;

  &.isActive {
    background-color: $color-secondary;
  }
}

.info {
  flex-grow: 1;
}

.title {
  @include textNormalDark();

  flex-grow: 1;
  margin-right: $spacer-m;
}

.action {
  flex: 0 0 auto;
}
