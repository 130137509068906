@import '~styles';

.container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-layer-low-level;
  background-image: url('./images/bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $color-text;
}

.close {
  height: 35px;
  width: 35px;
  border: none;
  background-color: transparent;
  color: $color-light;
  font-size: $font-size-xs;
  position: absolute;
  right: 0;

  &:hover,
  &:focus {
    outline: none;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-light;
  padding: $spacer-m $spacer-xs $spacer-s $spacer-s;
  gap: $spacer-xs;
}

.logo {
  width: 60px;
  height: 60px;
  background-color: $color-light;
  border-radius: 10px;
  padding: $spacer-xs;
}

.button {
  box-shadow: none;
  padding: $spacer-xs $spacer-s;
}
