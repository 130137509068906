@import '~styles';

.container {
  font-size: $font-size-s;
  text-align: center;
}

.first {
  order: 2;

  @include responsiveSmall() {
    order: 1;
  }
}

.second {
  order: 1;

  @include responsiveSmall() {
    order: 2;
  }
}

.third {
  order: 3;
}

.profil {
  width: 100px;
  height: 100px;
  background-color: color(gray-200);
  border-radius: 50%;
  border: $border-thicker-light;
  margin: 0 auto;
  z-index: $z-layer-header;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: $shadow-purple;
}

.profile-first {
  width: 150px;
  height: 150px;
  border: $border-thicker-light;
  position: relative;
}

.profile-without-picture {
  align-items: flex-end;
}

.image {
  max-height: 80%;
  display: block;
}

.picture {
  max-height: 100%;
  display: block;
}

.circles {
  display: grid;
  place-content: center;
}

.circle-1 {
  background-color: rgba($color: $color-light, $alpha: 0.3);
  width: 180px;
  height: 180px;
  border-radius: 50%;
  z-index: $z-layer-low-level;
  margin: -165px auto 0 auto;
  box-shadow:  $shadow-purple;
}

.circle-2 {
  background-color: rgba($color: $color-light, $alpha: 0.1);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  margin: -205px auto 0 auto;
  z-index: $z-layer-neutral;
  box-shadow:  $shadow-purple;
}

.confetti {
  width: 170px;
  height: 180px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(./images/confettis.svg);
  margin: -200px 0 0 $spacer-2xl;
  z-index: $z-layer-modal;
  animation: confetti cubic-bezier(0.55, 1.03, 0.37, 0.98) 2s 1;
}

@keyframes confetti {
  0% {
    width: 0;
    margin-top: -200px;
    margin-left: 100px;
  }

  20% {
    width: 170px;
    margin-top: -210px;
    margin-left: $spacer-2xl;
  }

  40% {
    width: 170px;
    margin-top: -208px;
    margin-left: $spacer-xl;
  }

  60% {
    width: 170px;
    margin-top: -206px;
    margin-left: $spacer-2xl;
  }

  80% {
    width: 170px;
    margin-top: -202px;
    margin-left: $spacer-xl;
  }

  100% {
    width: 170px;
    margin-top: -200px;
    margin-left: $spacer-2xl;
  }
}

.rank {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: $border-thick-light;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bolder;
  color: $color-light;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -$spacer-m auto 0 auto;
  z-index: $z-layer-mid-level;
  position: relative;
}

.rank-first {
  width: 52px;
  height: 52px;
  background: $background-gradient-orange;
  font-size: $font-size-m;
  border: $border-thicker-light;
  margin: -78px auto 0 auto;
}

.rank-second {
  background: $background-gradient-silver;
}

.rank-third {
  background: $background-gradient-bronze;
}

.name {
  margin-top: $spacer-xs;
  z-index: $z-layer-modal;
  position: relative;
}

.score {
  font-weight: $font-weight-bolder;

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
  }
}
