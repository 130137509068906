@import '~styles';

.container {
  position: sticky;
  top: 0;
  margin: 0 $spacer-m 0 0;
  display: flex;
  flex-direction: column;
  gap: $spacer-3xs;

  @include responsiveLargeSidePanel() {
    position: relative;
    flex-direction: row;
  }

  &.menu-expanded {
    @include responsiveSmallSidePanel() {
      position: relative;
      flex-direction: row;
    }
  }
}

.back-button {
  display: none;

  @include responsiveLargeSidePanel() {
    display: block;
  }

  &.menu-expanded {
    @include responsiveSmallSidePanel() {
      display: block;
    }
  }
}
