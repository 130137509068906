@import '~styles';

.error {
  @include textError();

  margin-bottom: $spacer-l;
}

.warning {
  padding: $spacer-xs $spacer-s;
  border-radius: 15px;
  background-color: color(yellow-50);
  color: color(yellow-800);
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  margin: -$spacer-m 0 $spacer-m 0;
}

.field {
  margin-bottom: $spacer-2xl;
}

.action {
  @include responsiveSmall() {
    width: 100%;
  }
}
