@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.category {
  display: flex;
  margin-bottom: $spacer-2xs;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  color: $color-text;
  cursor: pointer;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin-left 200ms ease-in-out;
  margin-left: 0;
  width: 100%;

  &:hover,
  &:focus {
    width: 101%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;
  }
}

.inner {
  display: flex;
  flex-grow: 1;
  padding: $spacer-m $spacer-l;
  border-radius: 10px;
  background-color: $color-light;
  border: $border-transparent;

  @include responsiveSmall() {
    flex-direction: column;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }
}

.category-title {
  border-right: 0.0875rem solid color(gray-600);
  flex: 0 0 auto;
  font-weight: $font-weight-bold;
  margin-right: $spacer-s;
  padding-right: $spacer-s;
  width: 45%;

  @include responsiveSmall() {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
    width: 100%;
  }
}

.category-destinations {
  display: flex;
  flex-wrap: wrap;
}

.category-destination-container {
  border: $border-gray-light;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding-left: $spacer-2xs;
  margin: 0 $spacer-3xs $spacer-3xs 0;
}

.category-destination {
  font-weight: $font-weight-medium;

  img {
    max-width: 100% !important;
  }
}

.remove {
  background: none;
  border: none;
  flex: 0 0 auto;
  padding: $spacer-2xs $spacer-s;

  &:focus,
  &:hover {
    outline: none;
  }
}

.isDisabled {
  background: color(gray-50);
  cursor: not-allowed;

  &:hover,
  &:focus {
    background: color(gray-50);
  }
}

.modal {
  width: 960px;
  max-width: 98%;
  padding: $spacer-3xl;
  margin: auto;
  display: flex;
  flex-direction: column;
  border-radius: 35px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  text-align: center;

  @include responsiveSmall() {
    padding: $spacer-m;
  }
}

.close {
  align-self: flex-end;
  margin-bottom: $spacer-xs;
}

.modal-title {
  margin-bottom: $spacer-2xl;
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.isSelected {
  background: $background-gradient-primary;
  border: $border-light;
  color: $color-light;
  font-weight: $font-weight-bold;
}

.isSelectedForOtherCourse {
  background: color(gray-100);
  opacity: 0.5;
}

.validate-button {
  margin: $spacer-m auto 0 auto;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .isSelected {
      @include buttonTheme($color);

      border: $border-light;
    }

    .validate-button {
      @include buttonTheme($color);
    }
  }
}
