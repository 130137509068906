@import '~styles';

.container {
  background-color: $color-light;
  border-radius: 15px;
  padding: $spacer-l $spacer-m 0 $spacer-2xl;
  display: flex;
}

.main {
  width: 100%;
}

.top {
  display: flex;
  align-items: center;

  @include responsiveSmall() {
    display: block;
  }
}

.header {
  flex-grow: 1;
  margin-right: $spacer-xs;

  @include responsiveMedium() {
    width: calc(100% - 100px);
    margin-bottom: $spacer-m;
  }

  @include responsiveSmall() {
    width: 100%;
  }
}

.top-action {
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    margin-right: 0;
    margin-bottom: $spacer-xs;
  }
}

.moodle {
  width: 1.5rem;
}

.share {
  @include responsiveSmall() {
    width: 100%;
  }
}

.scroll {
  display: flex;
  align-items: center;
}

.tabs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin-right: $spacer-s;
}

.tab {
  display: inline-block;
  padding: $spacer-m 0 $spacer-m 0;
  margin-right: 40px;
  background: none;
  border: none;
  color: $color-text-light;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  border-bottom: 3px solid transparent;
  flex: 0 0  auto;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
    text-decoration: $decoration-none;
  }

  &.isActive {
    color: $color-text;
    border-color: color(blue-300);
    font-weight: $font-weight-bolder;
  }

  &:focus-visible {
    box-shadow: $shadow-focus inset;
    border-radius: 10px;
  }
}

.filler {
  flex-grow: 1;
}

.menu {
  flex: 0 0 auto;
}
