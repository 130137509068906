@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
  border: $border-transparent;
  border-radius: 10px;

  &:focus-visible {
    border: $border-dark;
  }
}

.title {
  display: flex;
  justify-content: flex-start;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  margin-right: $spacer-xs;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.input-builder-container {
  justify-content: space-between;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.field {
  background: $color-light;
  border: $border-gray-light;
  border-radius: 22px;
  flex-grow: 1;

  &:focus-within {
    border-color: $color-text;
  }
}

.field :global(.fr-box.fr-basic .fr-wrapper) {
  background: none;
  border: none;
  cursor: text;
}

.field :global(.fr-box.fr-basic .fr-element) {
  padding: $spacer-xs $spacer-s;
  min-height: 0;
  font-weight: $font-weight-medium;
  color: $color-text !important;
  font-family: inherit;
}

.field :global(.fr-toolbar.fr-top),
.field :global(.fr-toolbar.fr-bottom) {
  position: absolute;
  top: calc(100% + 10px);
  left: -1px;
  right: -1px;
  bottom: unset;
  background: $color-light;
  border-radius: 10px;
  z-index: $z-layer-mid-level;
  border: $border-thin-dark;
}

.cefield {
  @include textBoldDark();

  display: block;
  width: 100%;
  padding: $spacer-xs $spacer-l;
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;
  cursor: text;

  &:focus,
  &:focus-within {
    border-color: $color-text;
  }

  &::placeholder {
    color: color(gray-700);
  }
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
  margin-top: $spacer-xs;
}

.hasError .field {
  border-color: $color-wrong;
}

:global(.fr-toolbar) {
  border: none;
}

:global(.fr-toolbar .fr-command.fr-btn svg path) {
  fill: $color-text;
}

:global(.fr-toolbar .fr-command.fr-btn) {
  color: $color-text;
}

:global(.fr-toolbar .fr-command.fr-btn.fr-dropdown::after) {
  border-top: 4px solid $color-text;
}

:global(.fr-dropdown-menu) {
  bottom: 55px !important;
  transition: none !important;
}

:global(.fr-expanded) {
  border-radius: 20px 20px 0 0 !important;
}

:global(.fr-class-title) {
  color: $color-text;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
}

:global(.fr-class-subtitle) {
  @include textBoldDark();
}

:global(.fr-class-text) {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

:global(.fr-class-horizontal-center) {
  text-align: center;
  margin: auto;
}

:global(.fr-class-columns) {
  display: flex;
  gap: $spacer-2xl;

  @include responsiveSmall() {
    flex-direction: column;
    align-items: center;
  }
}

:global(.fr-class-column-50) {
  width: 50%;
  position: relative;

  @include responsiveSmall() {
    width: 100%;
  }
}

:global(.fr-class-column-33) {
  width: 33%;
  position: relative;

  @include responsiveSmall() {
    width: 100%;
  }
}

:global(.fr-class-number) {
  width: 30px;
  height: 30px;
  min-width: 30px;
  line-height: 1.875rem;
  background-color: $color-primary;
  border-radius: 30px;
  font-weight: $font-weight-bolder;
  color: $color-light;
  font-size: $font-size-s;
}

:global(.fr-class-flex) {
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.fr-class-vertical-center) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

:global(.fr-class-hidden) {
  visibility: hidden;
}

:global(.fr-more-toolbar) {
  background-color: $color-light !important;
}

:global(.fr-popup.fr-desktop.fr-active) {
  z-index: $z-layer-tooltip !important;
}
