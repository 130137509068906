@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.item {
  margin-bottom: $spacer-2xs;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  color: $color-text;
}

.answer {
  align-items: center;
  display: flex;
}

.correct {
  color: darken($color-correct, 20) !important;
}

.wrong {
  color: $color-wrong !important;
}

.icon {
  flex: 0 0 auto;
  font-size: $font-size-3xs;
  width: 1.5rem;
}

.text {
  flex-grow: 1;
}

.aria-label {
  visibility: hidden;
  width: 0;
  height: 0;
}
