@import '~styles';

.container {
  padding-top: $spacer-2xl;
}

.choices {
  margin: 0 auto $spacer-m auto;
  display: flex;
  width: 500px;
  max-width: 100%;
  border: $border-thick-light;
  box-shadow: $shadow-purple;
  border-radius: 100px;
  background: #f6f7fa;
}

.choice {
  @include textBolderDark();

  display: flex;
  align-items: center;
  justify-content: center;
  margin: $spacer-3xs;
  flex: 1;
  height: 40px;
  background: none;
  border: $border-light;
  border-radius: 100px;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus {
    border-color: $color-text;
  }

  &.isActive {
    background: #ffffff;
  }
}

.header {
  margin-bottom: $spacer-l;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsiveMedium() {
    flex-direction: column;
    align-items: flex-start;
  }
}

.lms {
  position: absolute;
  right: $spacer-2xl;
  margin-top: -$spacer-s;
}

.title {
  @include textOverflow();

  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  max-width: 100%;

  @include responsiveMedium() {
    margin-bottom: $spacer-xs;
  }
}

.filler {
  flex-grow: 1;
}

.select-container {
  border-radius: 20px;
  background-color: $color-light;
  padding-right: $spacer-m;

  @include responsiveMedium() {
    margin-bottom: $spacer-xs;
    width: 100%;
  }
}

.select {
  @include textBolderDark();

  padding: $spacer-xs $spacer-l;
  border: none;
  border-radius: 20px;
  background-color: $color-light;

  &:focus {
    border-color: $color-text;
  }

  &::placeholder {
    color: color(gray-700);
  }

  @include responsiveMedium() {
    margin-left: 0;
    width: 100%;
  }
}

@include responsiveMedium() {
  .flex-1 {
    order: 1;
  }

  .flex-2 {
    order: 2;
  }

  .flex-3 {
    order: 3;
  }
}

.menu-student,
.menu-group {
  @include responsiveMedium() {
    width: 100%;
  }
}

.input-student-container,
.input-group-container {
  border: none;
  border-radius: 30px;
  background-color: $color-light;
  padding: $spacer-xs $spacer-l;
  box-shadow: $shadow-purple;
  display: flex;
  align-items: center;

  @include responsiveMedium() {
    width: 100%;
  }
}

.input-student,
.input-group {
  margin-right: $spacer-xs;
  border: none;

  @include responsiveMedium() {
    flex-grow: 1;
  }
}

.icon-input-student,
.icon-input-group {
  font-size: $font-size-2xs;
  font-weight: $font-weight-medium;
}

.report-button {
  margin-left: $spacer-xs;

  @include responsiveMedium() {
    margin-left: 0;
  }
}

.course,
.matrix,
.chapters,
.tags,
.questions,
.students,
.study-deadline {
  margin-bottom: $spacer-s;

  @include responsiveSmall() {
    margin-bottom: $spacer-m;
  }
}
