@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.image-legends-container {
  display: flex;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.image {
  width: 50%;
  margin-right: $spacer-m;

  @include responsiveSmall() {
    width: 100%;
    margin-right: 0;
    margin-bottom: $spacer-xs;
  }
}

.legends {
  flex-grow: 1;
}

.legend {
  margin-bottom: $spacer-2xs;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  color: $color-text;
}

.line {
  display: flex;
  align-items: center;
}

.legend-index {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: $border-light;
  background: $background-gradient-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bolder;
  color: $color-light;
  flex: 0 0 auto;
}

.report {
  flex-grow: 1;
  margin-left: $spacer-m;
}

.solution {
  align-items: center;
  display: flex;
}

.text {
  flex-grow: 1;
}

.right {
  color: $color-success;
  font-weight: $font-weight-bold;
}

.wrong {
  font-weight: $font-weight-bold;
  color: $color-wrong;
}

.wrong-answers {
  margin-left: $spacer-2xl;
  display: flex;
  align-items: center;
}

.tooltip {
  color: $color-text-light;
  font-size: $font-size-xs;
  margin-right: $spacer-xs;
}

.errors {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.error {
  @include textError();

  display: flex;
  align-items: center;
  margin: 0 $spacer-xs 0 0;
}

.error-title {
  margin-right: $spacer-3xs;
}

.error-occurrence {
  background-color: $color-light-gray;
  border-radius: 20px;
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  padding: 0 $spacer-xs;
  margin-right: $spacer-xs;
}
