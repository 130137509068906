@import '~components/Shared/Styles/StyledModal.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -$spacer-m 0 0 0;
}

.title {
  margin-bottom: 0 !important;
  text-align: left;
}

.dropzone {
  border-radius: 1rem;
  margin: $spacer-s 0 $spacer-m 0;
  transition: opacity 0.25s ease-in-out;

  label {
    border: $border-dashed-black-light;
    border-radius: 1rem;
    cursor: pointer;
    display: block;
    padding: $spacer-l;
    text-align: center;
    width: 100%;
  }

  input {
    @include hide();

    &:focus-visible ~ .input-content {
      border: $border-dark;
      border-radius: 10px;
    }
  }

  .input-content {
    border: $border-transparent;
  }

  .icon {
    font-size: $font-size-l;
    margin-bottom: $spacer-xs;
  }

  &.isDroppable {
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    label {
      border: $border-dashed-dark;
    }
  }
}

.preview {
  height: 105px;
  width: 105px;
  background-color: $color-primary-light;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto $spacer-xs auto;
  position: relative;
  object-fit: cover;
}

.file-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-name {
  font-size: $font-size-2xs;
  font-weight: $font-weight-bold;
}

.plus-container {
  position: absolute;
  margin-top: -53px;
  height: 20px;
  width: 20px;
  background: $background-gradient-primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-icon {
  font-weight: $font-weight-bolder;
  color: $color-light;
}

.preview-icon {
  color: $color-light;
  font-size: $font-size-3xl;
}

.preview-text {
  text-align: center;
}

.or {
  height: 2px;
  margin: $spacer-m 0 $spacer-l 0;
  background: $color-light-gray;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.or span {
  @include textBolderDark();

  background: $color-light;
  padding: 0 $spacer-xs;
  position: relative;
  top: -$spacer-xs;
}

.link-container {
  display: flex;
  align-items: center;
  margin-top: $spacer-xs;
  padding-bottom: $spacer-xs;
}

.https {
  color: $color-text;
  margin-right: $spacer-xs;
}

.link-preview {
  height: 54px;
  width: 54px;
  border-radius: 5px;
  margin-right: $spacer-xs;
  object-fit: cover;
}

.link-reset-button {
  margin-left: $spacer-xs;
}

.transparent {
  opacity: 0.6;
}

.footer {
  margin: $spacer-l 0 (-$spacer-2xl) 0;
  padding-top: $spacer-m;
  border-top: 2px solid $color-light-gray;
}

.modal {
  @include styledModal();

  @include responsiveSmall() {
    padding: 40px 40px 50px 40px;
  }
}
