@import '~styles';
@import '~components/Shared/Styles/StyledSubPage.module.scss';

.subpage {
  @include styledSubPage();
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-2xl;
}

.unsupported {
  text-align: center;
  color: $color-dark-purple;
  font-size: $font-size-xs;
  font-weight: bold;
  border-radius: 10px;
  background-color: $color-primary-light;
  padding: $spacer-xs 37px;
}

.empty {
  color: $color-dark-yellow;
  background-color: $color-light-yellow;

}
