@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.choice {
  border: none;
  display: flex;
  align-items: center;
  margin-bottom: $spacer-2xs;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 15px;
  color: $color-text;
  cursor: pointer;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin-left 200ms ease-in-out;
  width: 100%;
  margin-left: 0;

  &:hover,
  &:focus {
    width: 101%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }
}

.icon {
  color: $color-light-black;
  flex: 0 0 auto;
  font-size: $font-size-l;
  margin-right: $spacer-l;
  line-height: 15px;
  height: 15px;
}

.choice-title {
  flex-grow: 1;
}

.isDisabled {
  background: color(gray-50);
  cursor: not-allowed;

  &:hover,
  &:focus {
    background: color(gray-50);
  }
}

.isSelected {
  background: $background-gradient-primary-vertical;

  .icon {
    color: $color-dark-purple-2;
    background-color: $color-light;
  }

  .choice-title,
  a {
    color: $color-light;
  }

  .choice-title {
    font-weight: $font-weight-bold;
  }
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .isSelected {
      background: color-pastel($color);
    }

    .choice-title {
      color: $color-text;
    }
  }
}
