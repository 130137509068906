@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.answer {
  background: $color-light;
  border-radius: 10px;
  font-weight: $font-weight-medium;
  padding: $spacer-s;
  margin-bottom: $spacer-xs;
  color: $color-text-light;
  box-shadow: $shadow-light;
}

.isAdded {
  background: $color-dark-green;
}

.isRemoved {
  color: $color-light;
  background: $color-dark-red;
}
