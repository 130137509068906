@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.answer {
  font-size: 110%;
}

.space {
  margin-bottom: $spacer-s;
}
