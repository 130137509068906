@import '~styles';

.container {
  width: 140px;
  border-radius: 15px;
  padding: $spacer-xs;
  background-color: $color-light-black;
  border: $border-transparent;
  transition: box-shadow 250ms ease-in-out, margin 250ms ease-in-out;
  margin: 0;
  position: relative;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: $color-text;
  }
}

.new {
  background: $color-secondary;
  font-size: $font-size-xs;
  border-radius: 5px;
  font-weight: $font-weight-bolder;
  padding: $spacer-3xs;
  color: $color-light;
  top: $spacer-3xs;
  left: $spacer-3xs;
  position: absolute;
  opacity: 0.6;
  transition: opacity 250ms ease-in-out;
}

.picture-container {
  width: 90%;
  height: 60px;
  margin: auto;
  border-radius: 10px;
  opacity: 0.7;
  transition: opacity 250ms ease-in-out;
}

.picture {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  object-fit: contain;
}

.title {
  @include textOverflow();

  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  word-wrap: normal;
  margin-top: $spacer-xs;
  transition: color 250ms ease-in-out;
}

.highlighted {
  border: $border-primary;

  .title {
    color: $color-primary;
  }

  .picture-container,
  .new {
    opacity: 1;
  }
}
