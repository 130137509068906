@import '../../Styles/OnboardingSubPage.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-image: url('./images/bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-dark-purple-2;
  color: $color-light;

  @include responsiveMedium() {
    background-position: center center;
  }
}

.content {
  text-align: center;
  margin: auto;
}

.title {
  font-size: $font-size-2xl;
  font-weight: $font-weight-bolder;
  width: 340px;
  margin: 0 auto;
}

.subtitle {
  font-size: $font-size-m;
  font-weight: $font-weight-medium;
  line-height: 25px;
  margin: $spacer-l auto $spacer-2xl auto;
}

.action {
  width: 330px;
  box-shadow: none;
}
