@import '~styles';

.overlay {
  bottom: 0;
  left: 250px;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-layer-low-level;

  @include responsiveSmall() {
    left: 0;
  }
}

:global(.ReactModal__Overlay) {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
}

:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
}

.modal {
  background-color: $color-background;
  bottom: 0;
  right: 0;
  top: 0;
  left: 250px;
  overflow: auto;
  position: fixed;

  @include responsiveSmall() {
    left: 0;
  }
}

.collapsed-menu {
  left: 55px;

  @include responsiveSmall() {
    left: 0;
  }
}

.teams-view {
  left: 0;
  z-index: $z-layer-header;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $spacer-2xl $spacer-3xl;
  border-bottom: 1px solid $color-light-gray;

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}

.no-header {
  display: none;
}

.title {
  margin-left: $spacer-s;
  margin-bottom: $spacer-3xs;
  color: $color-text;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
}

.subtitle {
  margin-left: $spacer-s;
  color: $color-text-light;
  font-size: $font-size-xs;
  line-height: 1.188rem;
  font-weight: $font-weight-bold;
}

.header-size-default {
  border-bottom: none;
  padding: $spacer-l $spacer-3xl 0 $spacer-3xl;

  .title {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
  }

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}

.header-size-small {
  border-bottom: none;

  .title {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
  }
}

.side-panel-overlay {
  left: 0;
  background-color: rgba($color: $color-dark-blue-3, $alpha: 0.5);
  z-index: $z-layer-header;
}

.side-panel {
  left: 500px;
  background-color: $color-light;

  @include responsiveSmallSidePanel() {
    left: 0;
  }

  &.large-side-panel {
    left: 300px;

    @include responsiveLargeSidePanel() {
      left: 0;
    }
  }
}

:global(.ReactModal__Content) {
  &:global(.SubPage_side-panel__RT3WU) {
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
  }
}

:global(.ReactModal__Content--after-open) {
  &:global(.SubPage_side-panel__RT3WU) {
    transform: translateX(0);
  }
}

:global(.ReactModal__Content--before-close) {
  &:global(.SubPage_side-panel__RT3WU) {
    transform: translateX(100%);
  }
}
