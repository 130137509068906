@import '~styles';

@mixin styledSubPage() {
  .header {
    display: grid;
    grid-template: 'back dots language' auto / 20% 1fr 20%;
    align-items: center;
    padding: $spacer-2xl $spacer-2xl 0 $spacer-2xl;
    gap: $spacer-xs;
    width: 100%;

    @include responsiveMedium() {
      padding: $spacer-xs $spacer-2xl 0 $spacer-2xl;
    }
  }

  .back {
    grid-area: back;
    width: fit-content;
  }

  .points {
    grid-area: dots;
    display: flex;
    justify-content: center;
  }

  .point {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background: $color-light-purple-3;
    margin-left: $spacer-2xs;
  }

  .language {
    grid-area: language;
    display: flex;
    justify-content: flex-end;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: $color-light;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    margin-top: -$spacer-3xl;

    @include responsiveMedium() {
      padding: $spacer-xs $spacer-2xl $spacer-2xl $spacer-2xl;
    }

    @include responsiveSmall() {
      margin-top: 0;
      justify-content: flex-start;
    }
  }

  .illustration {
    max-height: 350px;
    margin: $spacer-3xl auto 0 auto;

    @include responsiveSmall() {
      width: 100%;
      margin: auto;
    }
  }

  .title {
    margin-bottom: 50px;
    color: $color-text;
    font-size: $font-size-l;
    font-weight: $font-weight-bolder;
    text-align: center;

    @include responsiveSmall() {
      font-size: $font-size-m;
      margin-bottom: $spacer-l;
    }
  }

  .subtitle {
    @include textNormalDark();

    text-align: center;
  }

  .select-buttons {
    display: flex;
    justify-content: center;
    gap: $spacer-2xl;

    @include responsiveSmall() {
      flex-direction: column;
      gap: $spacer-s;
    }
  }

  .select-button {
    border-radius: 30px;
    border: none;
    width: 330px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-bold;
    gap: $spacer-2xl;
    color: $color-text;

    &:hover,
    &:focus {
      border: $border-dark;
      outline: none;
    }

    @include responsiveSmall() {
      width: 100%;
      height: 150px;
      gap: $spacer-s;
    }
  }

  .error {
    @include textError();

    margin-bottom: $spacer-l;
  }

  .field {
    margin-bottom: $spacer-xs;
  }

  .action {
    display: flex;
    margin-top: $spacer-xl;
    width: 100%;

    & + .action {
      margin-top: $spacer-xs;
    }
  }
}
