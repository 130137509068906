@import '~styles';

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacer-xs;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.navigation {
  margin-right: $spacer-3xs;

  @include responsiveMedium() {
    margin-bottom: $spacer-xs;
  }
}

.current-page {
  margin-right: $spacer-xs;
}

.select {
  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }
}
