@import '~styles';

.container {
  align-items: center;
  background: color(light);
  border: $border-black-light;
  border-radius: 1rem;
  display: flex;
  padding: $spacer-2xs;
}

.icon {
  align-items: center;
  color: color(red-300);
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: center;
  width: 32px;

  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.title {
  @include textOverflow();

  flex-grow: 1;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  margin: 0 $spacer-xs;
  max-height: 1.5rem;
  text-align: left;
  width: 0;
}

.check {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:hover ~ .checkbox,
  &:focus ~ .checkbox {
    border-color: $color-dark-purple;
  }
}

.checkbox {
  height: 25px;
  width: 25px;
  margin: 0 $spacer-xs;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-light;
  border: $border-gray-light;
  border-radius: 5px;
  color: $color-light;
  cursor: pointer;
  font-size: $font-size-3xs;

  &.isChecked {
    background: $background-gradient-primary;
    color: $color-light;

    &:hover,
    &:focus {
      background: $background-gradient-primary;
      border-color: $color-text;
    }
  }
}
