@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.answer {
  font-size: 110%;
}

.correct {
  color: darken($color-correct, 20);
  font-weight: $font-weight-bold;
}

.wrong {
  color: $color-wrong;
  font-weight: $font-weight-bold;
  margin-bottom: $spacer-s;
}
