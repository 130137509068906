@import '~components/Shared/Styles/StyledModal.module.scss';

.modal {
  @include styledModal();
}

.button-container {
  position: relative;
  margin: auto;
}

.button {
  width: fit-content;
  margin: auto;
}

.count {
  position: absolute;
  right: -$spacer-3xs;
  top: -$spacer-3xs;
  background-color: $color-secondary;
  font-size: $font-size-2xs;
  border-radius: 50px;
  padding: $spacer-3xs $spacer-2xs;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  color: $color-text;
  font-weight: $font-weight-bolder;
}

.feedback {
  text-align: left;
  margin-bottom: $spacer-m;
  padding: $spacer-xs;
  border: $border-gray-light;
  border-radius: 20px;
}
