@import '~styles';

.droppable {
  background: transparent;
  border-radius: 1rem;
  transition: background 250ms ease-in-out;
}

.droppable.isDraggingOver {
  background: color(gray-200);
}
