@import '~styles';

:global(.Toastify__toast-container) {
  position: fixed;
  right: $spacer-2xl;
  top: $spacer-2xl;
  width: 345px;
  z-index: $z-layer-high-order;

  @include responsiveSmall() {
    left: $spacer-xs;
    right: $spacer-xs;
    top: $spacer-xs;
    width: auto;
  }
}

:global(.Toastify__toast) {
  margin-bottom: $spacer-xs;
  position: relative;
  animation-fill-mode: both;
  animation-duration: 0.7s;

  @include responsiveSmall() {
    margin-bottom: $spacer-3xs;
  }
}

.close {
  position: absolute;
  top: $spacer-s;
  right: $spacer-s;
  background: none;
  border: none;
  color: $color-text-light;

  &:hover,
  &:focus {
    outline: none;
  }
}

.container {
  border-radius: 11px;
  background-color: $color-light;
  box-shadow: $shadow-blue;
  padding: $spacer-l;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: $color-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-text;
  flex: 0 0 auto;
  margin-right: $spacer-m;
}

.type-error .icon {
  background-color: color(red-300);
  color: $color-light;
}

.ia-icon {
  background-image: url('./images/wizard.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 60px;
  width: 60px;
  min-width: 60px;
  height: 60px;
}

.message {
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  color: $color-text;
}

:global(.Toastify__slide-enter--top-right) {
  animation-name: slideInRight;

  @keyframes slideInRight {
    from {
      transform: translate3d(110%, 0, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }
}

:global(.Toastify__slide-exit--top-right) {
  animation-name: slideOutRight;

  @keyframes slideOutRight {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      visibility: hidden;
      transform: translate3d(110%, 0, 0);
    }
  }
}

:global(.Toastify__progress-bar--animated) {
  animation: trackProgress linear 1 forwards;

  @keyframes trackProgress {
    0% {
      transform: scaleX(1);
    }

    100% {
      transform: scaleX(0);
    }
  }
}
