@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.text {
  margin-top: 50px;
  line-height: 2.2rem;
  white-space: pre-line;
}

.chevron {
  font-size: $font-size-2xs;
  margin: 0 $spacer-3xs;
}

.answer {
  padding: $spacer-3xs $spacer-l;
  background-color: $color-light;
  border-radius: 15px;
  font-weight: $font-weight-bold;
}

.correct {
  border: $border-thick-green-pastel;
  background-color: $color-light-green-2;
}

.wrong {
  border: $border-thick-red-dark;
  background-color: $color-light-red-2;
}

.aria-label {
  visibility: hidden;
  width: 0;
  height: 0;
}
