@import '~styles';

.social {
  display: flex;
  justify-content: center;
  list-style: none;
}

.social-button {
  font-size: $font-size-m;
  margin: 0 $spacer-3xs;
}

.teams,
.classroom,
.whatsapp {
  width: 22px;
}

.facebook {
  color: $color-facebook !important;
}

.twitter {
  color: $color-twitter !important;
}

.linkedin {
  color: $color-linkedin !important;
}

.messenger {
  color: $color-messenger !important;
}
