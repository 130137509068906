@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.input {
  @include textBoldDark();

  display: block;
  width: 10rem;
  padding: $spacer-xs $spacer-l;
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;
  height: 44px;
  min-width: 1rem;
  -moz-appearance: textfield;
  text-align: center;

  &:focus {
    border-color: $color-text;
  }

  &::placeholder {
    color: color(gray-700);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled .input,
.isDisabled .textarea-container {
  background: color(gray-200);
  cursor: not-allowed;
  color: color(gray-700);
}

.hasError .input,
.hasError .textarea-container {
  border-color: $color-wrong;
}
