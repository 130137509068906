@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.experts {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: $spacer-2xs;

  @include responsiveMedium() {
    flex-direction: column;
    align-items: center;
  }
}

.label-container {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-xs;
}

.subtitle {
  margin-bottom: 0 !important;
}


