@import '~components/Shared/Styles/StyledModal.module.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: $spacer-m;
}

.open-modal-button {
  margin: auto;
}

.open-modal-button i {
  @include textGradient($color-dark-purple, color(blue-300));

  font-size: $font-size-m;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -$spacer-m 0 0 0;
}

.title {
  margin-bottom: 0 !important;
  text-align: left;
}

.dropzone {
  border-radius: 1rem;
  padding: $spacer-s 0 0 0;
  transition: opacity 0.25s ease-in-out;

  label {
    border: $border-dashed-black-light;
    border-radius: 1rem;
    cursor: pointer;
    display: block;
    padding: $spacer-l;
    text-align: center;
    width: 100%;
  }

  input {
    @include hide();

    &:focus-visible ~ .input-content {
      border: $border-dark;
      border-radius: 10px;
    }
  }

  .icon {
    font-size: $font-size-l;
    margin-bottom: $spacer-xs;
  }

  &.isDroppable {
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    label {
      border: $border-dashed-dark;
    }
  }
}

.input-content {
  border: $border-transparent;
}

.import-icon {
  height: 105px;
  width: 105px;
  background-color: $color-primary-light;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto $spacer-xs auto;
  position: relative;
  object-fit: cover;
}

.preview {
  margin: auto;
}

.file-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacer-s;
}

.file-name {
  font-size: $font-size-2xs;
  font-weight: $font-weight-bold;
  margin-right: $spacer-xs;
}

.plus-container {
  position: absolute;
  margin-top: -50px;
  height: 20px;
  width: 20px;
  background: $background-gradient-primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-icon {
  font-weight: $font-weight-bolder;
  color: $color-light;
}

.preview-icon {
  color: $color-primary-light;
  background-color: $color-light;
  border-radius: 5px;
  font-size: $font-size-l;
  padding: $spacer-xs;
}

.preview-text {
  text-align: center;
}

.select-button {
  margin-bottom: $spacer-s;
}

.modal {
  @include styledModal();

  @include responsiveSmall() {
    padding: 40px 40px 50px 40px;
  }
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .open-modal-button i {
      @include textGradient(color-dark($color), color-dark($color));
    }

    .import-icon {
      background-color: color-light($color);
    }

    .plus-container {
      background: color-dark($color);
    }

    .preview-icon {
      color: color-light($color);
    }

    .select-button {
      background: color-pastel($color);

      &:focus,
      &:hover {
        background: color-pastel($color);
      }
    }
  }
}
