@import '~styles';

.switch {
  padding-bottom: $spacer-m;
  margin-bottom: $spacer-xl;
  border-bottom: 2px solid $color-light-gray;
}

.property {
  margin-bottom: $spacer-m;
}

.label {
  text-align: left;
  font-size: $font-size-xs;
  margin-bottom: $spacer-xs;
}

.classes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: $spacer-l $spacer-l $spacer-l $spacer-l $spacer-l $spacer-l;
  grid-auto-flow: column;
  gap: $spacer-xs;
  margin-bottom: $spacer-m;
}

.higher {
  grid-template-rows: $spacer-l $spacer-l;
}
