@import '~styles';

.container {
  border: $border-light;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  transition: border-color 250ms ease-in-out;
}

.group {
  align-items: center;
  display: flex;
  padding: 0 $spacer-l;
}

.icon {
  @include textGradient($color-dark-purple, color(blue-300));

  align-items: center;
  border: none;
  display: flex;
  flex: 0 0 auto;
  font-size: $font-size-xs;
  height: 30px;
  justify-content: center;
  transition: color 250ms ease-in-out;
  width: 30px;
  margin-right: $spacer-3xs;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 50%;
  }
}

.group-icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 32px;
  justify-content: center;
  width: 32px;
  margin-right: $spacer-m;
  border-radius: 5px;
  background: #fffaf0;
  color: #ffd392;
  font-size: $font-size-xs;
}

.title {
  @include textOverflow();
  @include textBolderDark();

  flex-grow: 1;
  padding: $spacer-s $spacer-xs $spacer-s 0;
  text-align: left;

  &:hover,
  &:focus {
    outline: none;
  }
}

.action {
  flex: 0 0 auto;
  margin-left: $spacer-xs;
}

.students {
  border-radius: 10px;
  min-height: 3rem;
  transition: background 250ms ease-in-out;
  margin: $spacer-3xs $spacer-xs;
}

.student {
  margin-bottom: $spacer-xs;
}

.students.isDraggingOver {
  background: color(gray-200);
}

.container.isDraggingOver {
  background: color(gray-100);
  border-color: color(purple-400);
}
