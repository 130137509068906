@import '~styles';

.container {
  padding-top: $spacer-2xl;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-s;

  @include responsiveSmall() {
    flex-wrap: wrap;
  }
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;

  @include responsiveSmall() {
    margin-bottom: $spacer-xs;
  }
}

.count {
  padding: $spacer-3xs $spacer-xs;
  margin-left: $spacer-xs;
  border-radius: 15px;
  background-color: $color-primary-light;
  color: $color-dark-purple;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}

.game {
  margin-bottom: $spacer-xs;
  width: 50%;

  @include responsiveMedium() {
    width: 100%;
  }
}
