@import '~components/Shared/Styles/StyledModal.module.scss';

.container {
  display: grid;
  grid-template: 'study action' auto / 1fr auto;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 0 $spacer-s 0 0;
  border: none;
  font-size: $font-size-2xs;
  border-radius: 50px;
  border: $border-transparent;
  background-color: $color-text;
  box-shadow: $shadow-dark;
  text-align: left;
  transition: box-shadow 100ms ease-in-out;
  color: $color-text;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: $shadow-dark;
  }

  &:focus-within {
    border: $border-light;
  }
}

.button {
  grid-area: study;
  border: none;
  background-color: transparent;
  font-weight: $font-weight-bolder;
  font-size: $font-size-m;
  color: $color-light;
  border-radius: 50px 0 0 50px;
  height: 80px;
  padding: 0 $spacer-2xl;

  &:hover,
  &:focus {
    outline: none;
  }

  @include responsiveSmall() {
    padding: 0 $spacer-s;
  }
}

.action {
  grid-area: action;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  font-size: $font-size-xs;
  background-color: $color-light-black;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    background-color: $color-light-gray;
  }

  @include responsiveMedium() {
    margin-left: $spacer-xs;
  }
}

.icon {
  color: $color-text;
  padding: $spacer-3xs 0;
}

.options {
  padding: $spacer-m;
  background-color: $color-light;
  border-radius: 15px;
  width: max-content;

  @include responsiveSmall() {
    max-width: 90vw;
  }
}

.method {
  text-align: left;
  margin-bottom: $spacer-s;
  color: $color-text-light;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.switch {
  margin-bottom: $spacer-xs;
}

.information {
  max-width: 400px;
  font-size: $font-size-2xs;
  text-align: left;
}

.editionView {
  box-shadow: none;
  border: $border-gray-light;
  width: auto;
  height: 40px;
  font-size: $font-size-xs;
  padding: 0 $spacer-3xs 0 0;
  background-color: $color-light-black;

  &:hover,
  &:focus {
    background-color: $color-light-black;
    border: $border-transparent;
    color: $color-text;
    box-shadow: none;
  }

  &:focus-within {
    border: $border-dark;
  }

  .button {
    color: $color-text;
    font-size: $font-size-s;
    padding: 0 $spacer-m;
    height: 36px;
  }

  .action {
    width: 30px;
    height: 30px;

    &:focus-visible {
      background-color: $color-light;
      border: $border-dark;
    }
  }
}

.highlight {
  background: $background-gradient-primary;
  border: none;

  &:hover,
  &:focus {
    background: $background-gradient-primary-hover;
    border: none;
  }

  .button {
    color: $color-light;
  }
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-study {
  margin-right: $spacer-xs;
}
