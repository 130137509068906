@import '~styles';

.header {
  display: flex;
  align-items: center;
  margin-bottom: 44px;
  position: relative;

  @include responsiveMedium() {
    flex-wrap: wrap;
    margin-bottom: $spacer-m;
  }

  @include responsiveSmall() {
    padding: $spacer-m;
  }
}

.reset {
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    order: 3;
    margin-left: $spacer-xs;
  }
}

.title {
  color: $color-text;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
  flex-grow: 1;

  @include responsiveMedium() {
    margin-bottom: $spacer-m;
  }

  @include responsiveSmall() {
    width: 100%;
    margin-bottom: $spacer-xs;
  }
}

.options {
  display: flex;
  align-items: center;
  width: 550px;

  @include responsiveMedium() {
    width: 100%;
  }

  @include responsiveSmall() {
    width: 100%;
    flex-wrap: wrap;
  }
}

.search {
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    margin-right: 0;
    margin-bottom: $spacer-xs;
  }
}

.filter-toggle {
  border-radius: 5px;

  &:hover,
  &:focus {
    background-color: $color-light;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;

  @include responsiveMedium() {
    flex-direction: column;
    margin-bottom: $spacer-m;
  }
}

.filter {
  max-width: 23%;

  @include responsiveMedium() {
    max-width: 100%;
    margin-bottom: $spacer-xs;
  }

  :global(.Select_label__12fdl),
  :global(.InputTextArray_label__33rIK) {
    font-weight: $font-weight-bolder;
  }
}

.checkbox {
  margin-bottom: 0;
}

.categories {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
}
