@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.image-legends-container {
  display: flex;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.image {
  width: 60%;
  margin-right: $spacer-m;

  @include responsiveMedium() {
    width: 98%;
    margin: auto;
    margin-bottom: $spacer-xs;
  }
}

.legends {
  flex-grow: 1;

  &.scroll {
    @include scrollbarPrimary();

    background-color: $color-light;
    border-radius: 20px;
    flex: 1 1 auto;
    height: fit-content;
    max-height: 500px;
    padding: $spacer-m;
    margin-bottom: $spacer-xs;
  }
}

.legend {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-xs;
}

.legend-index {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: $background-gradient-primary;
  border: $border-light;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bolder;
  color: white;
  flex: 0 0 auto;
}

.legend-input {
  flex-grow: 1;
  margin-left: $spacer-xs;
}

.answeredLegends {
  border-top: 2px solid $color-light-gray;
  padding-top: $spacer-xs;
}

.answeredLegend-index {
  background: $color-light-green-2 !important;
  border: $border-green-pastel;
  color: $color-text;
}

.answeredLegend-answer {
  background: $color-light-green-2;
  border: $border-green-pastel;
  border-radius: 40px;
  padding: $spacer-xs $spacer-m;
  width: 100%;
  color: $color-text;
  font-weight: $font-weight-medium;
  margin-left: $spacer-xs;
  flex-grow: 1;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    .legend-index {
      @include buttonTheme($color);
    }
  }
}
