@import '~styles';
@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.empty {
  text-align: center;
  font-size: $font-size-xs;
  font-weight: bold;
  border-radius: 10px;
  color: $color-dark-yellow;
  background-color: $color-light-yellow;
  padding: $spacer-xs 37px;
  margin-top: -$spacer-2xl;
}

.unsupported {
  color: $color-dark-purple;
  background-color: $color-primary-light;
}
