@import '~styles';

.container {
  width: 500px;
  max-width: 98%;
  padding: $spacer-3xl;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  border-radius: 35px;
  background-color: $color-light;
  box-shadow: $shadow-blue;

  @include responsiveSmall() {
    padding: $spacer-m;
  }
}

.image {
  display: block;
  width: 200px;
  margin: 0 auto 38px;
}

.title {
  margin-bottom: 37px;
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}
