@import '~styles';

.skip {
  position: fixed;
  background-color: $color-light;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);

  &:focus-visible {
    left: 50%;
    top: $spacer-xs;
    transform: translateX(-50%);
    height: auto;
    width: auto;
    clip: unset;
    color: $color-text;
    text-decoration: $decoration-none;
    border: $border-dark;
    margin-bottom: $spacer-xs;
    border-radius: 10px;
    font-weight: $font-weight-bolder;
    padding: $spacer-3xs;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: $spacer-s;
  background: $color-light;
  box-shadow: $shadow-purple;
  z-index: $z-layer-header;

  @include responsiveSmall() {
    display: none;
    z-index: $z-layer-low-level;
    right: 0;
    width: 100%;

    &.isMenuVisible {
      display: flex;
    }
  }
}

.menu-header {
  padding: 0 0 $spacer-2xl $spacer-s;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: $spacer-3xs;

  @include responsiveSmall() {
    display: grid;
    grid-template-columns: $spacer-m 1fr $spacer-m;
    gap: 0;
  }
}

.back {
  display: none;
  margin-bottom: $spacer-m;

  @include responsiveSmall() {
    display: block;
  }
}

.logo {
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: $decoration-none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }

  @include responsiveSmall() {
    display: none;
  }
}

.logo-image {
  height: 30px;
  margin-right: $spacer-2xs;
}

.logo-text {
  @include textGradient($color-dark-purple, rgba(192, 168, 255, 1));

  font-family: $font-family-nunito !important;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
}

.collapse-button {
  box-shadow: none;
  border-radius: 10px;
  color: $color-text-light;
  transition: color ease-in-out 250ms, background-color ease-in-out 250ms;

  &:hover,
  &:focus {
    background-color: $color-light-black;
  }

  @include responsiveSmall() {
    display: none;
  }
}

.action-login {
  margin-bottom: $spacer-xs;
  width: 100%;

  i {
    display: none;
  }
}

.action-register {
  margin-bottom: $spacer-s;
  width: 100%;

  i {
    display: none;
  }
}

.separator {
  width: 100%;
  margin-bottom: $spacer-s;
  border-top: 1px solid $color-blue-light;
}

.notifications,
.agenda {
  position: relative;
  width: 100%;

  @include responsiveSmall() {
    &.isMenuVisible {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;
    }
  }
}

.count {
  position: absolute;
  right: $spacer-m;
  top: $spacer-s;
  background-color: $color-secondary;
  font-size: $font-size-2xs;
  border-radius: 50px;
  padding: $spacer-3xs $spacer-2xs;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  color: $color-text;
  font-weight: $font-weight-bolder;
}

.filler {
  flex-grow: 1;
}

.menu-footer {
  display: flex;
  align-items: center;
  width: 100%;
}

.me-menu {
  @include responsiveSmall() {
    display: none;
  }
}

.menu-footer-action {
  padding: $spacer-xs;
}

.logo-small {
  display: none;

  @include responsiveSmall() {
    display: block;
    margin: auto;
  }
}

.main {
  margin-left: 250px;
  min-height: 100vh;
  background: $color-background;

  @include responsiveSmall() {
    margin: 0;
  }

  &.isInTeams {
    margin-left: 0 !important;
  }
}

.warning {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacer-xs;
  padding: $spacer-xs $spacer-m;
  background: $background-gradient-primary-dark;
  color: $color-light;
}

.warning-icon {
  border-radius: 50px;
  padding: $spacer-xs;
  background-color: $color-primary-dark;
}

.header-padding {
  @include responsiveSmall() {
    padding: $spacer-s;
  }
}

.header {
  @include responsiveSmall() {
    background: $color-primary;
    border-radius: 0 0 30px 30px;
    position: relative;
    background-image: url(./images/bg.svg);
    background-repeat: none;
    background-size: cover;
  }
}

.header-top {
  position: relative;
  display: none;
  padding: $spacer-xs $spacer-m;
  border-bottom: 1px solid $color-blue-light;
  background-color: $color-light;

  @include responsiveSmall() {
    display: grid;
    grid-template-columns: $spacer-2xl 1fr $spacer-2xl;
    gap: $spacer-xs;
    grid-template-areas: 'toggle hello me';
    align-items: center;
    padding: 0;
    background-color: transparent;
    border-bottom: none;
  }
}

.header-top-title {
  @include textOverflow();

  grid-area: 'hello';
  justify-self: center;
  max-width: calc(100% - 10px);
}

.header-link {
  @include responsiveSmall() {
    display: none;
  }
}

.header-left {
  display: flex;
  align-items: center;
  gap: $spacer-xs;
}

.header-toggle-container {
  position: relative;
}

.count-toggle {
  font-size: $font-size-3xs;
  top: -$spacer-3xs;
  right: -$spacer-3xs;
}

.header-toggle {
  display: flex;
  padding: 0 $spacer-xs;
  align-items: center;
  height: 40px;
  padding: 0 $spacer-l;
  border: none;
  border-radius: 50px;
  box-shadow: $shadow-light;
  color: $color-text;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus:focus-visible {
    border: $border-thick-dark;
  }
}

.me-header {
  display: none;

  @include responsiveSmall() {
    display: flex;
  }
}

.header-bottom {
  position: relative;

  @include responsiveSmall() {
    padding: 0;
    margin: $spacer-m 0 0 0;
  }
}

.content {
  padding: $spacer-s $spacer-2xl $spacer-2xl $spacer-2xl;

  @include responsiveMedium() {
    padding: $spacer-m;
  }

  @include responsiveSmall() {
    padding: 0 $spacer-xs $spacer-xs $spacer-xs;
  }
}

.collapsed {
  .menu {
    width: 56px;
    padding: $spacer-s $spacer-2xs;

    @include responsiveSmall() {
      width: 100%;
      padding: $spacer-s;
    }

    .menu-header {
      padding: 0 0 $spacer-s 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacer-xs;
      width: 100%;

      @include responsiveSmall() {
        display: grid;
        grid-template-columns: $spacer-m 1fr $spacer-m;
      }
    }

    .logo {
      span {
        display: none;
      }
    }

    .action-login,
    .action-register {
      padding: 0 0 0 $spacer-xs;

      i {
        display: block;
      }

      span {
        display: none;
      }

      @include responsiveSmall() {
        i {
          display: none;
        }

        span {
          display: block;
        }
      }
    }

    .count {
      right: -$spacer-3xs;
      top: $spacer-3xs;
    }

    .menu-footer {
      flex-direction: column-reverse;
    }
  }

  .main {
    margin-left: 56px;

    @include responsiveSmall() {
      margin: 0;
    }
  }
}
