@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
  border: $border-transparent;
  border-radius: 10px;

  &:focus-visible {
    border: $border-dark;
  }
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.field {
  border: $border-gray-light;
  border-radius: 22.5px;
  background-color: $color-light;

  &:focus-within {
    border-color: $color-text;
  }
}

.field :global(.fr-box.fr-basic .fr-wrapper) {
  background: none;
  border: none;
  border-bottom: 2px solid $color-light-gray;
  cursor: text;
}

.field :global(.fr-box.fr-basic .fr-element) {
  padding: $spacer-xs $spacer-s;
  min-height: 0;
  font-family: inherit;
}

.field :global(.fr-toolbar.fr-top) {
  background: color(light);
  border: none;
  border-bottom: 2px solid $color-light-gray;
  border-radius: 22.5px 22.5px 0 0;
}

.field :global(.fr-toolbar.fr-top .fr-newline),
.field :global(.fr-second-toolbar) {
  display: none;
}

.field :global(.fr-popup .fr-icon-container) {
  grid-template-columns: repeat(auto-fill, minmax(3rem, 3rem));
}

.field :global(.fr-popup .fr-special-character) {
  width: 2rem;
  height: 2rem;
}

.equation {
  background: color(gray-50);
  border-radius: 0 0 22.5px 22.5px;
  padding: $spacer-s;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled .field {
  background: color(gray-200);
  cursor: not-allowed;
  color: color(gray-700);
}

.hasError .field {
  border-color: $color-wrong;
}

.cefield {
  margin-bottom: $spacer-xs;
}
