@import '~styles';

.enter {
  opacity: 0;

  .side-panel-modal {
    transform: translateX(100%);
  }
}

.enter-active {
  opacity: 1;
  transition: opacity 250ms linear;

  .side-panel-modal {
    transform: translateX(0);
    transition: transform 250ms ease-in-out;
  }
}

.exit {
  opacity: 1;

  .side-panel-modal {
    transform: translateX(0);
  }
}

.exit-active {
  opacity: 0;
  transition: opacity 250ms linear;

  .side-panel-modal {
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
  }
}

.overlay {
  position: fixed;
  z-index: $z-layer-mid-level;
  top: 0;
  right: 0;
  bottom: 0;
  left: 250px;
  overflow: auto;

  @include responsiveSmall() {
    left: 0;
  }
}

.collapsed-menu-overlay {
  left: 55px;

  @include responsiveSmall() {
    left: 0;
  }
}

.full-screen-overlay {
  left: 0;
}

.side-panel-overlay {
  left: 0;
  background-color: rgba($color: $color-dark-blue-3, $alpha: 0.5);
  z-index: $z-layer-header;
}

.modal {
  position: fixed;
  z-index: $z-layer-modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 250px;
  overflow: auto;
  padding: $spacer-s $spacer-3xl;
  background-color: $color-background;

  @include responsiveSmall() {
    left: 0;
    padding: $spacer-xs;
  }
}

.collapsed-menu-modal {
  left: 55px;

  @include responsiveSmall() {
    left: 0;
  }
}

.side-panel-modal {
  left: 500px;
  padding: $spacer-s 0;
  background-color: $color-light;

  @include responsiveSmallSidePanel() {
    left: 0;
  }
}

.large-side-panel-modal {
  left: 300px;

  @include responsiveLargeSidePanel() {
    left: 0;
  }
}

.full-screen-modal {
  left: 0;
  padding: 0;

  @include responsiveSmall() {
    padding: 0;
  }

  .header {
    justify-content: space-between;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $spacer-2xl $spacer-3xl;
  border-bottom: 1px solid $color-light-gray;

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}

.header-size-default {
  border-bottom: none;
  padding: $spacer-2xs $spacer-3xl $spacer-s 0;

  .title {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
  }

  @include responsiveSmall() {
    padding: $spacer-xs;
  }
}

.header-size-small {
  border-bottom: none;

  .title {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
  }
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 44px;
  margin-right: $spacer-2xs;
}

.logo-text {
  @include textGradient($color-dark-purple, color(blue-300));

  font-family: $font-family-nunito;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
}

.title {
  margin-left: $spacer-s;
  margin-bottom: $spacer-3xs;
  color: $color-text;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
}

.subtitle {
  margin-left: $spacer-s;
  color: $color-text-light;
  font-size: $font-size-xs;
  line-height: 1.188rem;
  font-weight: $font-weight-bold;
}
