@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.match-complete-left {
  flex: 0 0 auto;
  width: calc(50% - 78px);
}

.match-complete-right {
  flex: 0 0 auto;
  width: calc(50% - 40px);
}
