@import '~styles';

.container {
  width: 500px;
  max-width: 98%;
  margin: $spacer-2xl auto 0;

  @include responsiveSmall() {
    margin: 0 auto;
  }
}

.section {
  padding: $spacer-3xl;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 35px;
  background-color: $color-light;
  box-shadow: $shadow-dark-blue;

  @include responsiveSmall() {
    padding: $spacer-m;
  }
}

.image {
  display: block;
  width: 200px;
  margin: 0 auto 38px;

  @include responsiveSmall() {
    display: none;
  }
}

.title {
  margin-bottom: $spacer-3xs;
  color: $color-text;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
}

.subtitle {
  @include textMediumLight();

  margin-bottom: $spacer-s;
  text-align: center;
}

.action {
  margin-bottom: $spacer-xs;
  width: 100%;
}

.or {
  height: 1px;
  margin: $spacer-2xl 0;
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  text-align: center;
  background-color: $color-light-gray;

  span {
    background: $color-background;
    padding: 0 $spacer-xs;
    position: relative;
    top: -$spacer-2xs;

    @include responsiveSmall() {
      background-color: transparent;
    }
  }
}
