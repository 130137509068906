@import '~styles';

.container {
  margin-bottom: 45px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-s;
}

.title {
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  margin-right: $spacer-m;
}

.more {
  @include textBolderDark();

  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.see-more-large {
  @include responsiveSmall() {
    display: none;
  }
}

.see-more-small {
  display: none;

  @include responsiveSmall() {
    display: block;
  }
}

.icon {
  font-size: $font-size-2xs;
  margin-left: $spacer-xs;
}
