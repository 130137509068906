@import '~styles';

.text {
  width: 100%;
  min-width: 0;
  display: block;
  padding: $spacer-3xs $spacer-xs;
  border: $border-blue-light;
  border-radius: 22.5px;
  background: $color-light;
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bold;

  &:focus {
    border-color: $color-text;
  }
}

.range {
  display: flex;
  align-items: center;

  .to {
    font-size: $font-size-2xs;
    margin: 0 $spacer-3xs;
    color: $color-text;
  }

  input {
    width: 70px;
    display: block;
    padding: $spacer-3xs $spacer-xs;
    border: $border-blue-light;
    border-radius: 22.5px;
    background: $color-light;
    color: $color-text;
    font-size: $font-size-2xs;
    font-weight: $font-weight-bold;

    &:focus {
      border-color: $color-text;
    }
  }
}
