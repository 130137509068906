@import '~styles';

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: $color-light-black;
}

.image {
  width: 15rem;
}
