@import '~components/Shared/Styles/StyledSubPage.module.scss';

.subpage {
  position: relative;
  height: 100vh;
  overflow: hidden;

  @include responsiveSmall() {
    height: auto;
  }
}

.main {
  display: flex;
  gap: $spacer-m;
  height: calc(100% - 70px); // 70px is the height of the footer
  padding: $spacer-l $spacer-3xl 0 $spacer-m;
  overflow-y: auto;

  @include responsiveMedium() {
    padding: $spacer-l $spacer-2xl 0 $spacer-m;
  }

  @include responsiveSmall() {
    height: fit-content;
  }

  @include responsiveLargeSidePanel() {
    flex-direction: column;
  }

  &.menu-expanded {
    @include responsiveSmallSidePanel() {
      flex-direction: column;
    }
  }
}

.editor {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: $spacer-2xl;
  height: fit-content;

  @include responsiveSmall() {
    padding-bottom: $spacer-m;
  }
}

.editor-container {
  background-color: $color-light-black;
  border-radius: 15px;
  padding: $spacer-m $spacer-2xl;

  @include responsiveSmall() {
    padding: $spacer-m $spacer-m;
  }
}
