@import '~styles';

.container {
  border-radius: 25px;
  background-color: $color-light;
  padding: $spacer-l;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  border: $border-black-light;

  @include responsiveSmall() {
    display: block;

    .title-container {
      margin-bottom: $spacer-xs;
    }
  }
}

.container-single {
  box-shadow: $shadow-blue;
}

.title-icon {
  margin-right: $spacer-xs;
  height: 40px;
  width: 40px;
  line-height: 2.5rem;
  text-align: center;
  font-weight: $font-weight-bolder;
  border-radius: 7px;
  background: $color-pastel-turquoise;
  color: $color-text;
  font-size: $font-size-m;
}

.title-container {
  display: flex;
  align-items: center;
  margin-top: $spacer-3xs;
}

.title {
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.deadline-container {
  display: flex;
  align-items: center;
}

.deadline {
  flex-grow: 1;
}

.submit-button {
  margin-top: $spacer-xs;
  float: right;
  display: none;
}

.visible {
  display: block;
}
