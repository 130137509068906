@import '~styles';

.card-container {
  background-color: $color-pastel-purple;
  border-radius: 30px;
  box-shadow: none;
  text-decoration: $decoration-none;
  padding: $spacer-xs $spacer-xs $spacer-xs $spacer-m;
  transition: box-shadow 200ms ease-in-out, margin 200ms ease-in-out;
  margin: 0;
  width: 205px;
  min-width: 205px;

  &:hover,
  &:focus {
    text-decoration: $decoration-none;
    box-shadow: $shadow-blue;
    margin: -$spacer-3xs 0 $spacer-3xs 0;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
  }
}

.pin {
  height: 30px;
  width: 30px;
  background: $color-light;
  border: none;
  border-radius: 50%;
  font-size: $font-size-3xs;
  color: $color-secondary;
  transform: translate(-55px, 10px);

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
  }
}

.picture {
  width: 100%;
  height: 80px;
  object-fit: contain;
  margin: 0 0 $spacer-xs 0;
}

.icon {
  margin-right: $spacer-xs;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 7px;
  background: $color-light;
  color: color-dark('purple');
}

.title {
  @include textBolderDark;

  line-height: 1.5rem;
  text-align: left;
  margin: 0 0 $spacer-m 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacer-3xs;
}

.owner {
  color: $color-text;
  font-size: $font-size-3xs;
  font-weight: $font-weight-medium;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: $spacer-3xs;
}

.participants {
  color: $color-text;
  font-size: $font-size-2xs;
  font-weight: $font-weight-bolder;
}

.participants-icon {
  margin-right: $spacer-xs;
  color: $color-text;
}

@each $color in $palette-theme {
  .theme-#{$color} {
    background: color-pastel($color);

    .icon {
      color: color-dark($color);
    }
  }
}
