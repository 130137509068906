@import '~styles';

.zone {
  position: absolute;
  touch-action: none;
  user-select: none;
  cursor: grab;
  outline: none;
  border: none;
  border: $border-light-transparent;
  background: color(gray-400);

  &:hover,
  &:focus {
    outline: none;
  }

  &.isActive {
    border-color: $color-light;
  }
}

.resize,
.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: $color-light;
  border: $border-light;
  border-radius: 50%;
  font-size: $font-size-3xs;

  &:hover,
  &:focus {
    outline: none;
  }
}

.resize {
  bottom: -$spacer-xs;
  cursor: nwse-resize;
}

.delete {
  top: -$spacer-xs;
  cursor: pointer;
  color: color(red-400);
}

.shape-rectangle {
  border-radius: 10px;

  .delete,
  .resize {
    right: -$spacer-xs;
  }
}
