@import '~styles';

.container {
  display: block;
  width: 100%;
  text-align: left;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-3xs;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.helper {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.field-box {
  position: relative;
}

.field {
  @include textBoldDark();

  display: block;
  width: 100%;
  padding: $spacer-xs $spacer-l;
  border: $border-gray-light;
  border-radius: 5px;
  background-color: $color-light;

  &:focus {
    border-color: $color-text;
  }

  &::placeholder {
    color: color(gray-700);
  }
}

.arrow {
  pointer-events: none;
  position: absolute;
  top: $spacer-3xs;
  bottom: $spacer-3xs;
  right: $spacer-3xs;
  padding: $spacer-s $spacer-l;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-light;
  border-radius: 0 5px 5px 0;
  color: $color-text;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled .field,
.isDisabled .arrow {
  background: color(gray-200);
  cursor: not-allowed;
  color: color(gray-700);
}

.hasError .field,
.hasError .arrow {
  border-color: $color-wrong;
}

.intent-white .field {
  background: transparent;
  border: $border-light;
  color: color(light);
}

.intent-gray {
  .field,
  .arrow {
    border-radius: 50px;
    background-color: $color-light-black;
    border-color: $color-light-black;
  }
}
