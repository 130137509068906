@import '~components/Shared/Styles/StyledModal.module.scss';

.modal {
  @include styledModal();
}

.answers {
  flex-grow: 1;

  &.scroll {
    @include scrollbarPrimary();

    background-color: $color-light;
    border-radius: 20px;
    flex: 1 1 auto;
    height: fit-content;
    max-height: 500px;
    border: 15px solid $color-light;
    padding-right: 8px;
  }
}

.answer {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;

  @include responsiveSmall {
    align-items: flex-start;
  }
}

.check {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:hover ~ .checkbox,
  &:focus ~ .checkbox {
    border-color: $color-dark-purple;
  }
}

.checkbox {
  height: 25px;
  width: 25px;
  margin: 0 10px;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-light;
  border: 2px solid $color-light-gray;
  border-radius: 5px;
  color: $color-light;
  cursor: pointer;
  font-size: 0.65rem;

  &.isChecked {
    background: $background-gradient-primary;
    color: $color-light;

    &:hover,
    &:focus {
      background: $background-gradient-primary;
      border-color: $color-text;
    }
  }

  @include responsiveSmall {
   margin-top: 17px;
  }
}
