@import '~questions/Shared/Styles/Editor.module.scss';
@import '~components/Shared/Styles/StyledModal.module.scss';

.container {
  @include editor();
}

.audio {
  width: 100%;
}
