@import '~styles';

.container {
  border-radius: 25px;
  background-color: $color-light;
  padding: $spacer-l;
  box-shadow: $shadow-blue;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $spacer-l;

  &.isStudent {
    justify-content: space-between;
  }
}

.title-container {
  display: flex;
  align-items: center;
}

.title-icon {
  margin-right: $spacer-xs;
  height: 40px;
  width: 40px;
  line-height: 2.5rem;
  text-align: center;
  font-weight: $font-weight-bolder;
  border-radius: 7px;
  background: $color-pastel-blue;
  color: $color-dark-blue-2;
  font-size: $font-size-m;
}

.title {
  color: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.download-button {
  @include responsiveSmall() {
    padding: 0 0 0 $spacer-xs;
  }
}

.download-text {
  @include responsiveSmall() {
    display: none;
  }
}

.stat-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.content {
  border-radius: 25px;
  padding: $spacer-l;
  width: 32%;

  @include responsiveSmall() {
    padding: $spacer-m;
    flex-direction: column;
    width: 100%;
    margin-bottom: $spacer-xs;
  }

  &.progression {
    background: $color-light-purple-3;
  }

  &.success {
    background: $color-light-green;
  }

  &.mastery {
    background: $color-light-yellow;
  }
}

.graph-container {
  display: flex;

  @include responsiveMedium() {
    flex-direction: column;
    align-items: center;
  }

  @include responsiveSmall() {
    flex-direction: row;
  }
}

.graph {
  min-width: 130px;
  width: 130px;
  height: 130px;
  margin-right: $spacer-2xl;

  @include responsiveMedium() {
    min-width: 100px;
    width: 100px;
    height: 100px;
    margin-right: $spacer-xs;
  }
}

.name {
  margin: 50px 0 $spacer-xs 0;
  color: $color-text;
  font-weight: $font-weight-bold;

  @include responsiveMedium() {
    margin: 0 0 $spacer-xs 0;
  }

  @include responsiveSmall() {
    margin: 50px 0 $spacer-xs 0;
  }
}

.explanation {
  font-size: $font-size-2xs;
  line-height: 1.125rem;
  font-weight: $font-weight-medium;
  color: $color-text-light;
}

.percentage {
  fill: $color-text;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: $spacer-s;

  &.success {
    background: $color-success;
  }

  &.mastery {
    background: color(orange-300);
  }

  &.progression {
    background: color(blue-300);
  }
}
