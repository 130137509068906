@import '~styles';

.participants {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-light;
  border-radius: 20px;
  padding: 0 $spacer-3xs 0 0;
}

.participants-highlight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-container {
  border-radius: 30px;
  margin-right: -$spacer-xs;
}

.participants-number {
  font-size: $font-size-xs;
  color: $color-text;
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  padding: 0;
  border: $border-gray-light;
  background: $color-light;
  line-height: 1.875rem;
  font-weight: $font-weight-bolder;
}

.list-container {
  padding: $spacer-m;
  max-height: 90vh;
  width: 300px;
}

.list-title {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  color: $color-text;
  margin-bottom: $spacer-xs;
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-xs;
  border: $border-blue-light;
  border-radius: 22.5px;
  background: $color-light;
  color: $color-text;
  width: 250px;
  padding: 0 $spacer-xs;

  @include responsiveSmall() {
    width: 100%;
    margin-right: 0;
    margin-bottom: $spacer-xs;
  }

  &:focus-within {
    border-color: $color-text;
  }

  .search-input {
    flex-grow: 1;
    padding: $spacer-xs 0 $spacer-xs $spacer-xs;
    color: $color-text;
    height: 40px;
    font-size: $font-size-2xs;
    font-weight: $font-weight-bold;
    border: none;
    background: none;
  }
}

.participants-list {
  overflow: scroll;
}

.participant {
  display: flex;
  padding: $spacer-xs 0;
  align-items: center;
}

.status {
  border-radius: 50px;
  background-color: $color-light-gray;
  height: 10px;
  width: 10px;
  margin-right: $spacer-3xs;

  &.isActive {
    background-color: $color-secondary;
    border: none;
  }
}

.information {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.name {
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  margin-left: $spacer-xs;
  color: $color-text;
}

.last-connection {
  color: $color-text-light;
}
