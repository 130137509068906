@import '~styles';

.header {
  background: $color-primary;
  border-radius: 20px 20px 0 0;
  padding: $spacer-xs;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.occurrence {
  background-color: $color-light-gray;
  display: flex;
  align-items: center;
  padding: $spacer-3xs $spacer-xs;
  border-radius: 30px;
  font-weight: $font-weight-bolder;
  font-size: $font-size-xs;
}

.number {
  margin-right: $spacer-xs;
}

.header-icon {
  background-color: $color-success;
  color: $color-light;
  font-size: $font-size-m;
  width: 30px;
  height: 30px;
  line-height: 1.875rem;
  text-align: center;
  border-radius: 30px;
}

.wrong {
  background-color: $color-wrong;
}

.item {
  background-color: $color-light;
  display: flex;
  padding: $spacer-xs;

  &:last-child {
    border-radius: 0 0 20px 20px;
  }
}

.pair {
  background-color: $color-light-gray;
}

.position {
  color: $color-light;
  font-weight: $font-weight-bold;
  font-size: $font-size-2xs;
  background: $background-gradient-primary;
  border: $border-light;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  line-height: 1.688rem;
  text-align: center;
  margin-right: $spacer-xs;
  box-shadow: $shadow-purple;
}

.item-text {
  color: $color-text-light;
}

.item-icon {
  color: $color-success;
  font-size: $font-size-m;
  margin-right: $spacer-2xs;
}

.item-icon-wrong {
  color: $color-wrong;
  margin-right: $spacer-2xs;
}
