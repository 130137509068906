@import '~styles';

.container {
  background-color: color(light);
  box-shadow: $shadow-blue;
  border-radius: 10px;
  padding: $spacer-s;
}

.header {
  margin-bottom: $spacer-3xs;
  display: flex;
}

.header-primary {
  flex-grow: 1;
}

.solve-button {
  flex-shrink: 0;
  margin-left: $spacer-3xs;
}

.content {
  margin-bottom: $spacer-s;
}

.comment-reply {
  margin-bottom: $spacer-s;
}

.line {
  border-bottom: 1px solid $color-blue-light;
  margin: $spacer-s 0;
}

.reply {
  font-size: $font-size-3xs;
  font-weight: $font-weight-bolder;
  text-align: left;
  margin-bottom: $spacer-3xs;
}

.toggle-replies {
  border: none;
  outline: none;
  background: none;
  font-size: $font-size-3xs;
  font-weight: $font-weight-bolder;
  text-align: left;
  color: $color-text;

  &:focus,
  &:hover {
    outline: none;
  }
}

.chevron-right {
  margin-left: $spacer-3xs;
}
