@import '~questions/Shared/Styles/Editor.module.scss';

.container {
  @include editor();
}

.toolbar {
  margin: $spacer-xs 0;
  display: flex;
  flex-wrap: wrap;

  @include responsiveMedium() {
    flex-direction: column;
  }
}

.action {
  margin: 0 $spacer-3xs $spacer-3xs 0;

  @include responsiveMedium() {
    margin: 0 0 $spacer-3xs 0;
  }
}

.image {
  width: 100%;
  height: 200px;
}

.image-container {
  max-width: 900px;
  width: 100%;
  margin: auto;
}
