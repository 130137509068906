@import '~components/Shared/Styles/NewStyledModal.module.scss';

.modal {
  @include newStyledModal();
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
  border-radius: 100px;
  background: $color-pastel-purple;
  border: none;
  margin: 0 auto $spacer-l auto;

  @each $color in $palette-theme {
    &.theme-#{$color} {
      background: color-pastel($color);
    }
  }
}

.illustration {
  position: relative;
  width: 100%;
  height: 60%;
  background-size: cover;
  background-repeat: no-repeat;
}

.subtitle {
  margin-bottom: $spacer-m !important;
}

.info {
  font-weight: $font-weight-bold;
  margin-top: $spacer-s;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 $spacer-s 0 $spacer-2xl;
  border-radius: 50px;
  border: none;
  background-color: $color-text;
  box-shadow: $shadow-dark;
  font-size: $font-size-m;
  font-weight: $font-weight-bolder;
  text-align: left;
  color: $color-light;
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;

  &:hover,
  &:focus {
    box-shadow: $shadow-dark;
    transform: translateY(-5px);
    text-decoration: $decoration-none;
    color: $color-light;
    outline: none;
  }

  @include responsiveSmall() {
    width: 100%;

    &:hover,
    &:focus {
      transform: scale(1.01);
    }
  }
}

.chevron {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  background-color: $color-light;
  color: $color-text;
  text-align: center;
  margin-left: 40px;

  @include responsiveMedium() {
    margin-left: $spacer-xs;
  }
}

.button.isTeacher {
  @include textBolderDark();

  text-align: center;
  background-color: $color-light;
  height: 45px;
  box-shadow: none;
  border: $border-gray-light;
  width: auto;
  padding: 0 $spacer-2xl;

  &:hover,
  &:focus {
    transform: none;
    background-color: $color-light-black;
    border: $border-transparent;
    color: $color-text;
    box-shadow: none;
    outline: none;
  }

  .chevron {
    width: 23px;
    height: 23px;
    border-radius: 5px;
    font-size: $font-size-2xs;
    padding-top: $spacer-3xs;
    background-color: rgba($color: $color-light, $alpha: 0.3);
  }
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacer-s;

  @include responsiveSmall() {
    flex-direction: column-reverse;
    gap: $spacer-2xs;
  }
}
