@import '~questions/Shared/Styles/Aggregate.module.scss';

.info-right,
.info-left {
  margin-top: $spacer-xs;
}

.author {
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  margin-bottom: $spacer-3xs;
}

.duration-container {
  display: flex;
  align-items: center;
  background-color: $color-light-purple-3;
  border-radius: 5px;
  padding: $spacer-3xs;
}

.duration-icon {
  color: $color-primary;
}

.duration {
  font-weight: $font-weight-bolder;
  font-size: $font-size-2xs;
  margin-left: $spacer-3xs;
}

.info-right {
  display: flex;
  align-items: center;
}

.number {
  font-weight: $font-weight-bolder;
  font-size: $font-size-xs;
  margin: 0 $spacer-xs;
}

.button {
  background-color: $color-light-black;
  border: none;

  &:focus,
  &:hover {
    background-color: $color-light-gray;
  }
}
