@import '~styles';

.container {
  border-radius: 20px;
  background: $color-light-black;
  padding: $spacer-xs;

  &.tooltip {
    transform: translateY(-40%);
    width: 185px;
    padding: $spacer-m;
    background: $color-light;
    box-shadow: $shadow-blue;
  }

  &.future {
    text-align: center;
    background: $background-gradient-primary;
  }
}

.header {
  display: flex;
  align-items: center;
}

.course-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-3xs;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background: gradient-light('purple');
  color: $color-light;
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    display: none;
  }
}

.title {
  @include textBolderDark();
}

.picture {
  background-color: $color-light-purple-2;
  width: 100%;
  height: 70px;
  border-radius: 15px;
  border: none;
  object-fit: contain;
}

.stat-to-study {
  font-weight: $font-weight-medium;
  font-size: $font-size-2xs;
  color: $color-text-light;
  margin-top: $spacer-m;

  span {
    font-weight: $font-weight-bolder;
    color: $color-text;
    font-size: $font-size-m;
    margin: 0 $spacer-3xs;
    line-height: 0.75rem;
  }

  &.future {
    color: $color-light;
    font-weight: $font-weight-bold;
    margin: $spacer-l 0 $spacer-xs 0;

    span {
      font-weight: $font-weight-bolder;
      color: $color-light;
      margin: 0 $spacer-3xs 0 0;
    }
  }
}

.subtitle-container {
  display: flex;
  margin-top: $spacer-s;
}

.icon-check {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-3xs;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $color-secondary;
  color: $color-light;
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    display: none;
  }
}

.subtitle {
  font-size: $font-size-2xs;
  font-weight: $font-weight-medium;
  color: $color-text-light;

  &.future {
    color: $color-light;
    font-weight: $font-weight-bold;
    text-align: left;
  }
}

@each $color in $palette-theme{
  .theme-#{$color} {
    .course-icon {
      background: color-dark($color);
    }
  }
}
