@import '~styles';

.container {
  border: $border-light;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out,
    margin-left 200ms ease-in-out, padding 200ms ease-in-out;
  width: 100%;
  margin-left: 0;
  padding: 0;
  margin-bottom: $spacer-xs;

  &:hover,
  &:focus {
    width: 101%;
    padding: 0 0.5%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    outline: none;
  }
}

.toggle {
  @include textGradient($color-dark-purple, color(blue-300));

  margin-right: $spacer-s;
}

.folder {
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: $spacer-s $spacer-2xl $spacer-m $spacer-l;
  background-color: $color-light;
  border: $border-light;
  border-radius: 10px;

  @include responsiveSmall() {
    padding: $spacer-xs $spacer-s;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: $color-text;
    border-radius: 10px;
  }
}

.margin-bottom {
  margin-bottom: $spacer-s;
}

.folder-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: gradient-light('purple');
  color: $color-text;
}

.title {
  @include textOverflow();
  @include textNormalDark();

  padding: 0 $spacer-xs 0 $spacer-m;
  text-align: left;

  &:hover,
  &:focus {
    outline: none;
  }
}

.course-number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: gradient-light('purple');
  color: $color-text;
  font-weight: $font-weight-bolder;
  font-size: $font-size-3xs;
}

.fill {
  flex-grow: 1;
}

.action {
  flex: 0 0 auto;
  margin-left: $spacer-xs;
}

.courses {
  border-radius: 10px;
  min-height: 3rem;
  transition: background 250ms ease-in-out;
}

.courses.isDraggingOver {
  background: color(gray-200);
}

.course {
  margin-bottom: $spacer-xs;
}

.container.isDraggingOver {
  background: color(gray-100);
  border-color: color(purple-400);
}

.container.isSelected {
  border-color: color(purple-400);
}

.container.isDragging {
  border-color: color(purple-400);
}
