@import '../Styles/OnboardingSubPage.module.scss';

.container {
  @include styledSubPage();

  .select-button {
    overflow: hidden;

    @include responsiveMedium() {
      height: 250px;
    }
  }

  .select-button:nth-child(1) {
    background: $color-light-purple;
  }

  .select-button:nth-child(2) {
    background: $color-light-blue;
    padding-top: 50px;
  }

  .point:nth-child(2) {
    background: $color-primary;
    width: 30px;

    &.new-onboarding-point {
      background: $color-light-purple-3;
      width: 15px;
    }
  }

  .point:nth-child(3) {
    &.new-onboarding-point {
      background: $color-primary;
      width: 30px;
    }
  }
}
