@import '~questions/Shared/Styles/Aggregate.module.scss';

.container {
  @include aggregate();
}

.choice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacer-2xs;
  margin-bottom: $spacer-xs;
  box-shadow: $shadow-light;
  border-radius: 5px;
  padding: $spacer-xs;
}

.checkbox {
  text-align: left;
}

.buttons {
  display: flex;
  align-items: center;
  gap: $spacer-2xs;
}

.button {
  height: 65px;
  width: 65px;
  background: $color-light-gray;
  color: $color-text;
  font-size: $font-size-m;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;

  &.isChecked {
    background: $background-gradient-primary;
    color: $color-light;
  }
}

.choice-title {
  max-width: 25%;
}

.gauge {
  position: relative;
  margin-right: $spacer-s;
  height: 20px;
  width: 100%;
  background-color: $color-blue-light;
  border-radius: 20px;
  background: $color-light-gray;

  & > .success {
    height: 20px;
    border-radius: 20px;
    background: $background-gradient-green;
  }

  & > .animation {
    animation-name: percentage;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0, 1.1, 0.55, 1.01);
    height: 20px;
    border-radius: 20px;
    background: $color-light-gray;
    position: absolute;
    right: 0;
    top: 0;
  }

  @keyframes percentage {
    0% {
      width: 100%;
    }
    10% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }

  .percentage {
    position: absolute;
    top: 1px;
    left: calc(50% - 25px);
    background: $color-light;
    font-size: $font-size-2xs;
    border-radius: 10px;
    width: 50px;
    text-align: center;
    color: $color-success;
    font-weight: $font-weight-bold;
  }
}

.good-answers {
  min-width: 150px;
  color: $color-success;
  font-weight: $font-weight-medium;
}
