@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();

  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas {
  margin: auto;
  border-radius: 30px;
  outline: 22px solid $color-light;
  box-shadow: $shadow-dark-blue;

  @include responsiveSmall() {
    outline: 10px solid $color-light;
  }
}
