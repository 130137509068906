@import '~styles';

.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  height: 70px;
  padding: $spacer-3xs $spacer-3xl;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid $color-light-gray;
  background-color: $color-light;

  @include responsiveSmall() {
    position: relative;
    flex-direction: column;
    gap: $spacer-3xs;
    height: fit-content;
    padding: $spacer-3xs $spacer-l;
  }
}

.button {
  @include responsiveSmall() {
    width: 100%;
  }
}

.cancel {
  background-color: $color-light;
}

.actions-right {
  display: flex;
  gap: $spacer-xs;

  @include responsiveSmall() {
    flex-direction: column;
    width: 100%;
  }
}

