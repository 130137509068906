@import '~styles';

.container {
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
}

.field {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:hover ~ .checkbox,
  &:focus ~ .checkbox {
    border-color: $color-dark-purple;
  }
}

.checkbox {
  height: 25px;
  width: 25px;
  margin-right: $spacer-xs;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-light;
  border: $border-gray-light;
  border-radius: 5px;
  color: $color-light;
  cursor: pointer;
  font-size: $font-size-3xs;

  &.isChecked {
    background: $background-gradient-primary;
    color: $color-light;

    &:hover,
    &:focus {
      background: $background-gradient-primary;
      border-color: $color-text;
    }
  }
}

.content {
  flex-grow: 1;
  font-size: $font-size-xs;
  text-align: left;
}

.label {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.children {
  color: $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.error-message {
  color: $color-wrong;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
}

.isDisabled {
  pointer-events: none;

  .checkbox,
  &:hover .checkbox,
  .field:checked ~ .checkbox {
    background: color(gray-300);
    color: color(gray-700);
  }

  .label {
    color: color(gray-700);
  }

  .helper {
    color: color(gray-300);
  }
}

.hasError .checkbox {
  border-color: $color-wrong;
}

.intent-gray {
  .checkbox {
    background-color: $color-light-black;
    color: $color-light-black;
    border-color: $color-light;
  }
}
