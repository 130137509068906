@import '~questions/Shared/Styles/Face.module.scss';

.container {
  @include face();
}

.choice {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-2xs;
  padding: $spacer-m $spacer-l;
  background: $color-light;
  border-radius: 10px;
  box-shadow: $shadow-blue;
  color: $color-text;
}

.icon {
  color: $color-blue-light;
  flex: 0 0 auto;
  font-size: $font-size-m;
  margin-right: $spacer-l;
  line-height: 15px;
  height: 15px;
}

.choice-title {
  flex-grow: 1;
}

.isCorrect .choice-title,
.isWrong .choice-title {
  font-weight: $font-weight-bold;
}

.isCorrect {
  background: $color-light-green-2;
  border: $border-thick-green-pastel;

  .icon {
    color: $color-pastel-green;
    background-color: $color-light;
  }
}

.isWrong {
  background: $color-light-red-2;
  border: $border-thick-red-dark;
  .icon {
    color: $color-dark-red-2;
    background-color: $color-light;
  }
}

.choice-feedback {
  margin-top: $spacer-3xs !important;
  margin-bottom: $spacer-l;
}

.aria-label {
  @include ariaLabel();
}
