@import '~styles';

.container {
  width: 100%;
}

.lateral-panel-button {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: $font-size-xs;
  font-weight: $font-weight-bolder;
  padding: $spacer-s $spacer-l;
  color: $color-text-light;
  text-align: left;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  transition: color ease-in-out 250ms;

  &:hover,
  &:focus {
    color: $color-text;
    outline: none;
    text-decoration: $decoration-none;
  }

  &:focus-visible,
  &:focus-within {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }

  &.small {
    padding: $spacer-s $spacer-2xs;

    @include responsiveSmall() {
      padding: $spacer-s $spacer-l;
    }
  }
}

.lateral-panel-icon {
  margin-right: $spacer-m;
  min-width: 25px;
  text-align: center;
  font-size: $font-size-m;
}

.lateral-panel-text {
  @include textOverflow();

  max-width: 100%;

  &.hidden {
    display: none;

    @include responsiveSmall() {
      display: block;
    }
  }
}

.toggle-button {
  border: none;
  border-radius: 30px;
  box-shadow: $shadow-light;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
  }
}
