@import '~styles';

.container {
  height: 100vh;
  display: grid;
  grid-template:
    'side header' auto
    'side main' 1fr /
    360px 1fr;
  background-color: $color-background;

  @include responsiveSmall() {
    grid-template:
      'header' auto
      'side' 190px
      'main' 1fr /
      1fr;
  }
}

.side {
  grid-area: side;
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url('./images/bg.svg'), $gradient-dark-purple-2;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100% auto;
  box-shadow: $shadow-blue;

  @include responsiveSmall() {
    background-image: none;
    background-color: $color-primary-dark;
    box-shadow: none;
    border-radius: 0 0 50px 50px;
  }
}

.logo {
  display: flex;
  align-items: center;
  font-family: $font-family-nunito !important;
  font-size: $font-size-l;
  font-weight: $font-weight-bolder;
  margin: $spacer-2xl 0 0 $spacer-2xl;
  color: $color-light;

  &:hover,
  &:focus {
    text-decoration: $decoration-none;
    color: $color-light;
  }

  &:focus-visible {
    box-shadow: $shadow-focus;
    border-radius: 10px;
  }

  @include responsiveSmall() {
    display: block;
    color: $color-light;
    font-size: $font-size-2xl;
    text-align: center;
    font-weight: $font-weight-bolder;
    z-index: $z-layer-low-level;
  }
}

.side-text {
  position: absolute;
  bottom: 85px;
  left: $spacer-2xl;
  text-align: left;
  font-weight: $font-weight-medium;
  font-size: $font-size-xl;
  color: $color-light;

  @include responsiveSmall() {
    display: none;
  }
}

.elephant-small {
  display: none;
  position: absolute;
  transform: scale(1.2);
  left: $spacer-xs;
  bottom: $spacer-l;
  z-index: $z-layer-neutral;

  @include responsiveSmall() {
    display: block;
  }
}

.bg-small {
  display: none;
  position: absolute;
  right: $spacer-l;
  bottom: $spacer-2xl;
  z-index: $z-layer-neutral;

  @include responsiveSmall() {
    display: block;
  }
}

.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $spacer-xs;

  @include responsiveSmall() {
    background-color: $color-primary-dark;
  }
}

.header-action {
  margin-right: $spacer-xs;

  @include responsiveSmall() {
    display: none;
  }
}

.main {
  grid-area: main;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-layer-low-level;

  @include responsiveSmall() {
    align-items: flex-start;
    margin-top: -90px;
  }
}

.content {
  width: 100%;
  max-width: 440px;
  padding: $spacer-m;

  @include responsiveSmall() {
    padding: 0 $spacer-2xs $spacer-m $spacer-2xs;
  }
}

