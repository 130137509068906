@import '~components/Shared/Styles/Auth.module.scss';
@import '~components/Shared/Styles/StyledModal.module.scss';

.container {
  @include styledAuth();
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacer-xs;
  list-style: none;
}

.button {
  color: $color-text;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $spacer-xs;
  padding: 0 $spacer-m;
  border: $border-blue-light;
  border-radius: 22.5px;
  background: $color-light;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: $decoration-none;
    background-color: $color-light-black;
    border-color: $color-light-black;
    color: $color-text;
  }
}

.logo {
  width: $font-size-m;
  font-size: $font-size-m;
}

.facebook .logo {
  color: $color-facebook;
}

.or {
  margin: $spacer-l 0 $spacer-l 0;

  @include responsiveSmall() {
    margin: $spacer-m 0 $spacer-l 0;
  }
}

.or span {
  background-color: $color-background !important;

  @include responsiveSmall() {
    background-color: $color-light !important;
  }
}
