@import '~components/Shared/Styles/StyledModal.module.scss';

.button {
  position: relative;
  margin-right: $spacer-m;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  border-radius: 20px;
  background: $color-pastel-purple;
  border: none;
  font-size: $font-size-m;

  &:hover,
  &:focus {
    outline: none;
  }

  @include responsiveSmall() {
    display: none;
  }

  @each $color in $palette-theme {
    &.theme-#{$color} {
      background: color-pastel($color);
    }
  }
}

.image {
  width: 100%;
  height: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.isTutorial2 {
    height: 100%;
  }
}

.pencil-container {
  background-color: $color-light-gray;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 17px;
  border: $border-light;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -$spacer-2xs;
  right: -$spacer-2xs;
}

.pencil {
  color: $color-text;
  font-size: $font-size-2xs;
}

.menu {
  background: $color-light;
  border-radius: 10px;
  padding: $spacer-xl $spacer-xs $spacer-xs $spacer-xl;
}

.theme {
  margin: 0 $spacer-s $spacer-s 0;

  @include responsiveSmall() {
    margin: 0 $spacer-2xs $spacer-s 0;
  }
}
