@import '~styles';

.container {
  margin-right: $spacer-xs;
  padding: $spacer-s $spacer-2xl;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  border: $border-light;
  border-radius: 10px;
  background-color: $color-light;
  box-shadow: $shadow-light;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out, margin-left 200ms ease-in-out;
  width: 100%;
  margin-left: 0;

  @include responsiveSmall() {
    padding: $spacer-xs $spacer-s;
  }

  &:hover,
  &:focus {
    width: 101%;
    margin-left: -0.5%;
    box-shadow: $shadow-light-hover;
    text-decoration: $decoration-none;
  }

  &:focus-visible {
    border-color: $color-text;
  }
}

.icon {
  flex: 0 0 auto;
  margin-right: $spacer-m;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: gradient-light('purple');
  color: color-dark('purple');

  @include responsiveSmall() {
    display: none;
  }
}

.title {
  @include textOverflow();
  @include textNormalDark();

  flex-grow: 1;
  margin-right: $spacer-xs;
}
