$z-layer-negative: -1;
$z-layer-neutral: 0;
$z-layer-low-level: 1000;
$z-layer-header: 2000;
$z-layer-mid-level: 2500;
$z-layer-modal: 3000;
$z-layer-projected-screen: 4000;
$z-layer-tooltip: 5000;
$z-layer-top-level: 6000;
$z-layer-high-order: 7000;




