@import '~styles';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-layer-high-order;
  background: rgba(0, 0, 0, 0.5);
  cursor: zoom-out;
}

.container-enter {
  opacity: 0;
}

.container-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.container-exit {
  opacity: 1;
}

.container-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.image {
  max-height: 98%;
  max-width: 98%;
  border-radius: 20px;
}
