@import './palette.scss';

$shadow-blue: 0 10px 85px 0 rgba(135, 141, 238, 0.15);
$shadow-blue-top: 0 -10px 85px 0 rgba(135, 141, 238, 0.15);
$shadow-dark-blue:0 10px 35px 0 rgba(25, 42, 116, 0.2);
$shadow-purple: 0px 5px 15px rgba(137, 97, 247, 0.05);
$shadow-dark: 0 15px 20px 0 rgba(0, 0, 0, 0.3);
$shadow-light: 0 10px 35px 0 rgba(213, 220, 252, 0.1);
$shadow-light-hover: 0 15px 35px 0 rgba(213, 220, 252, 0.5);
$shadow-focus: 0 0 0 2px $color-text;
