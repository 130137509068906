@import '~styles';

.correct-answer {
  padding: $spacer-3xs $spacer-l;
  border-radius: 15px;
  font-weight: $font-weight-bold;
  border: $border-thick-green-pastel;
  background-color: $color-light-green-2;
}

.text {
  line-height: 2.5rem;
  white-space: pre-line;
}
