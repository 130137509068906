@import '~styles';

@mixin styledAuth() {
  text-align: center;

  .title {
    margin-bottom: $spacer-m;
    color: $color-text;
    font-size: $font-size-m;
    font-weight: $font-weight-bolder;

    @include responsiveSmall() {
      margin-bottom: $spacer-s;
    }
  }

  .subtitle-container {
    display: flex;
    justify-content: center;
    margin-bottom: $spacer-m;
  }

  .subtitle {
    font-size: $font-size-xs;
    margin-right: $spacer-3xs;
  }

  .link {
    text-decoration: $decoration-underline;
    font-size: $font-size-xs;
  }

  .error,
  .success {
    font-weight: $font-weight-bold;
    margin-bottom: $spacer-s;
    text-align: center;

    a {
      color: inherit;
      text-decoration: $decoration-underline;
    }
  }

  .error {
    color: color(red-600);
  }

  .success {
    color: color(green-600);
  }

  .field {
    margin-bottom: $spacer-s;
  }

  .helper {
    font-size: $font-size-xs;
    color: color(gray-700);

    &:hover,
    &:focus {
      color: color(dark);
    }
  }

  .action {
    margin-bottom: $spacer-xs;
    width: 100%;
  }

  .or {
    height: 1px;
    margin-bottom: $spacer-l;
    background: $color-light-gray;
    color: $color-text;
    font-size: $font-size-2xs;
    font-weight: $font-weight-normal;

    span {
      background: $color-light;
      padding: 0 $spacer-xs;
      position: relative;
      top: -$spacer-2xs;
    }
  }
}

@mixin styledSection() {
  background-color: $color-light;
  border-radius: 30px;
  padding: $spacer-2xl;
  box-shadow: $shadow-dark-blue;

  @include responsiveSmall() {
    padding: $spacer-s;
  }
}
