@import '~styles';

.choices {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  @include responsiveSmall() {
    flex-direction: column;
  }
}

.choice {
  position: relative;
  width: 49%;
  height: 265px;
  border: none;
  padding-top: 40px;
  background: $color-light-purple-3;
  border-radius: 30px;
  transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: none;

  &:hover,
  &:focus {
    transform: translate(0, -5px);
    box-shadow: $shadow-dark;
    text-decoration: $decoration-none;
    outline: none;
  }

  &:focus-visible {
    border: $border-dark;
  }

  @include responsiveSmall() {
    width: 100%;
    margin-bottom: $spacer-xs;
    min-height: 150px;
    padding: $spacer-s;
    align-items: center;
    text-align: left;
  }
}

.icon {
  color: $color-primary;
  background-color: $color-light;
  width: 80px;
  height: 80px;
  line-height: 5rem;
  font-size: $font-size-l;
  text-align: center;
  border-radius: 20px;
  margin-bottom: $spacer-l;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.choice-title {
  @include textBolderDark();
}

.from-ia {
  background: url('./images/bg.svg'), $background-gradient-ia;
  background-repeat: no-repeat;
  background-size: cover;

  .choice-title {
    color: $color-light;
  }
}

.tooltip-icon {
  position: absolute;
  top: $spacer-m;
  right: $spacer-m;
  color: $color-light;
}
